import React, { FC, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { Detail } from '../features/TransactionDetails/Detail'
import { Button } from '../../../elements/Buttons/Button'
import { TransactionStatus } from '../services/changelly/types'
import { InfoBox } from '../../../elements/InfoBox/InfoBox'
import { Navigation } from '../../../elements/Navigation/Navigation'
import { truncateNumber } from './Preview'
import { usePollingTransactionHistory } from '../hooks/usePollingTransactionHistory'
import { Spinner } from '../../../elements/Spinner/Spinner'

import SUCCESS from '../../../assets/images/checked-circle-green.svg'
import FAILURE from '../../../assets/images/warning.svg'
import PENDING from '../../../assets/images/exclamation.svg'
import REFUNDED from '../../../assets/images/dollar.svg'

import styles from '../../../views/Views.module.scss'

type Highlight = 'green' | 'yellow' | 'blue' | 'red'

/**
 * The icon corresponding to the transaction status
 */
export function getContent(status: TransactionStatus)
: { icon: string, header: string, text?: string, highlight: Highlight, hex: string } {
  switch (status) {
    case 'finished':
      return {
        icon: SUCCESS,
        header: 'successful',
        highlight: 'green',
        hex: '#6FCF97',
      }
    case 'failed':
      return {
        icon: FAILURE,
        header: status,
        text: `Transaction has failed. Often this is since the actual amount sent was less than the
      minimum. If the problem persists, contact support and provide a transaction ID.`,
        highlight: 'red',
        hex: '#FF5A5A',
      }
    case 'overdue':
    case 'expired':
      return {
        icon: FAILURE,
        header: status,
        text: 'The transaction was not made within the maximum timeframe.',
        highlight: 'red',
        hex: '#FF5A5A',
      }
    case 'refunded':
      return {
        icon: REFUNDED,
        header: status,
        text: 'Exchange failed and coins were refunded to your wallet',
        highlight: 'blue',
        hex: '#59BBC9',
      }
    case 'hold':
      return {
        icon: PENDING,
        header: 'on hold',
        text: `Due to AML/KYC procedure, exchange may be delayed. Contact the Changelly's security
        team at compliance@changelly.com to pass the KYC procedure or any other required procedures`,
        highlight: 'yellow',
        hex: '#F9A94A',
      }
    default:
      return {
        icon: PENDING,
        header: status,
        highlight: 'yellow',
        hex: '#F9A94A',
      }
  }
}

/**
 * A view displaying information about the status of a cross-chain transaction
 */
export const MultiChainTransactionStatus: FC = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const txId = searchParams.get('txId') as string | undefined
  const error = searchParams.get('error') as string | undefined
  const history = searchParams.get('history') as string | undefined
  const txs = useAppSelector(state => state.multiChain.txHistory)
  const tx = txs.find(tx => tx.id === txId)
  const [showLoading, setShowLoading] = useState<boolean>(true)

  usePollingTransactionHistory(txId)

  setTimeout(() => {
    setShowLoading(false)
  }, 10000)

  if (error) {
    return (
      <div className={styles['content']}>
        <h2>Status</h2>
        <div className={styles['container']}>
          <div className={styles['status']}>
            <img src={FAILURE} alt='icon' className={styles['icon']}/>
            <p className={styles['transaction-status__text']}>Oh no, something went wrong!</p>
            <p className={styles['transaction-status__text']}>
              We couldn't create this transaction at the moment due to the following error:
              <span>{error}</span>
            </p>
          </div>
          <Button onClick={() => navigate('/cross-chain')} style='secondary'>
            Back to Swap Office
          </Button>
        </div>
      </div>
    )
  }

  // TODO - not sure if this is wanted in this way
  if (!tx) {
    if (showLoading) {
      return <Spinner />
    }

    return (
      <div>
        <p>Transaction with id: '{txId}' not found</p>
        <p>
            We couldn't fetch this transaction status. There can sometimes be a delay of up to a few
            minutes for the transaction history to be generated.
            Please wait while we try again, or note the id number and re-check your previous
            transactions from the main page.
        </p>
        <p>You can also check your balance to confirm that the transaction has executed.</p>
        <Button onClick={() => navigate('/cross-chain')} style='secondary'>
            Back to Swap Office
        </Button>
      </div>
    )
  }

  const status = getContent(tx.status)

  return (
    <div className={styles['content']}>
      {history && <Navigation pathname='/cross-chain/transaction'/>}
      <h2>Status</h2>
      <div className={styles['container']}>
        <div className={styles['status']}>
          <img src={status.icon} alt='' className={styles['icon']}/>
          <h4>Transaction {status.header}.</h4>
        </div>
        <div className={styles['detail-container']}>
          <Detail label='Transaction ID' value={tx.id}/>
          <Detail label='Swap from estimated'
            value={`${truncateNumber(tx.amountExpectedFrom)} ${tx.currencyFrom}`}/>
          <Detail label='Swap from final'
            value={`${truncateNumber(tx.amountFrom)} ${tx.currencyFrom}`}/>
          {tx.payinTxLink && <span><a href={tx.payinTxLink} target='_blank'>
            See on block explorer
          </a><img/></span>}
          <Detail label='Swap to estimated'
            value={`${truncateNumber(tx.amountExpectedTo)} ${tx.currencyTo}`}/>
          <Detail label='Swap to final' value={`${truncateNumber(tx.amountTo)} ${tx.currencyTo}`}/>
          {tx.payoutHashLink ? (
            <span><a href={tx.payoutHashLink} target='_blank'>
            See on block explorer
            </a><img/></span>
          ) : tx.refundHashLink ? (
            <span><a href={tx.refundHashLink} target='_blank'>
            See on block explorer
            </a><img/></span>
          ) : null}
          <Detail label='Rate'
            value={`1 ${tx.currencyFrom} ≈ ${tx.rate} ${tx.currencyTo}`}/>
          {!(['failed', 'expired', 'overdue'].includes(tx.status)) && (
            <>
              <Detail label='Service fee' value={
                `${truncateNumber(tx.changellyFee)} ${tx.currencyTo}`}/>
              <Detail label='Network fee' value={
                `${truncateNumber(tx.networkFee)} ${tx.currencyTo}`}/>
              <Detail label='Partner fee' value={
                `${truncateNumber(tx.apiExtraFee)} ${tx.currencyTo}`}/>
              <Detail label='Recipient address' value={tx.payoutAddress}/>
            </>
          )}
          {status.text && <InfoBox type='warning'>{status.text}</InfoBox>}
        </div>
        {!history &&
          <Button onClick={() => navigate('/cross-chain')} style='secondary'>
            Back to Swap Office
          </Button>
        }
      </div>
    </div>
  )
}
