import { Api, ApiInterfaces, JsonRpc } from 'eosjs'
import { WaxJS } from '@waxio/waxjs/dist'
import { Action } from 'eosjs/dist/eosjs-serialize'

/**
 * Data needed to form a transaction Action
 */
type ActionArgs = {
  /**
   * The user address from which tokens are being sent
   */
  sender: string
  /**
   * The address receiving tokens
   */
  recipient: string
  /**
   * The token being input to the transaction
   */
  sentToken: {
    /**
     * The token contract address
     * @example eosio.token
     */
    contract: string
    /**
     * The input token amount a string with the !correct number of! decimals.
     * The precision of the transfer token amount has to match the decimals
     * @example '0.050000' EOS for 6 decimals, not '0.05' EOS
     */
    scaledAmount: string
    /**
     * The token symbol
     * @example 'EOS
     */
    symbol: string
  }
  /**
   * The action name, by default the action is a transfer
   */
  name?: 'transfer'
  /**
   * A memo, if supplied
   */
  memo?: string
}

/**
 * Creates an EOSIO action
 */
export function eosioAction({ sender, recipient, sentToken, name = 'transfer', memo = '' }: ActionArgs): Action {
  return {
    name: name,
    account: sentToken.contract,
    authorization: [{ actor: sender, permission: 'active' }],
    data: {
      from: sender,
      // The precision of the transfer token amount has to match the decimals
      // e.g. 0.050000 EOS for 6 decimals, not 0.05 EOS
      quantity: `${sentToken.scaledAmount} ${sentToken.symbol}`,
      memo: memo,
      to: recipient,
    },
  }
}

/**
 * Creates a new RPC for a given node
 * @param node the node of the network
 */
export function rpc(node: string): JsonRpc {
  return new JsonRpc(node)
}

/**
 * Triggers a transaction on an EosIo chain using Scatter, this is used when the user is connected
 * with Wombat Wallet
 * @param tx the action data provided for the transaction
 * @param chainId the chain id, this is in hex format
 * @param node the node to use for the transaction
 */
export async function scatterTransact(tx: ApiInterfaces.Transaction, chainId: string, node: string)
: Promise<void> {
  if (!window.scatter) {
    throw new Error('Scatter not available')
  }

  const executor = window.scatter.eos({ chainId }, Api, { rpc: rpc(node) })
  await executor.transact(tx, { blocksBehind: 3, expireSeconds: 30 })
}

/**
 * Triggers a transaction on an EosIo chain using waxJs, this is used when the user is connected
 * with Wax Cloud Wallet
 * @param tx the action data provided for the transaction
 * @param node the node to use for the transaction
 */
export async function waxCloudWalletTransact(tx: ApiInterfaces.Transaction, node: string) {
  const waxJs = new WaxJS({
    rpcEndpoint: node,
    freeBandwidth: true,
    tryAutoLogin: false,
  })
  await waxJs.api.transact(tx, { blocksBehind: 3, expireSeconds: 30 })
}
