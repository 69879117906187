import { useEffect } from 'react'
import { getTransactionHistoryById } from '../services/changelly/api'
import { setTxHistory } from '../store/multiChainSlice'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { TransactionStatus } from '../services/changelly/types'

const confirmed: TransactionStatus[] = ['failed', 'expired', 'overdue', 'finished', 'refunded']

export const usePollingTransactionHistory = (txId: string | undefined) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    let intervalId: NodeJS.Timeout

    const fetchTransactionHistory = async () => {
      try {
        if (txId) {
          const res = await getTransactionHistoryById(txId)
          if ('result' in res) {
            dispatch(setTxHistory(res.result))
            const currentStatus = res.result.find(tx => tx.id === txId)?.status
            if (currentStatus && confirmed.includes(currentStatus)) {
              clearInterval(intervalId)
            }
          } else if ('error' in res) {
            throw new Error(res.error.message)
          } else {
            throw new Error('Something went wrong fetching tx history')
          }
        }
      } catch (error) {
        console.error(error)
      }
    }

    if (txId) {
      intervalId = setInterval(fetchTransactionHistory, 10000)
    }

    return () => clearInterval(intervalId)
  }, [txId])
}
