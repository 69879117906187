@import '../../assets/styles/_typography';
@import '../../assets/styles/_variables';

.button {
  border-radius: 24px;
  padding: 12px 24px;
  background: var(--gradient-primary);
  width: 100%;
  @include text-normal(center, 700);

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }

  &:disabled {
    cursor: not-allowed;
    background: var(--colorSurfaceDisabled) !important;
    color: var(--colorTextDisabled);
  }
}

.narrow {
  @media (min-width: 768px) {
    width: unset;
  }
}

.secondary {
  border: 1px solid var(--colorWhite);
  background: var(--colorSurfaceDark);
  
  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }
}

.secondary--small {
  border: 1px solid var(--colorWhite);
  background: var(--colorSurfaceDark);
  padding: 6px 8px;
  width: min-content;
  
  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }
}

.selection-button {
  display: flex;
  width: 100%;
  border: 1px solid var(--colorWhite);
  background: var(--colorSurfaceDark);
  padding: 5px 24px 5px 5px;
  align-items: center;
  gap: 16px;
  border-radius: 32px;
  @include text-normal(center, 400);

  &:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.12);
  }
}

.icon {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  background: var(--colorWhite);
}
