import { FC, PropsWithChildren } from 'react'

import WARNING from '../../assets/images/warning.svg'
import INFO from '../../assets/images/info.svg'

import styles from './InfoBox.module.scss'

type InfoType = 'info' | 'warning'

/**
 * Returns the correct icon based on the type of information being shown
 */
function getIcon(type: InfoType): string {
  switch (type) {
    case 'info': return INFO
    default: return WARNING
  }
}

type Props = {
  /**
   * The type of information being shown
   */
  type: InfoType
}
/**
 * The information to be shown
 */
& PropsWithChildren

/**
 * A UI component to give the user information about the swap
 */
export const InfoBox: FC<Props> = ({ type = 'warning', children }) => {
  /**
   * The icon to indicate which type of information is being shown
   */
  const icon = getIcon(type)

  return (
    <div className={styles['info-box']} aria-live={type === 'warning' ? 'assertive' : 'polite'}>
      <img className={styles['icon']} src={icon} alt='info'/>
      {children}
    </div>
  )
}
