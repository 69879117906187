import { EosIoNetworkConfig } from '../../configs/networks'
import { QuoteArgs } from '../../hooks/useSwapEstimate'
import { TokenWithAmount } from '../../hooks/useTokenInputs'
import { TokenAmount } from '../../numbers/TokenAmount'
import { setActions } from '../../store/eosSlice'
import { ActionData } from '../../store/userSlice'
import { DisplayError, singleChainSwapErrors, warningGenerator } from '../../store/warnings'
import { isSameToken } from '../checks'
import { formatTransactionDetails } from '../../features/TxInformation/transaction-stats'
import {
  calculatePriceImpact, isSignificantPriceImpact, multiPathPriceImpactPercentage,
  singlePathPriceImpactPercent,
} from './price-impact'
import { PairResponse, TokenResponse, getEosioPairTable } from './tables/pairs'
import { largestLiquidityPair } from './tables/processing'
import { Quote } from '../evm/swap-quote'

/**
 * The {@link QuoteArgs} modified to contain only {@link EosIoNetworkConfig} as the config type
 */
type EosioQuoteArgs = QuoteArgs & {
  /**
   * The config for an EOSIO network
   */
  config: EosIoNetworkConfig
}

/**
 * The {@link PairResponse} as recieved from the api but augmented with some data unknown to the
 * quote api but essential for calculating the values of this particular trade.
 */
type AugmentedPairResponse = PairResponse & {
  /**
   * A sort order (the order in which the trade should be executed, i.e. input token -> main token
   * -> output token)
   * @example 0
   */
  order: number
  /**
   * The input token input {@link TokenAmount}
   */
  inputAmount?: TokenAmount
}

/**
 * Fetches the data for token pairs from the api
 * @returns the api response {@link PairResponse} plus some addition data
 */
async function getRawPairData(args: EosioQuoteArgs): Promise<AugmentedPairResponse[]> {
  /**
   * The input and output token
   */
  const tokens = [args.from, args.to]
  /**
   * Tokens with the input or output token filtered out if it is same as the chain main token
   * (e.g. EOS token on EOS network)
   */
  const filteredTokens = tokens.filter(token => !isSameToken(token, args.mainToken))

  return Promise.all(filteredTokens.map(async (token, i) => {
    // TODO - the token type needs to be rewritten so that typescript knows that the pairIndex is
    // always defined. This is the case for all EOSIO pairs.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const res = await getEosioPairTable({ pairIndex: token.pairIndex!, node: args.config.node,
      contract: args.config.contract })
    const pair = largestLiquidityPair(res)

    if (!pair) {
      throw new Error('No liquidity found for this token pair')
    }

    return { ...pair, order: i,
      // If it is first token, i.e. the token being swapped in, the `inputAmount` should be the
      // `amount` input to the swap
      inputAmount: i === 0 ? args.amount : undefined }
  }))
}

/**
 * The token data returned from the api after it is cleaned
 */
export type ApiToken = {
  /**
   * The token address
   * @example 'btc.ptokens'
   */
  address: string
  /**
   * The token decimals
   * @example 6
   */
  decimals: number
  /**
   * The token symbol
   * @example 'EOS'
   */
  symbol: string
  /**
   * The token reserve i.e. liquidity pool, as a {@link TokenAmount}
   */
  reserve: TokenAmount
}

/**
 * Extracts the clean data for a token from the api response
 *
 * @param token The raw token data as returned by the api
 * @param reserveRaw The raw data about the token reserve
 * @returns The data from the api as a cleaned {@link ApiToken}
 */
function extractResponseData(token: TokenResponse, reserveRaw: string): ApiToken {
  const symbolParts = token.symbol.split(',')
  const decimals = parseInt(symbolParts[0])
  const symbol = symbolParts[1]
  const reserve = TokenAmount.fromStringWithDecimals(reserveRaw.split(' ')[0], decimals, symbol)
  return { address: token.contract, decimals, symbol, reserve }
}

/**
 * Labels the data for tokens as returned from the API as either the token being swapped from or to
 *
 * Where a swap contains multiple steps, e.g. when a swap of two tokens is done via the network main
 * token, this assigns the input token as `from` and the network main token as the `to`, then for
 * the second step, the network main token as the `from` and the desired output token as the `to`.
 * In every case, the function assigns the token traded in as the `from` and the received token as
 * the `to`.
 *
 * @param pair The {@link PairResponse} as received from the API
 * @param from Data about the token selected to be input to the swap
 * @param to Data about the token selected to be output from the swap
 * @returns The token to be swapped from and to for each token pair
 */
function assignTokens(pair: PairResponse, from: TokenWithAmount, to: TokenWithAmount)
: { from: ApiToken, to: ApiToken } {
  const token0: ApiToken = extractResponseData(pair.token0, pair.reserve0)
  const token1: ApiToken = extractResponseData(pair.token1, pair.reserve1)

  // find which user-selected token corresponds to which token in the response
  if (isSameToken(from, token0) || isSameToken(to, token1)) {
    return { from: token0, to: token1 }
  } else if (isSameToken(from, token1) || isSameToken(to, token0)) {
    return { from: token1, to: token0 }
  } else {
    throw new Error('Cannot assign token responses')
  }
}

/**
 * The data which is required for the quote
 *
 * These values are needed either to display in the UI (e.g. the exchange rate) or to then execute
 * a transaction for the given inputs.
 */
type PairData = {
  /**
   * The order of this trade from the trades which should be made for the swap.
   *
   * For EOS the swap is often multipath unless EOS itself is being traded from or to. The tokens
   * are traded first into EOS and then into the selected output token. It's therefore important to
   * know in what order the trades should be made.
   * e.g. order 0 :`from` token -> `EOS`, order 1 `EOS` -> `to` token
   * or if the swap is being made from `EOS`: `EOS` -> `to` token = order 0
   *
   * @example 0
   */
  order: number
  /**
   * The the id for the token pair's token liquidity pool
   * @example 177
   */
  pairIndex: number
  /**
   * Data about the token within the pair which is being input to the swap
   */
  from: ApiToken// TODO - replace reserve with amount
  /**
   * Data about the token within the pair which is being output from the swap
   */
  to: ApiToken
  /**
   * The exchange rate for swapping from -> to token, i.e. the number that the `from` token should
   * be multiplied by in order to receive the equivalent `to` token value
   */
  exchangeRate: TokenAmount
  /**
   * The expected price impact of making this trade of the pair
   */
  priceImpact: TokenAmount
  /**
   * The {@link TokenAmount} being input to the swap
   */
  fromAmount: TokenAmount
  /**
   * The estimated {@link TokenAmount} output from the swap
   */
  toAmount: TokenAmount
}

/**
 * Extracts data needed for the quote from the api response for the token pair
 *
 * @param rawData The API response for the token pair
 * @param from The token being swapped from
 * @param to The token being swapped into
 * @returns Data which is needed to display about and execute the swap
 */
function deriveDataFromApiResponse(
  rawData: AugmentedPairResponse[], from: TokenWithAmount, to: TokenWithAmount,
): PairData[] {
  const uncalculatedData: Omit<PairData, 'priceImpact'>[] = rawData.map(pair => {
    const tokens = assignTokens(pair, from, to)
    const exchangeRate = tokens.to.reserve.div(tokens.from.reserve)
    const fromAmountPlaceholder = pair.inputAmount ?? TokenAmount.ZERO(from.decimals, from.symbol)
    const toTokenAmount = TokenAmount.ZERO(tokens.to.decimals, tokens.to.symbol)
    return {
      order: pair.order,
      fromAmount: fromAmountPlaceholder,
      pairIndex: pair.id,
      from: tokens.from,
      to: tokens.to,
      toAmount: fromAmountPlaceholder.mul(exchangeRate).convertToOtherToken(toTokenAmount),
      exchangeRate: exchangeRate,
    }
  }).sort((a, b) => a.order - b.order)
  return uncalculatedData.map((entry, i, arr) => {
    const actualFromAmount = entry.fromAmount.eq(TokenAmount.ZERO(entry.fromAmount.decimals,
      // TODO - rewrite
      entry.fromAmount.symbol)) ?
      (arr[i - 1] ? arr[i - 1].toAmount : entry.fromAmount)
      : entry.fromAmount
    return {
      ...entry,
      fromAmount: actualFromAmount,
      toAmount: actualFromAmount.mul(entry.exchangeRate).convertToOtherToken(entry.toAmount),
      priceImpact: calculatePriceImpact(entry.from.reserve, entry.to.reserve, actualFromAmount),
    }
  })
}

/**
 * Forms the action to pass to the transaction for the token swap
 *
 * @param pair The data for the token pair
 * @param contract The contract executing the token swap
 * @returns The data for an action to be used in a transaction
 */
function createAction(pair: PairData, contract: string): ActionData {
  return {
    exchangeContract: contract,
    pairId: pair.pairIndex.toString(),
    from: { contract: pair.from.address, decimals: pair.from.decimals, symbol: pair.from.symbol,
      amount: pair.fromAmount.asStringWithDecimalQuantity(pair.fromAmount.decimals) },
    to: { contract: pair.to.address, decimals: pair.to.decimals, symbol: pair.to.symbol,
      amount: pair.toAmount.asStringWithDecimalQuantity(pair.toAmount.decimals) },
  }
}

// TODO - prime candidate for testing!
/**
 * Returns the price impact percentage when it is deemed significant
 *
 * @param pairData Data about the token pair and swap required to calculate the price impact
 * @param maxPriceImpact The maximum price impact configured for the user
 * @returns A price impact percentage higher than an arbitrary %
 */
function returnPriceImpact(pairData: PairData[]): string | undefined {
  let priceImpact: string | undefined
  if (pairData.length === 1) {
    priceImpact = singlePathPriceImpactPercent(pairData[0].from.reserve, pairData[0].to.reserve,
      pairData[0].fromAmount)
  } else if (pairData.length === 2) {
    priceImpact = multiPathPriceImpactPercentage(
      { reserveFrom: pairData[0].from.reserve, reserveTo: pairData[0].to.reserve,
        inputFrom: pairData[0].fromAmount },
      { reserveFrom: pairData[1].from.reserve, reserveTo: pairData[1].to.reserve,
        inputFrom: pairData[1].fromAmount },
    )
  }
  return priceImpact
}

/**
 * All data for displaying in the UI and forming an action
 */
export type QuoteRes = Omit<Quote, 'type' | 'actions'> & {
  /**
   * The data for the actions property of a transaction. This is prepared in the store so that it
   * can be used if the user decides to execute a swap action.
   */
  actions: {
    /**
     * The data to set in the store
     */
    payload: ActionData[]
    /**
     * The action to dispatch in the store
     */
    type: 'eos/setActions'
  }
}

/**
 * Returns data necessary for the preview step of a transaction
 * @returns the {@link QuoteRes} or undefined if an error has been dispatched
 */
export async function quoteForPair(args: EosioQuoteArgs): Promise<QuoteRes> {
  const warnings: DisplayError[] = []

  // return a config error if a `pairIndex` is missing
  // TODO - for EOS tokens this should always be defined, this should be fixed earlier
  if (!args.from.pairIndex || !args.to.pairIndex) {
    throw new Error(singleChainSwapErrors.CONFIG.code)
  }

  /**
   * Data returned from the api for the token pairs
   */
  const rawPairData: AugmentedPairResponse[] = await getRawPairData(args)

  /**
   * Only the data required for making a quote for the swap
   */
  const pairData: PairData[] = deriveDataFromApiResponse(rawPairData, args.from, args.to)
  /**
   * The output is the final element in the pairData array
   */
  const output = pairData[pairData.length - 1]

  /**
   * The actions to pass to a transaction if the user proceeds with the swap
   */
  const actions: ActionData[] = pairData
    .map(pair => createAction(pair, args.config.contract))

  /**
   * The exchange rate between the input `from` and output `to` token
   */
  const combinedExchangeRate = pairData.map(pair => pair.exchangeRate).reduce((a, b) => b.mul(a))

  /**
   * The percentage of estimated price impact for a swap
   */
  const priceImpact = returnPriceImpact(pairData)
  const highPriceImpact = isSignificantPriceImpact(priceImpact, args.priceImpactTolerance)
  if (!priceImpact) {
    warnings.push(singleChainSwapErrors.FAILED_TO_CALCULATE_PRICE_IMPACT)
  } else {
    if (highPriceImpact) {
      warnings.push({ ...singleChainSwapErrors.NOT_ALLOWED_PRICE_IMPACT,
        message: warningGenerator.NOT_ALLOWED_PRICE_IMPACT.message(priceImpact) })
    }
  }
  /**
   * Statistics about the transaction
   * @example the exchange rate
   */
  const details = formatTransactionDetails({ priceImpact: { value: priceImpact,
    highlight: highPriceImpact },
  rate: { rate: combinedExchangeRate, fromSymbol: args.from.symbol, toSymbol: args.to.symbol } })

  return { amount: output.toAmount, actions: setActions(actions), details, warnings,
    estimatedPriceImpact: priceImpact }
}
