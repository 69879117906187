import React, { FC, useMemo, useState } from 'react'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { useAppSelector } from '../../hooks/useAppSelector'
import { storeSlippageTolerance } from '../../store/singleChainSlice'
import { EditableDetail } from '../TxInformation/EditableDetail'

/**
 * A pattern which allows the numbers 0 - 100 with up to one decimal point. Both `.` and `,` are
 * allowed as a decimal separator.
 */
const pattern = /^(?!0\d)(?:\d{1,2}(?:[.,]\d{0,1})?|100(?:[.,]0)?)$/

/**
 * A UI element which allows a user to input a custom slippage
 */
export const MaxPriceImpactControl: FC = () => {
  const dispatch = useAppDispatch()
  const { maxPriceImpact } = useAppSelector(state => state.singleChain)
  const [wipTolerance, setWipTolerance] = useState(maxPriceImpact)

  /**
   * Values for the editable slippage detail
   */
  const detail = useMemo(() => ({
    label: 'Max price impact',
    value: wipTolerance,
    highlight: false,
  }), [wipTolerance])

  const text = `Max price impact a.k.a. max slippage is the maximum difference between the expected
  price and actual price of your transaction, that you are willing to accept. Only edit it if your
  transaction is failing due to high price impact, but you still want to trade.`

  /**
   * Saves the user input slippage tolerance to the state
   *
   * @param val the new slippage tolerance as a number string
   * @example 3
   */
  function updateSlippageInput(val: string) {
    let num
    if (val === '') {
      num = ''
    } else if (pattern.test(val)) {
      num = val
    } else {
      num = maxPriceImpact
    }
    setWipTolerance(num)
  }

  function confirmSlippageTolerance() {
    if (wipTolerance.length === 0) {
      setWipTolerance('0')
    }
    dispatch(storeSlippageTolerance(wipTolerance))
  }

  return <EditableDetail detail={detail} updateValue={updateSlippageInput} tooltipText={text}
    confirmValue={confirmSlippageTolerance}/>
}
