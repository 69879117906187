@import '../../assets/styles/_variables';

.header {
  &__title {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    @media (min-width: 768px) {
      align-items: center;
      font-size: 1.5rem;
    }
  }
}

.nav {
  display: flex;
  gap: 4px;
  font-size: 0.725rem;

  &__button {
    padding: 4px 12px;
    border: solid 1px var(--colorWhite);
    border-radius: 24px;

    @media (min-width: 768px) {
      width: 100px;
    }

  &--selected {
    color: var(--colorSurface);
    background-color: var(--colorWhite);
    font-weight: 600;
  }
  }
  &__back {
    display: flex;
    align-items: center;
    font-weight: 700;
  }
}

