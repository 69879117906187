import React, { FC } from 'react'
import { useAppSelector } from '../../hooks/useAppSelector'
import { InfoBox } from '../../elements/InfoBox/InfoBox'
import { Detail } from './Detail'
import { MaxPriceImpactControl } from '../Slippage/MaxPriceImpactControl'
import { DisplayError } from '../../store/warnings'
import { Spinner } from '../../elements/Spinner/Spinner'

import styles from './TransactionDetails.module.scss'

/**
 * A UI component displaying numerical values related to a potential swap transaction
 */
export const TransactionDetails: FC = () => {
  const { swapDetails, warnings, loading } = useAppSelector(state => state.singleChain)
  return <TransactionDetailsDumb loading={loading} details={swapDetails} warnings={warnings}/>
}

/**
 * An estimated value associated with a transaction, e.g. the exchange rate, price impact,
 * transaction fees, which should be displayed in the UI
 */
export type TransactionDetail = {
  /**
   * The name of the value associated with the transaction, i.e. a fee or rate
   * @example 'Transaction fee'
   */
  label: string
  /**
   * The numerical value(s) for the estimated rate/fee, fully formatted with any associated symbols
   * @example '1 YFI ≈ 0.5 Uniswap', '2.01 UNI'
   */
  value: string
  /**
   * Whether the detail should be highlighted in the UI
   *
   * Required for cases when there is a high price impact or balance doesn't cover transaction fees
   */
  highlight: boolean
  /**
   * An optional extra info text to be displayed to the user about the detail if provided
   */
  infoText?: string
}

type Props = {
  /**
   * Whether the transaction fee details are being loaded
   */
  loading: boolean
  /**
   * The list of transaction fee details
   */
  details: TransactionDetail[]
  /**
   * Any warnings to display concerning the fees
   */
  warnings: DisplayError[]
}

/**
 * A UI component displaying numerical values related to a potential swap transaction
 */
export const TransactionDetailsDumb: FC<Props> = props => (
  <div className={styles['details']} aria-live='polite'>
    {props.loading && <Spinner/>}
    {props.details.length > 0 && (
      <div className={styles['numeric-details']}>
        {props.details.map(detail => <Detail {...detail} key={detail.label} />)}
        <MaxPriceImpactControl />
      </div>
    )}
    {props.warnings.map(warning => {
      return (warning.type === 'output' && (
        <InfoBox key={warning.code} type='warning'>{warning.message}</InfoBox>
      ))
    })}
  </div>
)
