import { ConfigType } from '../../configs/networks'
import { QuoteArgs } from '../../hooks/useSwapEstimate'
import { TokenAmount } from '../../numbers/TokenAmount'
import { setQuoteData } from '../../store/evmNetworksSlice'
import { calculateRate, formatTransactionDetails } from '../../features/TxInformation/transaction-stats'
import { ParaswapPriceErrors, PriceRoute } from './paraswap-api/paraswap-types'
import { extraBalanceNeeded } from '../checks'
import { getGasPrice } from './gas-prices'
import { warningGenerator, singleChainSwapErrors, DisplayError } from '../../store/warnings'
import { fetchUsdToMainTokenRate } from '../usd-rates'
import { fetchPrice } from './paraswap-api/fetch-price'
import { SupportedToken } from '../../store/processRawTokenData'
import { TransactionDetail } from '../../features/TxInformation/TransactionDetails'
import { impactFromSrcAndDestAmounts, isSignificantPriceImpact } from '../eosio/price-impact'

/**
 * Return the exchange rate for two tokens.
 *
 * This handles checking if the two tokens are the same.
 * @param from The input token
 * @param to The output token
 * @param amount The input amount as an unscaled string in wei
 * @example '1287777770000'
 * @param networkId The network id number
 * @returns The exchange rate as a {@link TokenAmount}
 */
export async function getExchangeRate(from: SupportedToken, to: SupportedToken,
  amount: string, networkId: string, maxImpact: number): Promise<TokenAmount> {
  // check if the to token is the token being swapped from - this happens when calculating the
  // exchange rate to the network main token, if the network main token is also the input token.
  if (from.address === to.address && from.name === to.name) {
    // in this case there is no need to make an API call, the rate is 1:1
    return TokenAmount.ONE(from.decimals, from.symbol)
  }
  const inputToMain = await fetchPrice(from.address, from.decimals.toString(), to.address,
    to.decimals.toString(), amount, networkId, maxImpact)
  return exchangeRate(inputToMain.priceRoute, from.symbol, to.symbol)
}

/**
 * Returns the exchange rate for tokens based on the {@link PriceRoute} response
 * @param prices The {@link PriceRoute} object returned by the paraswap price path endpoint
 */
function exchangeRate(prices: PriceRoute, fromSymbol: string, toSymbol: string): TokenAmount {
  const from = TokenAmount.fromUnscaledString(prices.srcAmount, prices.srcDecimals, fromSymbol)
  const to = TokenAmount.fromUnscaledString(prices.destAmount, prices.destDecimals, toSymbol)
  return calculateRate(from, to)
}

type EvmAction = {
  payload: PriceRoute
  type: 'evm/setQuoteData'
}

export type Quote = {
  /**
   * The estimated amount to receive from the swap as a {@link TokenAmount}
   */
  amount: TokenAmount
  /**
   * The action to dispatch for storing data from the quote
   */
  actions: EvmAction
  /**
   * Information for display in the UI about a potential swap, for example the exchange rate
   */
  details: TransactionDetail[]
  /**
   * The estimated value of the slippage
   */
  estimatedPriceImpact: string | undefined
  /**
   * The successful type of quote response
   */
  type: 'success'
  /**
   * Warnings to display in the UI alongside the output amount of the quote
   *
   * This is when a quote has not failed but there is information which is important for the user
   * e.g. when the price impact is significant, or they do not have sufficient balance to execute
   * the transaction.
   */
  warnings: DisplayError[]
}

/**
 * Gets the estimated values for the swap including data to pass to a swap transaction
 */
export async function getEvmQuote(args: QuoteArgs): Promise<Quote> {
  // TODO - re-write so that it is explicit that this can not happen since `getPreviewData` should
  // only be used with a `ConfigType.EVM`
  if (args.config.type !== ConfigType.EVM) {
    throw new Error(singleChainSwapErrors.CONFIG.code)
  }

  const warnings: DisplayError[] = []

  // attempt to fetch the price data
  /**
   * The input amount as a string in wei
   */
  const unscaledInputAmount = args.amount.asUnscaledQuantity(args.amount.decimals)
  const priceResponse = await fetchPrice(args.from.address, args.from.decimals.toString(),
    args.to.address, args.to.decimals.toString(), unscaledInputAmount, args.config.id,
    args.priceImpactTolerance)

  const highPriceImpact = isSignificantPriceImpact(priceResponse.value,
    args.priceImpactTolerance)
  // this error is being returned for almost all transactions since the `maxImpact` parameter
  // is being set to 0 in order to receive the price impact value
  if (priceResponse.error === ParaswapPriceErrors.PRICE_IMPACT) {
    if (highPriceImpact && priceResponse.value) {
      warnings.push({ ...singleChainSwapErrors.NOT_ALLOWED_PRICE_IMPACT,
        message: warningGenerator.NOT_ALLOWED_PRICE_IMPACT.message(priceResponse.value),
      })
    } else if (highPriceImpact) {
      warnings.push(singleChainSwapErrors.NOT_ALLOWED_PRICE_IMPACT)
    }
  }
  const route = priceResponse.priceRoute
  const impact = impactFromSrcAndDestAmounts({ srcUsd: route.srcUSD, destUsd: route.destUSD })

  /**
   * The user's main token balance as a {@link TokenAmount}
   */
  const mainToken = TokenAmount.fromStringWithDecimals(args.mainToken.balance,
    args.mainToken.decimals, args.mainToken.symbol)

  // Calculate the transaction fees
  /**
   * The gas for the transaction as an {@link TokenAmount} of the network's main token
   * e.g. for Polygon network the gas fee would be a TokenAmount in POL
   */
  const gasFee = await getGasPrice(route.gasCost, args.config.scanService, mainToken)
  /**
   * The fee which Spielworks takes as a cut from each swap transaction - this is taken from the
   * input token. E.g. swapping $WOMBAT to USDT the referrer fee would be in$WOMBAT.
   */
  const referrerFee = args.amount.mul(args.config.feePercentage/100)
  /**
   * The extra balance needed in order to execute a transaction, if the transaction fees are
   * higher than the user's available balance of the chain main token
   */
  const insufficientBalance = extraBalanceNeeded(args.mainToken, args.from, args.amount, gasFee,
    referrerFee)
  if (insufficientBalance) {
    warnings.push({ ...singleChainSwapErrors.TRANSACTION_FEES, message: warningGenerator
      .TRANSACTION_FEES.message(insufficientBalance, insufficientBalance.symbol) })
  }
  /**
   * The USD exchange rate to the network's main token
   */
  const usdRate = await fetchUsdToMainTokenRate(args.config.coinGeckoId)

  // Fetch the input to main token rate in order to calculate the referrer fee in USD
  /**
   * The input token to chain main token rate
   */
  const inputToMainRate = await getExchangeRate(args.from, args.mainToken, route.srcAmount,
    args.config.id, args.priceImpactTolerance)
  /**
   * The total fee in USD
   */
  const usdGasFee = gasFee.mul(usdRate).display(true, gasFee.decimals)
  const usdReferrerFee = referrerFee.mul(inputToMainRate).mul(usdRate)
    .display(true, referrerFee.decimals)

  /**
   * The transaction details to display for an EVM chain
   */
  const details = formatTransactionDetails({ rate: { rate: exchangeRate(route, args.from.symbol,
    args.to.symbol), fromSymbol: args.from.symbol, toSymbol: args.to.symbol }, fees: { gas: {
    amount: gasFee, type: 'token' }, referrer: referrerFee, usdGasFee, usdReferrerFee,
  highlight: insufficientBalance }, priceImpact: { value: impact, highlight: highPriceImpact } })

  return {
    amount: TokenAmount.fromUnscaledString(route.destAmount, route.destDecimals,
      args.to.symbol), actions: setQuoteData(route), details, type: 'success', warnings,
    estimatedPriceImpact: priceResponse.value,
  }
}
