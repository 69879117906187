import React, { FC } from 'react'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { setPayoutIdRequired, setPayoutIdValue } from '../../store/multiChainSlice'
import { InfoBox } from '../../../../elements/InfoBox/InfoBox'

import styles from './PayoutMemo.module.scss'

/**
 * UI component enabling a user to submit a identifier to be used in a payout transaction
 */
export const PayoutMemo: FC = () => {
  const dispatch = useAppDispatch()
  const { payoutToken, payoutId: { required, value } } = useAppSelector(state => state.multiChain)

  // If the payout token doesn't require an identifier to be payed out
  if (!payoutToken?.txIdName) {
    return null
  }

  /**
   * Manages updating the payout id value
   */
  function handleTextInput(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault()
    dispatch(setPayoutIdValue(e.currentTarget.value))
  }

  return (
    <InfoBox type='info'>
      <div className={styles['column']} >
        <div className={styles['row']}>
          <p>
          Does your wallet require a {payoutToken.txIdName.toLowerCase()} to receive&nbsp;
            {payoutToken.symbol}?
          </p>
          <div className={styles['row']}>
            <label className={styles['label']}>
              Yes
              <input type='checkbox' checked={required}
                onChange={() => dispatch(setPayoutIdRequired(true))}/>
            </label>
            <label className={styles['label']}>
              No
              <input type='checkbox' checked={!required}
                onChange={() => dispatch(setPayoutIdRequired(false))}/>
            </label></div>
        </div>
        {required && <div className={styles['row']}>
          <label>Enter the {payoutToken.txIdName.toLowerCase()}:</label>
          <input type='text' required={required} value={value} onChange={handleTextInput}
            className={styles['input']}/>
        </div>}
      </div>
    </InfoBox>
  )
}
