import { useNavigate } from 'react-router-dom'
import { AsyncThunkAction } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../store'
import { useAppDispatch } from './useAppDispatch'
import { Network, NetworkSymbol } from '../configs/networks'
import { TxState } from '../store/transactionsSlice'
import {
  doSingleChainSwap as eosSwap, doMultiChainSwap as eosMultiChainSwap,
} from '../store/eosSlice'
import {
  doSingleChainSwap as evmSwap, doMultiChainSwap as evmMultiChainSwap,
} from '../store/evmNetworksSlice'
import { doSingleChainSwap as waxSwap, doMultiChainSwap as waxMultiChainSwap,
} from '../store/waxSlice'
import { getTransactionHistoryById } from '../features/multichain-swap/services/changelly/api'
import { setTxHistory, setTxId } from '../features/multichain-swap/store/multiChainSlice'

/**
 * Returns a more user-friendly / helpful error message for certain known errors
 * @param e An error returned from attempting to make a transaction
 */
function handleTxError(e: Error): string {
  switch (e.message) {
    case 'connection not open on send()': {
      return 'Error making Eth transaction - please update Wombat to the latest version'
    }
    case 'Extension context invalidated.': {
      return 'The Wombat Wallet extension context needs refreshing - please reload the page'
    }
    default: return e.message
  }
}

/**
 * Creates the route to display the correct transaction status
 */
function transactionStatusRoute(
  network: NetworkSymbol, status: TxState, txHash?: string, error?: string): string {
  const query = new URLSearchParams({
    status: status,
    network: network,
  })
  if (txHash) {
    query.set('hash', txHash)
  }
  if (error) {
    query.set('error', error)
  }
  return `/transaction?${query.toString()}`
}

/**
* Creates the route to display the correct transaction status
*/
export function multiChainTransactionStatusRoute(
  { txId, history, error }: {txId?: string, history?: boolean, error?: string}): string {
  const query = new URLSearchParams()
  if (txId) {
    query.set('txId', txId)
  }
  if (history) {
    query.set('history', history.toString())
  }
  if (error) {
    query.set('error', error)
  }
  return `/cross-chain/transaction?${query.toString()}`
}

/**
 * A redux action executed to make a swap
 */
type Action = AsyncThunkAction<string | void, string | void,
{ state: RootState, dispatch: AppDispatch }>

/**
 * A hook to handle the execution of swap transactions
 */
export const useSwap = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  /**
   * Execute the single chain swap action and manage the immediate transaction status
   */
  function executeSingleChainSwap(action: Action, network: NetworkSymbol) {
    dispatch(action).unwrap()
      .then(tx => {
        if (tx) {
          // `tx` is returned for EVM routes which should be directed to a pending transaction view
          return navigate(transactionStatusRoute(network, TxState.PENDING, tx))
        }
        navigate(transactionStatusRoute(network, TxState.CONFIRMED))
      })
      .catch((e: Error) => {
        console.error(e)
        const message = handleTxError(e)
        navigate(transactionStatusRoute(network, TxState.FAILED, undefined, message))
      })
  }

  /**
   * A function to execute a single-chain swap transaction
   * @param network the network on which the transaction is being made
   */
  function swap(network: NetworkSymbol): void {
    let action
    switch(network) {
      case Network.WAX: {
        action = waxSwap()
        break
      }
      case Network.EOS: {
        action = eosSwap()
        break
      }
      case Network.AVAX: case Network.BNB: case Network.ETH: case Network.POL: {
        action = evmSwap()
        break
      }
      default: {
        throw new Error(`No swap tx created for ${network} network`)
      }
    }

    executeSingleChainSwap(action, network)
  }

  /**
   * Execute the multi chain swap action and manage the immediate transaction status
   */
  function executeMultiChainSwap(action: Action, txId: string) {
    dispatch(action).unwrap()
      .then(() => {
        dispatch(setTxId(txId))
        getTransactionHistoryById(txId)
          .then(res => {
            if ('result' in res) {
              dispatch(setTxHistory(res.result)).unwrap()
                .then(() => navigate(multiChainTransactionStatusRoute({ txId })))
                // TODO - look into if this needs better handling
                .catch(e => console.error(e))
            } else if ('error' in res) {
              throw new Error(res.error.message)
            } else {
              throw new Error('Something went wrong fetching tx history')
            }
          })
      })
      .catch((e: Error) => {
        console.error(e)
        navigate(multiChainTransactionStatusRoute({ txId, error: e.message }))
      })
  }

  /**
   * A function to execute a cross-chain swap transaction
   * @param network the network on which the transaction is being made
   * @param changellyTxId the transaction id within the Changelly service
   */
  function multiChainSwap(network: NetworkSymbol, changellyTxId: string): void {
    let action
    switch(network) {
      case 'WAX': {
        action = waxMultiChainSwap()
        break
      }
      case 'EOS': {
        action = eosMultiChainSwap()
        break
      }
      case 'POL': case 'AVAX': case 'BNB': case 'ETH': {
        action = evmMultiChainSwap(network)
        break
      }
      default: {
        throw new Error(`No swap tx created for ${network} network`)
      }
    }

    executeMultiChainSwap(action, changellyTxId)
  }

  return {
    swap,
    multiChainSwap,
  }
}
