import cl from 'classnames'
import React, { FC, PropsWithChildren, useRef } from 'react'
import { useOutsideClick } from '../../hooks/useOutsideClick'

import styles from './ModalBase.module.scss'

type Props = PropsWithChildren & {
  /**
   * A function to close the modal
   */
  closeModal: () => unknown
  /**
   * Whether the blocking layer is visible on desktop
   */
  opaqueBlockingLayer?: true
  /**
   * Custom styles for a modal
   */
  extraStyles?: string
}

/**
 * A UI component intended to be used as the base for all modal content in order to standardise the
 * behaviour and appearance of modals in the application
 */
export const ModalBase: FC<Props> = props => {
  const ref = useRef<HTMLDivElement>(null)

  // Handles clicks outside of the `ModalBase`
  useOutsideClick({ refs: [ref], handleClick: props.closeModal })

  return (
    <>
      <div ref={ref} className={cl(styles['modal-content'], props.extraStyles)}>
        {props.children}
      </div>
      <div className={cl(
        styles['blocking-layer'], props.opaqueBlockingLayer ? styles['blocking-layer--opaque'] : '',
      )}/>
    </>
  )
}
