import { useMemo } from 'react'
import { Network } from '../../../configs/networks'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { changellyNetworkMap } from '../config/allowedChains'

type Args = {
  source: 'user-selection'
  userSelected: Network
} | {
  source: 'input-token'
  userSelected?: Network
}

/**
 * A hook to identify the network of the input token for the cross-chain swap
 */
export function useBlockchain(args: Args): Network {
  const inputToken = useAppSelector(state => state.multiChain.quote?.from)
  const tokens = useAppSelector(state => state.multiChain.tokens)

  /**
   * The blockchain of the token
   */
  return useMemo(() => {
    if (args.source === 'input-token' && inputToken) {
      const supportedChain = tokens.find(it => it.ticker === inputToken)?.blockchain
      if (!supportedChain) {
        throw new Error(`No chain found for ${inputToken} input token`)
      }
      return changellyNetworkMap[supportedChain].symbol
      // TODO - this whole part feels unnecessary, see if possible to delete
    } else if (args.source === 'user-selection') {
      return args.userSelected
      // TODO - fix, this should not be used as a fallback, the first two statements should return
      // the correct state, however something is causing premature re-rendering on navigating from
      // the Preview to the Inputs view. This needs to be troubleshot properly.
    } else if (args.userSelected) {
      return args.userSelected
    } else {
      throw new Error('No network found')
    }
  }, [tokens, inputToken, args])
}
