import React, { FC, PropsWithChildren, useEffect } from 'react'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { useAppSelector } from '../../hooks/useAppSelector'
import { clearRefresh, connectWombat, getAlcorTokens, getBalances } from '../../store/waxSlice'
import { initialiseDisplayValues, storeMainToken, storeOwnedTokens } from '../../store/singleChainSlice'
import { formatEosIoOwnedTokens } from '../../store/processRawTokenData'
import { Network } from '../../configs/networks'

/**
 * Component that ensures WAX state is initialized. This should be wrapped around all components
 * that use the WAX state.
 */
export const WaxInitialiser: FC<PropsWithChildren> = props => {
  const dispatch = useAppDispatch()
  const waxConfig = useAppSelector(state => state.configs.WAX)
  const wax = useAppSelector(state => state.user.wax)

  /**
   * Fetches balances of tokens owned on the WAX network
   */
  function loadUserData() {
    dispatch(getBalances()).unwrap()
      .then(balances => {
        const formatted = formatEosIoOwnedTokens(
          balances, waxConfig.availableTokens, waxConfig.mainTokenZeroBalance,
        )
        // Stores tokens in the display state
        dispatch(storeOwnedTokens(formatted))
        dispatch(storeMainToken(formatted))
      })
  }

  // Effect set up the initial state for WAX:
  // 1. connect Wombat wallet automatically
  // 2. initialise WAX display values
  // 3. attempt to fetch token data from alcor swap
  useEffect(() => {
    if (wax.initialized === 'NO') {
      dispatch(connectWombat())
    }
    dispatch(initialiseDisplayValues(Network.WAX))
    dispatch(getAlcorTokens())
  }, [])

  // Effect to load user data on the WAX network
  useEffect(() => {
    if (wax.initialized === 'YES') {
      loadUserData()
    }
  }, [wax.initialized, waxConfig.availableTokens])

  // Effect to create a delayed refresh when the store property for that is set
  useEffect(() => {
    // Reload WAX user data if the reinitializeIn property is set to a value > 0.
    if (wax.reinitializeIn > 0) {
      console.log('Reloading WAX data in %d milliseconds', wax.reinitializeIn)
      // Immediately clear the refresh in the state, so it doesn't get triggered again. Since this
      // changes wax.reinitializeIn we can _not_ clear the timeout when the effect exits as a change
      // in dependencies will trigger the cleanup code and the timeout would always be cancelled.
      dispatch(clearRefresh())
      setTimeout(() => {
        loadUserData()
        console.log('WAX data successfully reloaded')
      }, wax.reinitializeIn)
    }
  }, [wax.reinitializeIn])

  return <>{props.children}</>
}
