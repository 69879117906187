import { useEffect, useRef } from 'react'

/**
 * Adds 'debounce' functionality for a callback.
 * This debounce hook delays the execution of a callback by the delay specified in MS.
 * If the callback is called again before the delay expires, the delay is reset and
 * the callback is called only once, after the delay has expired.
 * @param callbackForDebounce Callback should be called
 * @param delay Delay for the callback calling
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDebouncedCallback = <A extends any[]>(
  callbackForDebounce: (...args: A) => void,
  delay: number,
) => {
  // Track args and timeout handle between calls
  const argsRef = useRef<A>()
  const timeout = useRef<ReturnType<typeof setTimeout>>()

  const cleanup = () => {
    if (timeout.current) {
      clearTimeout(timeout.current)
    }
  }
  // Clear the timeout if the consuming component is unmounted
  useEffect(() => cleanup, [])

  return function debouncedCallback (
    ...args: A
  ) {
    // capture latest args
    argsRef.current = args

    // clear debounce timer
    cleanup()

    // restart delay
    timeout.current = setTimeout(() => {
      if (argsRef.current) {
        callbackForDebounce(...argsRef.current)
      }
    }, delay)
  }
}
