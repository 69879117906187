import { useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAppSelector } from './useAppSelector'
import { useAppDispatch } from './useAppDispatch'
import { SupportedToken, OwnedToken, Allowance } from '../store/processRawTokenData'
import { TokenAmount } from '../numbers/TokenAmount'
import { clearWarnings, resetMaxPriceImpact, storeWarning } from '../store/singleChainSlice'
import { singleChainSwapErrors } from '../store/warnings'
import { InputType } from './useTokensList'

/**
 * The token with the balance as a TokenAmount
 */
export type TokenWithAmount = SupportedToken & {
  /**
   * The token balance as a {@link TokenAmount}
   *
   * This can not be stored in redux since the {@link TokenAmount} is not serialisable, therefore
   * the balance has to be converted to an {@link TokenAmount} here in order to be used in the UI
   */
  balance: TokenAmount
  /**
   * Whether the user has given permission for this token to be swapped by the swap smart contract
   */
  allowance: Allowance
}

/**
 * Converts the token data from the serializable form in which it is
 * stored in Redux to the {@link TokenAmount} class and adds other
 * data necessary for displaying
 * @param token an available or owned token
 * @returns the {@link TokenListItem} i.e. the name and icon of a token
 */
function formatDisplayData(token: SupportedToken | OwnedToken)
: TokenWithAmount {
  return {
    ...token,
    balance: TokenAmount.fromStringWithDecimals(
      'balance' in token ? token.balance : '0', token.decimals, token.symbol,
    ),
    allowance: ('allowance' in token) ? token.allowance : 'NOT_SET',
  }
}

/**
 * Uses a token's sybmol to identify the token from the list of owned input tokens or tokens
 * available to swap into.
 * @returns the data of the token ready to display
 */
function findTokenData(
  symbol: string | null, tokens: (SupportedToken | OwnedToken)[],
): TokenWithAmount | undefined {
  const token = tokens.find(it => it.symbol === symbol)
  if (token) {
    return formatDisplayData(token)
  }
}

/**
 * The {@link TokenListItem} for tokens which have been selected as inputs
 */
type TokenInputsData = {
  /**
   * Data for the token to swap from
   */
  from: TokenWithAmount
  /**
   * Data for the token to swap to
   */
  to?: TokenWithAmount
  /**
   * Updates the token parameters stored in the URL
   * (i.e. the `to` and `from` token key-value pairs)
   */
  updateUrlTokens: (type: InputType, symbol: string) => void
  /**
   * Switches the input and output tokens, when both are owned
   */
  reverseTokens: () => void
}

/**
 * A hook to keep track of the two tokens which have been selected to
 * be swapped from and to.
 * @returns The {@link TokenListItem} for a token which has been
 * selected to be swapped from, and the token which has been selected
 * to be swapped into, if present.
 */
export const useTokenInputs = (): TokenInputsData => {
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const { ownedTokens, availableTokens, network } = useAppSelector(state => state.singleChain)
  const defaultFrom = useAppSelector(state => state.configs[network.symbol].mainTokenZeroBalance)

  /**
   * A token symbol which is set as the token to swap 'from' via
   * the URL 'from' parameter. If no 'from' is set, default to the
   * chain main token.
   */
  const fromParam = searchParams.get('from') ?? defaultFrom.symbol

  /**
   * The token which is being input to the swap, i.e. swapped from
   */
  const from: TokenWithAmount = useMemo(() => {
    return findTokenData(fromParam, ownedTokens) ?? formatDisplayData(defaultFrom)
  }, [defaultFrom, fromParam, ownedTokens])

  /**
   * A token symbol which is set as the token to swap 'to' via
   * the URL 'to' parameter
   */
  const toParam = searchParams.get('to')

  /**
   * The token which is being output from the swap, i.e. swapped to
   */
  const to: TokenWithAmount | undefined = useMemo(() => {
    if (toParam) {
      return findTokenData(toParam, availableTokens)
    }
  }, [toParam, availableTokens])

  /**
   * Updates the `to` and `from` tokens stored in the URL
   * @param type whether to update the `to` or `from` key
   * @param symbol the token symbol
   */
  function updateUrlTokens(type: InputType, symbol: string): void {
    // clear any existing warnings
    dispatch(clearWarnings())
    const updatedSearchParams = new URLSearchParams(searchParams.toString())
    updatedSearchParams.set(type, symbol)
    setSearchParams(updatedSearchParams.toString())
  }

  /**
   * Switches the input and the output tokens, when both are owned
   */
  function reverseTokens() {
    const toIsOwned = ownedTokens.find(owned => owned.symbol === toParam)
    if (toParam && toIsOwned) {
      const updatedSearchParams = new URLSearchParams(searchParams.toString())
      updatedSearchParams.set('from', toParam)
      updatedSearchParams.set('to', fromParam)
      setSearchParams(updatedSearchParams.toString())
    } else if (!toParam) {
      return
    } else if (!toIsOwned) {
      dispatch(storeWarning([singleChainSwapErrors.TOKEN_NOT_OWNED]))
    }
  }

  // Ensure that the max price impact is always restored to the default value for new token inputs
  useEffect(() => {
    dispatch(resetMaxPriceImpact())
  }, [to, from])

  return { from, to, updateUrlTokens, reverseTokens }
}
