import cl from 'classnames'
import React, { FC } from 'react'

import styles from './Action.module.scss'

/**
 * The data for an action available in the header
 */
type Action = {
  /**
   * Data for the icon at the left of the action, where present
   */
  leftIcon?: {
    /**
     * A link to the icon image
     */
    imgUrl: string | undefined
    /**
     * Whether the icon is present on desktop
     */
    desktop: boolean
  }
  text: {
    /**
     * The text for the action
     */
    value: string
    /**
     * Additional styles for the CTA text, e.g. hiding the text on a mobile view
     */
    style?: 'hide-mobile'
  }
  /**
   * The button label for assistive technologies i.e. screenreaders
   */
  label: string
  /**
   * Data for the icon at the right of the action
   */
  rightIcon: {
    /**
     * A link to the icon image
     */
    imgUrl: string | undefined
    /**
     * Whether the icon is hidden on a mobile view
     */
    hideForMobile: boolean
  }
  /**
   * The function to execute when the action is clicked
   */
  onClick: () => unknown
  /**
   * Whether the action is disabled
   */
  disabled?: boolean
  /**
   * Whether the action is disconnected
   */
  disconnected?: boolean
}

/**
 * The UI component for an action relating to the user wallet, e.g. switching chains,
 * disconnecting etc.
 */
export const Action: FC<Action> = props => (
  <button className={cl(styles['network-action'], `${props.disconnected ? styles['disconnected'] : ''}`,
    `${props.disabled ? styles['disabled'] : ''}`)} onClick={props.onClick}
  disabled={props.disabled} aria-label={props.label}>
    {props.leftIcon?.imgUrl && <img src={props.leftIcon.imgUrl} aria-hidden className={
      cl(styles['icon'], styles['left'], props.leftIcon.desktop ? `${styles['hide-mobile']}` : '')
    }/>}
    <p className={props.text.style ? styles[props.text.style] : ''}>{props.text.value}</p>
    {props.rightIcon?.imgUrl ? <img src={props.rightIcon.imgUrl} aria-hidden className={
      cl(styles['icon'], styles['right'], props.rightIcon.hideForMobile ? `${styles['hide-mobile']}` : '')
    }/> : <div className={cl(styles['icon__placeholder'], styles['hide-mobile'])}></div>}
  </button>
)
