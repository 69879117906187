import { AnyAction, configureStore, ThunkMiddleware } from '@reduxjs/toolkit'
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore'
import userReducer, { UserState } from './userSlice'
import configsReducer, { ConfigsState } from './configsSlice'
import singleChainReducer, { SingleChainState } from './singleChainSlice'
import transactionsReducer, { TransactionsState } from './transactionsSlice'
import multiChainReducer, { MultiChainState } from '../features/multichain-swap/store/multiChainSlice'

export type RootState = {
  /**
   * The user's data for all supported networks
   */
  user: UserState
  /**
   * Token configuration
   */
  configs: ConfigsState
  /*
   * The data for displaying in the simple, single-chain swap interface
   */
  singleChain: SingleChainState
  /**
   * State for the cross-chain swap functionality
   */
  multiChain: MultiChainState
  /**
   * Transactions which are being processed
   */
  transactions: TransactionsState
}

type Store = ToolkitStore<RootState, AnyAction, [ThunkMiddleware<RootState, AnyAction, undefined>]>

/**
 * The redux store, built out of all combined reducers
 */
export const store: Store = configureStore<RootState>({
  reducer: {
    user: userReducer,
    configs: configsReducer,
    singleChain: singleChainReducer,
    transactions: transactionsReducer,
    multiChain: multiChainReducer,
  },
})

/**
 * `AppDispatch` type is inferred from the store itself
 */
export type AppDispatch = typeof store.dispatch

/**
 * Type to use in createAsyncThunk<A, B, AsyncThunkOptions> to get correctly typed access to the
 * state and dispatch from thunkAPI
 */
export type AsyncThunkOptions = { state: RootState, dispatch: AppDispatch }
