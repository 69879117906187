.swap {
  display: flex;

  &__cta {
    width: 100%;
    max-width: 686px;
  }

  @media (min-width: 768px) {
    width: unset;
  }
}
