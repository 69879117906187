@import '../../assets/styles/variables';
@import '../../assets/styles/typography';

.actions {
  display: flex;
  gap: 8px;

  @media (min-width: 768px) {
    width: 100%;
    justify-content: center;
  }

  &--secondary-header {
    justify-content: end;
  }
}

.icon {
  width: 32px;
  height: 32px;
}

.button {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: var(--colorSurfaceDark);
  width: 210px;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.30), 0px 8px 12px 6px rgba(0, 0, 0, 0.15);
  padding: 12px 24px;
  margin-top: 8px;
  margin-right: 32px;
  color: var(--colorHighlight);
  @include text-small(left, 700);
  z-index: 1;
  right: 0;

  @media (min-width: 768px) {
    right: unset;
  }

  .icon {
    width: 20px;
    height: 20px;
  }
}
