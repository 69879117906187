import { ethers } from 'ethers'
import { TransactionResponse } from './paraswap-api/paraswap-types'
import { Ethereum } from '../../types/ethereum'

/**
 * The ethers provider to communicate with the chain.
 */
// This should use the type "ethers.providers.JsonRpcProvider | undefined", but this makes
// interacting with it rather cumbersome. Instead it is assumed the app checks once in the beginning
// that this exists and renders an error otherwise.
export let provider: ethers.providers.JsonRpcProvider

export function setProvider() {
  const ethereum: Ethereum | undefined = window.ethereum
  if (ethereum) {
  // Allow any network - so we can hint the user to switch the network if necessary
    provider = new ethers.providers.Web3Provider(ethereum, 'any')
  }
}

setProvider()
document.addEventListener('ethereum#initialized', () => {
  setProvider()
})

/**
 * Parameters to add a network to a provider, for the RPC method 'wallet_addEthereumChain'
 */
type AddNetworkParams = {
  /**
   * The chain id of the network to add. Must be in hexadecimal with a leading '0x'
   */
  chainId: string
  /**
   * The name of the chain
   */
  chainName: string
  /**
   * Description of the native currency of the chain
   */
  nativeCurrency: {
    /**
     * The name of the currency.
     * Note: This seems to be ignored by Metamask
     */
    name: string
    /**
     * The symbol of the currency
     */
    symbol: string
    /**
     * The number of digits of the currency
     */
    decimals: number
  }
  /**
   * RPC URLs for the chain
   */
  rpcUrls?: string[]
  /**
   * Block explorer URLs for the chain
   */
  blockExplorerUrls?: string[]
}

/**
 * Data for the EVM networks
 * https://www.coincarp.com/chainlist/
 */
export const NETWORK_DATA: Record<number, AddNetworkParams> = {
  1: { // Ethereum main network
    chainId: '0x1', // 1 as hexadecimal
    chainName: 'Ethereum Mainnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://cloudflare-eth.com'],
    blockExplorerUrls: ['https://cloudflare-eth.com'],

  },
  31337: { // Local Hardhat Network
    chainId: '0x7a69', // 31337 as hexadecimal
    chainName: 'Hardhat',
    nativeCurrency: {
      name: 'HardhatETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['http://localhost:8545'],
  },
  5: { // Goerli Test network
    chainId: '0x5',
    chainName: 'Goerli Test Network',
    nativeCurrency: {
      name: 'GoerliETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://goerli.infura.io/v3/'],
    blockExplorerUrls: ['https://goerli.etherscan.io'],
  },
  137: { // Polygon main network
    chainId: '0x89',
    chainName: 'Polygon Mainnet',
    nativeCurrency: {
      name: 'POL',
      symbol: 'POL',
      decimals: 18,
    },
    rpcUrls: ['https://polygon-rpc.com/'],
    blockExplorerUrls: ['https://polygonscan.com/'],
  },
  80001: { // Mumbai test network
    chainId: '0x13881',
    chainName: 'Polygon Testnet',
    nativeCurrency: {
      name: 'POL',
      symbol: 'POL',
      decimals: 18,
    },
    rpcUrls: ['https://matic-mumbai.chainstacklabs.com'],
    blockExplorerUrls: ['https://mumbai.polygonscan.com/'],
  },
  56: { // BNB main network
    chainId: '0x38',
    chainName: 'Binance Smart Chain',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://bsc-dataseed.binance.org/'],
    blockExplorerUrls: ['https://bscscan.com/'],
  },
  97: { // BNB test network
    chainId: '0x61',
    chainName: 'Smart Chain - Testnet',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
    blockExplorerUrls: ['https://testnet.bscscan.com'],
  },
  43114: { // AVAX main network
    chainId: '0xa86a',
    chainName: 'Avalanche Network',
    nativeCurrency: {
      name: 'AVAX',
      symbol: 'AVAX',
      decimals: 18,
    },
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://snowtrace.io/', 'https://subnets.avax.network/c-chain'],
  },
  43113: { // AVAX test network
    chainId: '0xA869',
    chainName: 'Avalanche Fuji Testnet',
    nativeCurrency: {
      name: 'AVAX',
      symbol: 'AVAX',
      decimals: 18,
    },
    rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://cchain.explorer.avax-test.network'],
  },
}

/**
 * Request that the provider switches the network to the chain with the given id. If the provider
 * does not know the chain it will be added to it.
 *
 * Only works for chain ids registered in {@link NETWORK_DATA}.
 * @param chainId The chain id to switch to.
 *
 * @return The selected network. provider.on('network', func) will be triggered for Metamask but not
 * for wombat wallet. Thus, we want to set network manually to the store.
 */
export async function switchChain(chainId: number) {
  const networkData = NETWORK_DATA[chainId]
  if (!networkData) {
    throw new Error(`Unsupported chain ${chainId}`)
  }

  await provider.send(
    'wallet_addEthereumChain',
    [networkData],
  )

  // returned when the request is accepted or rejected
  return {
    chainId,
    name: networkData.chainName,
  }
}

/**
 * Request to execute a swap transaction on an EVM chain
 *
 * @returns if successful, the transaction id in hex format, otherwise void
 * @example '0x0d40989ec930311fa4e7f471fc0689ac6bfae3c1f23af035c2b152fa1c810635'
 */
export async function transact(swap: TransactionResponse)
: Promise<string> {
  // the values must be passed as hex values since they are interpreted as hex values in the
  // iOS client, regardless of whether it is actually in WEI.
  // Transaction parameters are also required in hex format by Metamask.
  const bigIntValue = ethers.BigNumber.from(swap.value).toHexString()
  const gasAsHex = ethers.BigNumber.from(swap.gas).toHexString()
  const gasPriceAsHex = swap.gasPrice !== '' ? ethers.BigNumber.from(swap.gasPrice).toHexString() : ''
  const params = {
    to: swap.to,
    from: swap.from,
    gas: gasAsHex,
    value: bigIntValue,
    data: swap.data,
    gasPrice: gasPriceAsHex,
  }

  return await provider.send(
    'eth_sendTransaction',
    [params],
  )
}
