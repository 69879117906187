import React, { FC, useMemo, useState } from 'react'
import { Virtuoso } from 'react-virtuoso'
import { InputType, ListItem } from '../../hooks/useTokensList'
import { ListHeader } from '../../elements/ListHeader/ListHeader'
import { truncateNumber } from '../multichain-swap/views/Preview'

import ITEM from '../../assets/images/token.svg'

import styles from './Selector.module.scss'

/**
 * Filters a list of items by a search string
 *
 * @param val the value of the search
 * @param items the list of items to filter
 * @returns a list of items where the symbol contains the search string
 */
function filterItems(val: string, items: ListItem[]) {
  if (val.length > 0) {
    // TODO - replace symbol with ID
    return items.filter(item => item.symbol.toLowerCase().includes(val.toLowerCase()))
  } else {
    return items
  }
}

type SelectorProps = {
  /**
   * The name of the selector
   * @example 'token' 'blockchain'
   */
  name: string
  /**
   * The list of available options
   */
  items: ListItem[]
  /**
   * The symbol of an item which is already selected, if present
   */
  preselected?: string
  /**
   * Whether the selector is for the inputs being swapped `from` or `to`
   */
  type: InputType
  /**
   * A function for when an item is selected
   */
  onSelect: (type: InputType, item: ListItem) => void
  /**
   * A function to close the selector
   */
  closeSelector: () => void
}

/**
 * A UI component which allows the user to select an item
 */
export const Selector: FC<SelectorProps> = props => {
  const [ searchString, setSearchString ] = useState<string>('')

  // Filters items by a search string
  const filteredItems = useMemo(() => filterItems(searchString, props.items),
    [searchString, props.items])

  // Items with a shortened displayed balance
  const items = useMemo(() => {
    return filteredItems.map(it => {
      return {
        ...it,
        balance: it.balance ? truncateNumber(it.balance) : it.balance,
      }
    })
  }, [filteredItems])

  /**
   * A function to handle when a token is selected
   */
  function handleSelect(item: ListItem): void {
    props.onSelect(props.type, item)
    props.closeSelector()
  }

  return (
    <div className={styles['dropdown']}>
      <ListHeader closeList={props.closeSelector} className='header--desktop-hidden'>
        Please select a {props.name}
      </ListHeader>
      <div className={styles['search']}>
        <input className={styles['input']} type='text' placeholder={`Search for a ${props.name}`}
          onChange={e => setSearchString(e.currentTarget.value)} autoFocus />
      </div>
      <SelectorDumb preselected={props.preselected} items={items} onSelect={handleSelect}/>
    </div>
  )
}

type Props = {
  /**
   * A list of items which the user can select from
   */
  items: ListItem[]
  /**
   * The symbol of the item which is already selected, if present
   * @example ‘WOMBAT’
   */
  preselected?: string
  /**
   * A callback for when an item is selected
   */
  onSelect: (item: ListItem) => void
}

/**
 * The dumb UI component displaying the list to select from
 */
const SelectorDumb: FC<Props> = props => (
  <div className={styles['virtuoso-scroller-container']}>
    <Virtuoso data={props.items} className={styles['token-list']} itemContent={(_, it) => (
      <li onClick={() => props.onSelect(it)} className={styles['row']} value={it.symbol}
        key={it.symbol + it.iconUrl} aria-live='polite'>
        <span className={styles['token']}>
          <img className={styles['icon']} src={it.iconUrl} loading='lazy' alt =''
            onError={e => e.currentTarget.src = ITEM}/>
          <div className={styles['']}>
            <p>{it.symbol}</p>
            {it.chain && <p className={styles['chain']}>{it.chain}</p>}
          </div>
        </span>
        {it.balance && <span className={styles['amount']}>{it.balance}</span>}
      </li>
    )}/>
  </div>
)
