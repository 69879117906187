import cl from 'classnames'
import React, { FC, useEffect, useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAppSelector } from '../hooks/useAppSelector'
import { TxState } from '../store/transactionsSlice'
import { Network, NetworkSymbol } from '../configs/networks'
import { ScanName } from '../services/evm/gas-prices'
import { TxErrorType, transactionErrors } from '../store/warnings'
import { ParaswapTransactionError } from '../services/evm/paraswap-api/paraswap-types'

import CHECKMARK from '../assets/images/checked-circle-green.svg'
import INFO_YELLOW from '../assets/images/info-orange.svg'
import ERROR from '../assets/images/warning.svg'
import CHEVRON from '../assets/images/chevron-down.svg'

import styles from './TransactionStatus.module.scss'

/**
 * The content of the TransactionStatus screen
 */
type Content = {
  /**
   * And icon which shows the status of a transaction
   */
  icon: string
  /**
   * A text explaining the status of a transaction
   */
  text: string
}

/**
 * The start of the error returned when the transaction confirmation/rejection popup times out
 */
const timeoutRegex = /Timeout reached waiting for the answer in popup.*/

/**
 * The start of the error returned when the transaction fails due to slippage
 */
const slippageRegex = /Received lower than minTokenOut.*/

/**
 * Returns the content of the TransactionStatus UI depending on the status of the transaction
 * @param status the {@link TxState}, whether the transaction is successful or failing
 * @param errorMsg an {@link TxErrorType} if the error is known (i.e. saved in the `warnings.ts`)
 * and simply a string if the error is not indexed
 */
function getContent(status: TxState, errorMsg?: TxErrorType | string): Content {
  switch (status) {
    case TxState.CONFIRMED:
      return {
        icon: CHECKMARK,
        text: 'Your transaction was successful.',
      }
    case TxState.PENDING:
      return {
        icon: INFO_YELLOW,
        text: 'The transaction may require a while, please come back in a few minutes.',
      }
    default: {
      let errorText
      if (!errorMsg) {
        errorText = 'Oh no, something went wrong.'
      } else if (errorMsg in transactionErrors) {
        errorText = transactionErrors[errorMsg as TxErrorType].message
      } else if (timeoutRegex.test(errorMsg)) {
        errorText = transactionErrors[ParaswapTransactionError.POPUP_TIMEOUT_REACHED].message
      } else if (slippageRegex.test(errorMsg)) {
        errorText = transactionErrors.SLIPPAGE.message
      } else {
        errorText = errorMsg
      }
      return {
        icon: ERROR, text: errorText,
      }
    }
  }
}

/**
 * An index for links to a transaction for the available chains
 */
type TxLinkIndex = Record<ScanName, string>

/**
 * Return a link to the transaction on the network scan service
 * @param scanName the {@link ScanName} for the service
 * @param hash the transaction hash
 */
export function txLink(scanName: ScanName, hash: string): string {
  const links: TxLinkIndex = {
    snowtrace: `https://${scanName}.io/tx/${hash}`,
    etherscan: `https://${scanName}.io/tx/${hash}`,
    bscscan: `https://${scanName}.com/tx/${hash}`,
    polygonscan: `https://${scanName}.com/tx/${hash}`,
    'wax.bloks': `https://${scanName}.io/transaction/${hash}`,
    bloks: `https://${scanName}.io/transaction/${hash}`,
  }
  return links[scanName]
}

/**
 * Creates the route to navigate to for a transaction depending on whether it succeeds fails or
 * needs more time to process
 */
export function transactionStatusRoute(network: NetworkSymbol, status: TxState, txHash?: string)
: string {
  const query = new URLSearchParams({
    status: status,
    network: network,
  })
  if (txHash) {
    query.set('hash', txHash)
  }
  return `/transaction?${query.toString()}`
}

/**
 * Navigates to success/failed when the status of the transaction changes
 * @param hash The hash of the transaction to monitor, if any
 */
function useNavigateOnStateChange(hash?: string) {
  const transactions = useAppSelector(state => state.transactions)
  const navigate = useNavigate()
  const transaction = useMemo(() => {
    return transactions.find(tx => tx.hash === hash)
  }, [hash, transactions])

  useEffect(() => {
    if (transaction) {
      if (transaction.state === TxState.CONFIRMED || transaction.state === TxState.FAILED) {
        // // At the moment we only support this for Polygon, so it can be hardcoded here
        navigate(transactionStatusRoute(transaction.network, transaction.state, hash))
      }
    }
  }, [transaction])
}

/**
 * A UI component which shows the status of a transaction
 */
export const TransactionStatus: FC = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const statusParam = searchParams.get('status') as TxState
  const network = searchParams.get('network') as Network
  const txHash = searchParams.get('hash') as string | undefined
  const error = searchParams.get('error') as string | undefined
  const config = useAppSelector(state => state.configs[network])
  useNavigateOnStateChange(txHash)
  const content = getContent(statusParam, error)

  /**
   * A link to the transaction on the network scan service
   */
  const transaction = useMemo(() => {
    if (txHash) {
      return txLink(config.scanService.name, txHash)
    }
  }, [config.scanService.name, txHash])

  return (
    <div className={cl('element-container--desktop-only', styles['transaction-status'])}>
      <img src={content.icon} alt='icon' className={styles['transaction-status__icon']}/>
      <p className={styles['transaction-status__text']}>{content.text}</p>
      {/* TODO - currently only implemented for EVM chains, add txHash for EOSIO */}
      {txHash && (
        <a href={transaction} onClick={e => e.stopPropagation()} rel='noreferrer'
          target='_blank' className={styles['transaction-status__text']}>
          Monitor your transaction status here
        </a>)}
      <button className={styles['transaction-status__btn']} onClick={() => navigate(`/${network}`)}>
        <p>Back to Swap Office</p>
        <img src={CHEVRON} alt='chevron' className={styles['transaction-status__chevron']}/>
      </button>
    </div>
  )
}
