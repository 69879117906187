import { TokenAmount } from '../numbers/TokenAmount'
import { ParaswapPriceErrors, ParaswapTransactionError } from '../services/evm/paraswap-api/paraswap-types'
import { DisplayName } from '../configs/networks'

/**
 * Keys for all quote warning texts to show in the UI
 */
export enum SingleChainErrorKey {
  /**
   * Fallback error for failing to return a quote for the token pair
   */
  QUOTE = 'QUOTE',
  /**
   * Call to fetch a quote from the api failed
   */
  QUOTE_FETCH_FAILED = 'QUOTE_FETCH_FAILED',
  /**
   * A wrong config is provided
   */
  CONFIG = 'CONFIG',
  /**
   * The same token has been set as the input and output for the swap
   */
  SAME_TOKEN = 'SAME_TOKEN',
  /**
   * The api for fetching a quote has returned a quote for an input amount which different from the
   * one entered by the user
   */
  AMOUNT_MISMATCH = 'AMOUNT_MISMATCH',
  /**
   * Failed to fetch gas price
   */
  GAS_PRICE = 'GAS_PRICE',
  /**
   * Unable to calculate the referrer fee
   */
  REFERRER_FEE = 'REFERRER_FEE',
  /**
   * Unable to fetch the fee in USD
   */
  USD_FEE = 'USD_FEE',
  /**
   * When the price impact can not be calculated
   */
  FAILED_TO_CALCULATE_PRICE_IMPACT = 'FAILED_TO_CALCULATE_PRICE_IMPACT',
  /**
   * Insufficient main token balance to cover transaction fees
   *
   * This is different from INSUFFICIENT_BALANCE since that refers to the input token, whereas the
   * transaction fees are always deducted from the user's balance of the network's main token.
   */
  TRANSACTION_FEES = 'TRANSACTION_FEES',
  /**
   * Insufficient input token balance to execute transaction
   */
  INSUFFICIENT_BALANCE = 'INSUFFICIENT_BALANCE',
  /**
   * When the user's allowed price impact is lower than the estimated price impact
   */
  NOT_ALLOWED_PRICE_IMPACT = 'NOT_ALLOWED_PRICE_IMPACT',
  /**
   * There is no liquidity pool available for this token pair
   */
  LIQUIDITY_POOL = 'LIQUIDITY_POOL',
  /**
   * The token can not be traded because it is not owned by the user
   */
  TOKEN_NOT_OWNED = 'TOKEN_NOT_OWNED',
  /**
   * When there is no token data for exchanging tokens on Alcor Exchange
   */
  NO_ALCOR_EXCHANGE_TOKEN_DATA = 'NO_ALCOR_EXCHANGE_TOKEN_DATA'
}

/**
 * Errors which arise in the process of inputting values for the swap or fetching an initial quote
 */
export type SingleChainSwapError = SingleChainErrorKey | ParaswapPriceErrors

/**
 * Keys for all errors relating to the multichain swap
 */
export enum MultiChainSwapError {
  /**
   * When the api call fails
   */
  FAILED_TO_FETCH = 'FAILED_TO_FETCH',
  /**
   * When the changelly API returns a -32603 internal error code - their documentation says this is
   * probably something on their side
   */
  CHANGELLY_ERROR = 'CHANGELLY_ERROR',
  /**
   * When the API fails to find min and max values for this pair
   */
  NO_MIN_MAX = 'NO_MIN_MAX',
  /**
   * When a value passed to the API is not valid
   */
  INVALID_PARAMETER = 'INVALID_PARAMETER',
  /**
   * When an amount is input which is below the min or above the max swappable value
   * @example 'Invalid amount for pair id->dao. Minimal amount is 276 id'
   */
  INVALID_AMOUNT = 'INVALID_AMOUNT',
  /**
   * When an invalid receiver address is entered
   */
  INVALID_RECEIVER = 'INVALID_RECEIVER',
  // TODO - combine the two below
  /**
   * When the token is not valid as the input token
   */
  INVALID_INPUT_TOKEN = 'INVALID_INPUT_TOKEN',
  /**
   * When the token is not valid as the output token
   */
  INVALID_OUTPUT_TOKEN = 'INVALID_OUTPUT_TOKEN'
}

/**
 * Warnings which have dynamic values in the messages
 * e.g. a price impact percentage
 */
type DynamicErrors = SingleChainErrorKey.TRANSACTION_FEES |
SingleChainErrorKey.TOKEN_NOT_OWNED | MultiChainSwapError.INVALID_AMOUNT |
MultiChainSwapError.INVALID_PARAMETER | AuthError.CHAIN_NOT_FOUND |
SingleChainErrorKey.NOT_ALLOWED_PRICE_IMPACT

/**
 * An index of all warnings which use dynamic values in their messages
 */
type DynamicWarningIndex = Record<DynamicErrors, {
  /**
   * A function to create the message dynamically by inserting up to two values
   * @param val the first dynamic value, always defined since the message must have at least one
   * this can be a string or {@link TokenAmount}
   * @example '6.786'
   * @param val2 a second dynamic value, defined if needed for the message
   * @return the message with the dynamic values included
   */
  message(val: string | TokenAmount, val2?: string): string
}>

/**
 * Generates warning messages where the message should contain dynamic values
 */
export const warningGenerator: DynamicWarningIndex = {
  TRANSACTION_FEES: {
    message(balance: TokenAmount, symbol: string): string {
      return `You need approximately ${balance.display(true, balance.decimals)} more ${symbol} to
      cover the transaction fees at our recommended ${symbol} gas price. Buy more or try reducing
      your 'Swap from' amount.`
    },
  },
  NOT_ALLOWED_PRICE_IMPACT: {
    message(priceImpact: string): string {
      const formatted = priceImpact.replace('%', '')
      return `This swap's maximum price impact is estimated at ${formatted}%. This is higher than
      your maximum allowed. This means a worse exchange rate. Adjust your maximum if you want to
      swap anyway.`
    },
  },
  TOKEN_NOT_OWNED: {
    message(symbol: string): string {
      return `You have 0 ${symbol}. Get more ${symbol} to use it as a token to swap from`
    },
  },
  INVALID_PARAMETER: {
    message(error: string): string {
      return error
    },
  },
  INVALID_AMOUNT: {
    message(error: string): string {
      return error
    },
  },
  CHAIN_NOT_FOUND: {
    message(network: DisplayName): string {
      return `No ${network} account detected - cannot connect the wallet for ${network} network.
        Please add ${network} to your wallet in order to connect.`
    },
  },
}

/**
 * Key for a fallback error to show in the UI
 */
export enum FallbackError {
  /**
   * A generic error message as a fallback
   */
  GENERIC_FAIL = 'GENERIC_FAIL'
}

/**
 * Keys for all authorisation warning texts to show in the UI
 */
export enum AuthError {
  /**
   * When no wallet provider is detected
   */
  WALLET_NOT_FOUND = 'WALLET_NOT_FOUND',
  /**
   * When the wallet does not have an account for the chain
   */
  CHAIN_NOT_FOUND = 'CHAIN_NOT_FOUND',
  /**
   * If there is no EVM provider available in the browser
   */
  NO_PROVIDER = 'NO_PROVIDER'
}

export type DisplayErrorCode = SingleChainSwapError | FallbackError | AuthError
| MultiChainSwapError

/**
 * A warning to display in the UI
 */
export type DisplayError = {
  /**
   * The error identifier
   */
  code: DisplayErrorCode
  /**
   * For which value the error occurs - this changes the placement of the error in the UI
   */
  type: 'input' | 'output' | 'auth'
  /**
   * The message to display in the Swap Office UI
   */
  message: string
  /**
   * The serverity of an issue: if 'error', it should disable the swap, if 'warning' it is simply
   * important to notify the user.
   */
  severity: 'error' | 'warning'
}

/**
 * An index for all quote warnings to show in the UI
 */
export type SingleChainSwapErrorsIndex = Record<SingleChainSwapError, DisplayError>

/**
 * Errors and warnings which should be shown to the user which arise whilst getting a quote for
 * inputs to the single chain swap
 */
export const singleChainSwapErrors: SingleChainSwapErrorsIndex = {
  QUOTE: {
    code: SingleChainErrorKey.QUOTE,
    type: 'output',
    message: 'Could not calculate a quote for this token pair',
    severity: 'error',
  },
  QUOTE_FETCH_FAILED: {
    code: SingleChainErrorKey.QUOTE_FETCH_FAILED,
    type: 'output',
    message: 'The quote fetch returned an error for this pair',
    severity: 'error',
  },
  CONFIG: {
    code: SingleChainErrorKey.CONFIG,
    type: 'output',
    message: 'There is an issue with the network configuration, please try refreshing the page',
    severity: 'error',
  },
  SAME_TOKEN: {
    code: SingleChainErrorKey.SAME_TOKEN,
    type: 'output',
    message: 'You are attempting to swap into the same token',
    severity: 'error',
  },
  // a safeguard - it seems to have happened once that the src amount received from the API and
  // user input amount did not appear the same, but cannot currently reproduce
  AMOUNT_MISMATCH: {
    code: SingleChainErrorKey.AMOUNT_MISMATCH,
    type: 'output',
    message: 'Oops, something went wrong, please try re-inputting the amount',
    severity: 'error',
  },
  GAS_PRICE: {
    code: SingleChainErrorKey.GAS_PRICE,
    type: 'output',
    message: 'Could not estimate gas fees for this transaction',
    severity: 'error',
  },
  REFERRER_FEE: {
    code: SingleChainErrorKey.REFERRER_FEE,
    type: 'output',
    message: 'Unable to calculate the referrer fee.',
    severity: 'error',
  },
  USD_FEE: {
    code: SingleChainErrorKey.USD_FEE,
    type: 'output',
    message: 'Unable to fetch the fee in USD for this transaction.',
    severity: 'warning',
  },
  TRANSACTION_FEES: {
    code: SingleChainErrorKey.TRANSACTION_FEES,
    type: 'output',
    // must be generated using `warningGenerator.TRANSACTION_FEES.message(balance, symbol)`
    message: `You need more main token balance for transaction fees. Buy more or try reducing
      your 'Swap from' amount`,
    severity: 'error',
  },
  LIQUIDITY_POOL: {
    type: 'output',
    code: SingleChainErrorKey.LIQUIDITY_POOL,
    message: 'No liquidity pool is available for this pair',
    severity: 'error',
  },
  INSUFFICIENT_BALANCE: {
    code: SingleChainErrorKey.INSUFFICIENT_BALANCE,
    type: 'input',
    message: 'You have insufficient balance. Please adjust the amount.',
    severity: 'error',
  },
  TOKEN_NOT_OWNED: {
    code: SingleChainErrorKey.TOKEN_NOT_OWNED,
    type: 'output',
    message: 'You have 0 balance of this token. Get more to use it as a token to swap from',
    severity: 'error',
  },
  // TODO - paraswap errors - find a neat way to integrate them to the other errors, some repetiton
  'No routes found with enough liquidity': {
    code: ParaswapPriceErrors.LIQUIDITY,
    type: 'output',
    message: 'No liquidity pool is available for this pair',
    severity: 'error',
  },
  NOT_ALLOWED_PRICE_IMPACT: {
    code: SingleChainErrorKey.NOT_ALLOWED_PRICE_IMPACT,
    type: 'output',
    message: `This swap has a higher estimated price impact than your allowed maximum. The actual
    amount received from the swap can be significantly less than the expected output. Adjust your
    maximum to swap anyway`,
    severity: 'error',
  },
  // TODO - integrate with above error - the same error as returned by paraswap api
  'ESTIMATED_LOSS_GREATER_THAN_MAX_IMPACT': {
    code: ParaswapPriceErrors.PRICE_IMPACT,
    type: 'output',
    message: `This swap has a high estimated price impact. The actual amount received from the swap
    can be significantly less than the expected output. Adjust your maximum to swap anyway`,
    severity: 'error',
  },
  FAILED_TO_CALCULATE_PRICE_IMPACT: {
    code: SingleChainErrorKey.FAILED_TO_CALCULATE_PRICE_IMPACT,
    type: 'output',
    message: `The price impact was not calculated for this swap - try inputting the values to
    calculate again. You can execute the swap anyway, but you risk receiving significantly less
    token than expected.`,
    severity: 'warning',
  },
  NO_ALCOR_EXCHANGE_TOKEN_DATA: {
    code: SingleChainErrorKey.NO_ALCOR_EXCHANGE_TOKEN_DATA,
    type: 'output',
    message: 'No tokens found for exchange - attempting to refetch exchange tokens. Please try again.',
    severity: 'error',
  },
}

/**
 * Errors which relate to high price impact of a swap, a.k.a. slippage
 // TODO - check if all of these are still individually necessary. Slippage / price impact data is
 // different for EVM vs. EOSIO chains
 */
export const PRICE_IMPACT_WARNING_CODES = [
  singleChainSwapErrors.ESTIMATED_LOSS_GREATER_THAN_MAX_IMPACT.code,
  singleChainSwapErrors.NOT_ALLOWED_PRICE_IMPACT.code,
]

/**
 * An index for all multi chain warnings to show in the UI
 */
export type MultiChainSwapErrorsIndex = Record<MultiChainSwapError, DisplayError>

/**
 * Errors and warnings which should be shown to the user which arise whilst getting quotes for
 * inputs to the multichain swap
 */
export const multiChainSwapError: MultiChainSwapErrorsIndex = {
  FAILED_TO_FETCH: {
    code: MultiChainSwapError.FAILED_TO_FETCH,
    type: 'auth',
    message: 'The server is experiencing some issue. Please refresh and try again.',
    severity: 'error',
  },
  NO_MIN_MAX: {
    code: MultiChainSwapError.NO_MIN_MAX,
    type: 'output',
    message: 'No minimum and maximum values could be found for this pair.',
    severity: 'error',
  },
  INVALID_PARAMETER: {
    code: MultiChainSwapError.INVALID_PARAMETER,
    type: 'input',
    message: 'Invalid value input to the swap',
    severity: 'error',
  },
  INVALID_AMOUNT: {
    code: MultiChainSwapError.INVALID_AMOUNT,
    type: 'input',
    message: 'Invalid input amount for this pair, please input an amount between the minimum and the maximum.',
    severity: 'error',
  },
  INVALID_INPUT_TOKEN: {
    code: MultiChainSwapError.INVALID_INPUT_TOKEN,
    type: 'input',
    message: 'The token you are trying to set is not enabled as an input token',
    // this error should not disable the swap, it should simply disallow the token to be input
    severity: 'warning',
  },
  INVALID_OUTPUT_TOKEN: {
    code: MultiChainSwapError.INVALID_OUTPUT_TOKEN,
    type: 'output',
    message: 'The token you are trying to set is not enabled as an output token',
    // this error should not disable the swap, it should simply disallow the token to be input
    severity: 'warning',
  },
  INVALID_RECEIVER: {
    code: MultiChainSwapError.INVALID_RECEIVER,
    type: 'output',
    message: `This receiver address can't accept the output token. Please input a valid receiver
    address.`,
    severity: 'error',
  },
  CHANGELLY_ERROR: {
    code: MultiChainSwapError.CHANGELLY_ERROR,
    type: 'output',
    message: `This token combination can't currently be swapped, please try again later or report
    the issue if it persists`,
    severity: 'error',
  },
}

/**
 * An index for all quote warnings to show in the UI
 */
export type AuthErrorsIndex = Record<AuthError, DisplayError>

/**
 * Errors about connecting / authenticating users
 */
export const authErrors: AuthErrorsIndex = {
  WALLET_NOT_FOUND: {
    code: AuthError.WALLET_NOT_FOUND,
    type: 'auth',
    message: 'No crypto wallet detected - could not connect. Please add a wallet to connect.',
    severity: 'warning',
  },
  CHAIN_NOT_FOUND: {
    code: AuthError.CHAIN_NOT_FOUND,
    type: 'auth',
    message: `No account detected for this chain. Please add an account on this chain in order to
    connect to it.`,
    severity: 'warning',
  },
  NO_PROVIDER: {
    code: AuthError.NO_PROVIDER,
    type: 'auth',
    message: 'No EVM provider is detected. Check that your wallet is connected and refresh.',
    severity: 'warning',
  },
}

/**
 * A fallback error to use in case no other is available
 */
const fallbackError: DisplayError = {
  code: FallbackError.GENERIC_FAIL,
  type: 'output',
  message: 'Oops, something went wrong',
  severity: 'error',
}

/**
 * A fallback error to show which also displays the original error message
 * @param msg The error message
 * @returns An error to show in the display with details of the error
 */
function fallbackWithMessage(msg: string): DisplayError {
  return {
    code: FallbackError.GENERIC_FAIL,
    type: 'output',
    message: `Oops, something went wrong: ${msg}`,
    severity: 'error',
  }
}

/**
 * Returns the custom display error if there is one and a fallback error if not
 *
 * @param error The code by which the error is indexed
 * @returns An error message to display in the UI
 */
export function displayError(error: unknown): DisplayError {
  if (error instanceof Error) {
    if (error.message in singleChainSwapErrors) {
      return singleChainSwapErrors[error.message as SingleChainSwapError]
    } else {
      return fallbackWithMessage(error.message)
    }
  } else {
    return fallbackError
  }
}

enum TxGenericError {
  /**
   * Transaction failed due to the slippage being higher than allowed
   */
  SLIPPAGE = 'SLIPPAGE'
}

/**
 * Errors which are returned from a failed transaction
 */
export type TxErrorType = ParaswapTransactionError | TxGenericError

type TxError = {
  /**
   * The error identifier
   */
  code: TxErrorType
  /**
   * The error type is an error with executing the transaction
   */
  type: 'transaction'
  /**
   * The message to display in the UI
   */
  message: string
}

type TransactionErrors = Record<TxErrorType, TxError>

/**
 * The known errors which cause transactions to fail
 */
export const transactionErrors: TransactionErrors = {
  'Validation failed: Can\'t process priceRoute with max impact reached': {
    code: ParaswapTransactionError.MAX_PRICE_IMPACT,
    type: 'transaction',
    message: 'This transaction failed due the price impact being higher than the max allowed.',
  },
  'insufficient funds for gas * price + value': {
    code: ParaswapTransactionError.INSUFFICIENT_FUNDS,
    type: 'transaction',
    message: `This transaction failed because you don't have enough balance for the amount you are
    trading plus fees.`,
  },
  'This transaction has some errors and may fail. Please contact support for more details': {
    code: ParaswapTransactionError.UNSPECIFIED_ISSUES,
    type: 'transaction',
    message: `This transaction has some errors and might fail. Please check back whether the amount
    has been swapped.`,
  },
  'Timeout reached waiting for the answer in popup': {
    code: ParaswapTransactionError.POPUP_TIMEOUT_REACHED,
    type: 'transaction',
    message: 'Timeout reached waiting for the answer in popup',
  },
  SLIPPAGE: {
    code: TxGenericError.SLIPPAGE,
    type: 'transaction',
    message: `Error: The transaction slippage was higher then your maximum allowed.
    Increase max price impact to execute the transaction.`,
  },
}
