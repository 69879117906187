import React, { FC } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { App } from './App'
import { store } from './store'
import { initialiseSentry } from './services/sentry/sentry'

const container = document.getElementById('root')

if (!container) {
  throw new Error('Could not find root element')
}

initialiseSentry()

const Root: FC = () => (
  <Provider store={store}>
    <App/>
  </Provider>
)

const root = createRoot(container)
root.render(
  <Root/>,
)
