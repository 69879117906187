@import '../../assets/styles/typography';

.modal {
  position: absolute;
  left: 0;
  bottom: 0;
  width: calc(100vw - 64px);
  background: var(--colorSurfaceDark);
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  border-radius: 32px 32px 0px 0px;
  z-index: 2;

  @media (min-width: 768px) {
    max-width: 345px;
    bottom: unset;
    margin: 0;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: calc(50% - 202px); 
    border-radius: 32px;
  }
}

.title {
  align-self: flex-start;
  @include headline-medium(left);
}

.preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.token {
  display: flex;
  gap: 4px;
  align-items: center;
  @include text-normal(center, 700)
}

.icon {
  height: 24px;
  width: 24px;
}

.ctas {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 16px;
}
