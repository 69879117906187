import { useAppDispatch } from './useAppDispatch'
import { useAppSelector } from './useAppSelector'
import { TokenWithAmount, useTokenInputs } from './useTokenInputs'
import { TokenAmount } from '../numbers/TokenAmount'
import {
  clearWarnings, setLoading, storeDetails, storeEstimatedSlippage, storeInput, storeOutput,
  storeWarning,
} from '../store/singleChainSlice'
import {
  Network, type EosIoNetworkConfig, type EvmNetworkConfig, ConfigType,
  evmNetworkSymbols,
} from '../configs/networks'
import { OwnedToken } from '../store/processRawTokenData'
import { displayError, singleChainSwapErrors } from '../store/warnings'
import { AppDispatch } from '../store'
import { useDebouncedCallback } from './useDebouncedCallback'
import { getEvmQuote } from '../services/evm/swap-quote'
import { quoteForPair } from '../services/eosio/pair-quote'
import { isSameToken } from '../services/checks'
import { WaxQuoteArgs, getQuote } from '../store/waxSlice'

/**
 * The number of milliseconds to delay debouncing the quote-fetching api calls
 */
const QUOTE_DEBOUNCE_DELAY_MS = 1500

/**
 * Checks that the input amount is more than 0
 * @param amount the user input as a string
 * @param from the token being swapped from
 */
function isZero(amount: string, from: TokenWithAmount): boolean {
  if (amount.length === 0) {
    return true
  }
  const tokenAmount = TokenAmount.fromStringWithDecimals(amount, from.decimals, from.symbol)
  return tokenAmount.eq(TokenAmount.ZERO(from.decimals, from.symbol))
}

/**
 * The data required in order to fetch and calculate a quote for a potential swap
 */
export type QuoteArgs = {
  /**
   * The config for the network being viewed, used for fetching quotes for EVM chains
   */
  config: EvmNetworkConfig | EosIoNetworkConfig
  /**
   * The main token of the network
   */
  mainToken: OwnedToken
  /**
   * The {@link TokenAmount} of the token being swapped from
   */
  amount: TokenAmount
  /**
   * Additional data for the token being swapped from, such as the address
   */
  from: TokenWithAmount
  /**
   * The {@link SupportedToken} data of the token being swapped into
   */
  to: TokenWithAmount
  /**
   * The maximum allowed price impact for a transaction.
   *
   * For EVM chains this can be set as part of the transaction data. Price impact is the difference
   * in percentage between the expected price of a token and the price per token at which the trade
   * is actually executed. It occurs due to the size of liquidity pools and therefore exchange rates
   * changing during a swap. Most price impact will occur when the number of tokens swapped account
   * for a significant proportion of the liquidity pools they are transferred between.
   */
  priceImpactTolerance: number
}

/**
 * Fetches and stores a quote for a swap on EVM networks (e.g. input/output values)
 *
 * @param amount the {@link TokenAmount} to be swapped in
 * @param from the token data ({@link TokenWithAmount} - this includes the user's balance of the
 * token) of the token being swapped `from`, i.e. input to the swap
 * @param to the token data ({@link TokenWithAmount} - this includes the user's balance of the
 * token) of the token being swapped `to`, i.e. output from the swap
 * @param mainToken the main token for the network, e.g. POL for Polygon, as an {@link OwnedToken}
 * @param config the network config data
 * @param dispatch the {@link AppDispatch} function
 * @param priceImpactTolerance The maximum allowed price impact for a transaction
 */
async function fetchEvmQuote(amount: TokenAmount, from: TokenWithAmount, to: TokenWithAmount,
  mainToken: OwnedToken, config: EvmNetworkConfig | EosIoNetworkConfig, dispatch: AppDispatch,
  priceImpactTolerance: number,
): Promise<void> {
  try {
    const quote = await getEvmQuote({ config, mainToken, from, to, amount, priceImpactTolerance })
    if (quote && quote.type === 'success') {
      // stores the output values to display in the UI
      dispatch(storeOutput(quote.amount.display(true, to.decimals)))
      dispatch(storeEstimatedSlippage(quote.estimatedPriceImpact))
      dispatch(storeDetails(quote.details))
      // dispatches an action depending on the network to store data from the quote which is
      // subsequently required if a swap is triggered
      dispatch(quote.actions)
      if (quote.warnings) {
        dispatch(storeWarning(quote.warnings))
      }
    }
  } catch (e) {
    console.error(e)
    const warning = displayError(e)
    dispatch(storeWarning([warning]))
  }

  dispatch(setLoading(false))
}

/**
 * Fetches the data which should be displayed to the user for an EOS swap, alongside values which
 * are necessary to execute the swap technically. Stores returned values.
 *
 * @param amount the {@link TokenAmount} to be swapped in
 * @param from the token data ({@link TokenWithAmount} - this includes the user's balance of the
 * token) of the token being swapped `from`, i.e. input to the swap
 * @param to the token data ({@link TokenWithAmount} - this includes the user's balance of the
 * token) of the token being swapped `to`, i.e. output from the swap
 // TODO - if the functions for the networks keep being kept separately, this can be removed as an
 // argument and defined in the function
 * @param mainToken the main token for the network, e.g. EOS for EOS, as an {@link OwnedToken}
 // TODO - same as above TODO
 * @param config the network config data
 * @param dispatch the {@link AppDispatch} function
 * @param priceImpactTolerance The maximum allowed price impact for a transaction
 */
async function fetchEosData(amount: TokenAmount, from: TokenWithAmount, to: TokenWithAmount,
  mainToken: OwnedToken, config: EosIoNetworkConfig, dispatch: AppDispatch,
  priceImpactTolerance: number): Promise<void> {
  try {
    const quote = await quoteForPair({ config, mainToken, from, to, amount, priceImpactTolerance })
    dispatch(storeOutput(quote.amount.display(true, to.decimals)))
    dispatch(storeDetails(quote.details))
    dispatch(storeEstimatedSlippage(quote.estimatedPriceImpact))
    // dispatches an action depending on the network to store data from the quote which is
    // subsequently required if a swap is triggered
    dispatch(quote.actions)
    dispatch(setLoading(false))
    if (quote.warnings) {
      dispatch(storeWarning(quote.warnings))
    }
  } catch (e) {
    console.error(e)
    const warning = displayError(e)
    dispatch(storeWarning([warning]))
  }
}

/**
 * Hook to provide estimated values for a swap based on the user's inputs
 */
export const useSwapEstimate = () => {
  const dispatch = useAppDispatch()
  const debouncedCallEvm = useDebouncedCallback(fetchEvmQuote, QUOTE_DEBOUNCE_DELAY_MS)
  const debouncedCallEos = useDebouncedCallback(fetchEosData, QUOTE_DEBOUNCE_DELAY_MS)
  const debouncedCallWax = useDebouncedCallback((args: WaxQuoteArgs) => dispatch(getQuote(args)),
    QUOTE_DEBOUNCE_DELAY_MS)
  const { network, mainToken, maxPriceImpact } = useAppSelector(state => state.singleChain)
  const networkConfig = useAppSelector(state => state.configs[network.symbol])
  const { from, to } = useTokenInputs()

  /**
   * Updates the input token amounts based on user inputs
   * @param amount a scaled amount value with decimals as a input by the user
   */
  function handleNewInputAmount(amount: string): void {
    // reset display values
    dispatch(clearWarnings())
    dispatch(storeDetails(undefined))
    dispatch(storeOutput(undefined))
    dispatch(setLoading(false))

    // update the input amount to display
    dispatch(storeInput(amount))

    if (!to || isZero(amount, from)) {
      return
    }

    // don't allow a user to swap into the same token
    if (isSameToken(from, to)) {
      dispatch(storeWarning([singleChainSwapErrors.SAME_TOKEN]))
      return
    }

    const tokenAmount = TokenAmount.fromStringWithDecimals(amount, from.decimals,
      from.symbol)

    // check whether the user input amount is greater than their balance
    if (tokenAmount.gt(from.balance)) {
      dispatch(storeWarning([singleChainSwapErrors.INSUFFICIENT_BALANCE]))
    }

    // Set the state to loading so that the user can feel that something is happening, shortly
    // before starting to send of the API calls to fetch data
    setTimeout(() => dispatch(setLoading(true)), 500)

    // TODO - this can be cleared up once the EVM and WAX quote fetching is refactored and the
    // debounced call can be used for all networks
    if (network.symbol === Network.EOS && networkConfig.type === ConfigType.EOSIO) {
      debouncedCallEos(tokenAmount, from, to, mainToken, networkConfig, dispatch,
        parseFloat(maxPriceImpact),
      )
    }

    if (network.symbol === Network.WAX && networkConfig.type === ConfigType.EOSIO) {
      debouncedCallWax({ amount, from: { contract: from.address, symbol: from.symbol },
        to: { contract: to.address, symbol: to.symbol } })
    }

    if (evmNetworkSymbols.find(symbol => symbol === network.symbol)) {
      debouncedCallEvm(tokenAmount, from, to, mainToken, networkConfig, dispatch,
        parseFloat(maxPriceImpact))
    }
  }

  return { handleNewInputAmount }
}
