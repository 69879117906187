import { singleChainSwapErrors } from '../../../store/warnings'
import { paraswapBaseUrl, paths } from './fetch-transactions'
import { PriceResponse, PriceSuccessResponse } from './paraswap-types'

/**
 * Returns a quote/estimate for the swap path and pricing
 *
 * @param srcToken the input token addess
 * @example '0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab'
 * @param srcDecimals the input token decimals
 * @example 6
 * @param destToken the output token address
 * @example '0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab'
 * @param destDecimals the output token decimals
 * @example 18
 * @param amount the input token amount as an unscaled string in wei
 * @example '30000000000000000000'
 * @param networkId the id number of the network as a string
 * @example '1' (Ethereum), '56' (BNB)
 * @param maxImpact the maximum price impact above which the response is flagged as a high-impact
 * trade. This is a percentage. If unset, the default from paraswap is 15 (percent).
 * We set the default value to 0 in order to receive the price impact data regardless of the user's
 * configuration.
 * @example 1.5
 * @returns a {@link PriceResponse} with estimated values for the swap
 */
export async function fetchPrice(srcToken: string, srcDecimals: string, destToken: string,
  destDecimals: string, amount: string, networkId: string, maxImpact: number)
  : Promise<PriceSuccessResponse> {
  /**
   * It's necessary to pass a whitelist of contract methods which allow referrer fees in order
   * for referrer fees to be allowed in the `transaction` call.
   *
   * There is also the method 'megaswap', however this has horrific exchange rates so we want to
   * disallow paraswap returning such routes.
   *
   * https://developers.paraswap.network/api/get-rate-for-a-token-pair
   */
  const methodsAllowingReferrerFees = 'simpleSwap,multiSwap,megaSwap'
  /**
   * Whether selling or buying. We use only the 'SELL' value.
   */
  const side = 'SELL'
  const endpoint = new URL(paths.prices, paraswapBaseUrl)
  const query = new URLSearchParams({
    srcToken,
    srcDecimals,
    destToken,
    destDecimals,
    amount,
    network: networkId,
    maxImpact: maxImpact.toString(),
    side,
    includeContractMethods: methodsAllowingReferrerFees,
  })
  endpoint.search = query.toString()

  const headers = {
    accept: 'application/json',
  }

  const response: Response = await fetch(endpoint, {
    method: 'GET',
    headers,
  })
  const data: PriceResponse = await response.json()

  // this only exists on a `PriceSuccessResponse`
  if ('priceRoute' in data) {
    if (amount !== data.priceRoute.srcAmount) {
      throw new Error(singleChainSwapErrors.AMOUNT_MISMATCH.code)
    }

    return data
  } else {
    throw new Error(`Paraswap: ${data.error}`)
  }
}
