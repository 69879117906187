import cl from 'classnames'
import React, { FC, PropsWithChildren } from 'react'

import CROSS from '../../assets/images/cross.svg'

import styles from './ListHeader.module.scss'

type Props = {
  /**
   * Additional class names for the component
   */
  className?: 'header--desktop-hidden'
  /**
   * Function to close the open list
   */
  closeList: () => void
}

/**
 * A standardised UI component for using as a list header
 */
export const ListHeader: FC<PropsWithChildren & Props> = props => (
  <div className={cl(styles['header'], styles[props.className ?? ''])}>
    <button onClick={() => props.closeList()}>
      <img src={CROSS} alt={`close ${props.children} list`}/>
    </button>
    <p>{props.children}</p>
  </div>
)
