import { TokenWithAmount } from '../hooks/useTokenInputs'
import { TokenAmount } from '../numbers/TokenAmount'
import { OwnedToken } from '../store/processRawTokenData'
import { ApiToken } from './eosio/pair-quote'

/**
 * Checks whether the input and output tokens are the same
 * @param from the token being submitted to the swap
 * @param to the token being recieved from the swap
 * @returns true if the two tokens are identical, otherwise false
 */
export function isSameToken(from: TokenWithAmount, to: TokenWithAmount | OwnedToken | ApiToken)
: boolean {
  return (from.address === to.address) && (from.symbol.toLowerCase() === to.symbol.toLowerCase())
}

/**
 * Checks that the user has enough of the main token to cover estimated fees
 *
 * On EVM chains, gas fees are paid from the user's balance of the chain main token. Check whether
 * the fees exceed the user's main token balance.
 * @param mainToken The {@link TokenAmount} user balance of the chain main token
 * @param swapToken The {@link TokenAmount} user input of any token to the swap
 * @param gasFee The {@link TokenAmount} of gas fees estimated to be needed for the swap
 * @param referrerFee The {@link TokenAmount} of referral fees to be taken for the swap
 * @returns The extra {@link TokenAmount} needed if the user has insufficient token to make the
 * swap, or 'undefined'
 */
export function extraBalanceNeeded(
  mainToken: OwnedToken, swapToken: TokenWithAmount, swapAmount: TokenAmount,
  gasFee: TokenAmount, referrerFee: TokenAmount)
: TokenAmount | undefined {
  if (gasFee.symbol.toLowerCase() !== mainToken.symbol.toLowerCase()) {
    throw new Error(`Fee token ${gasFee.symbol} is different from main token ${mainToken.symbol}`)
  }
  const mainTokenWithAmount: TokenWithAmount = { ...mainToken, balance: TokenAmount
    .fromStringWithDecimals(mainToken.balance, mainToken.decimals, mainToken.symbol) }

  let mainTokenNeeded: TokenAmount
  let inputTokenNeeded: TokenAmount
  // if the input token is the main token, add the gas to the input amount and subtract from the
  // balance to check if the balance is sufficient
  if (isSameToken(mainTokenWithAmount, swapToken)) {
    mainTokenNeeded = swapToken.balance.sub(swapAmount.add(gasFee).add(referrerFee))
    inputTokenNeeded = TokenAmount.ZERO(swapToken.decimals, swapToken.symbol)
  } else {
    // if the input token is not the main token, subtract the gas from the main token to check that
    // there is enough main token to cover gas fees
    mainTokenNeeded = mainTokenWithAmount.balance.sub(gasFee)
    inputTokenNeeded = swapToken.balance.sub(swapAmount).sub(referrerFee)
  }

  const zeroMainToken = TokenAmount.ZERO(mainToken.decimals, mainToken.symbol)
  const insufficientMTBalance = mainTokenNeeded.lt(zeroMainToken)
  if (insufficientMTBalance) {
    return mainTokenNeeded.mul(-1)
  }

  const zeroInputToken = TokenAmount.ZERO(swapToken.decimals, swapToken.symbol)
  const insufficientITBalance = inputTokenNeeded.lt(zeroInputToken)
  if (insufficientITBalance) {
    return inputTokenNeeded.mul(-1)
  }
}
