import React, { FC } from 'react'
import { Button } from '../elements/Buttons/Button'
import { useNavigate } from 'react-router-dom'

import styles from './Error.module.scss'

/**
 * The fallback component in case of errors
 */
export const ErrorView: FC = () => {
  const navigate = useNavigate()
  return (
    <div className={styles['error-view']}>
      <p>Hmmm, we can't find what you're looking for...</p>
      <Button onClick={() => navigate('/')} style='secondary'>Back to Swap Office</Button>
    </div>
  )
}
