import { useMemo } from 'react'
import { DisplayName, evmNetworkNames } from '../../../configs/networks'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { UserState } from '../../../store/userSlice'

/**
 * Return the correct address based on the network
 * @param user The user's data on all networks
 * @param network The network for which to return the user address
 */
function getAddress(user: UserState, network?: DisplayName): string {
  if (evmNetworkNames.some(evmNetwork => evmNetwork === network)) {
    return user.evm.address
  }
  if (network === 'EOS') {
    return user.eos.accountName
  }
  if (network === 'WAX') {
    return user.wax.accountName
  }
  return ''
}

/**
 * Returns the user's address for the network of the payout token
 *
 * This is the default address which is prefilled as the recipient address when the user first
 * selects tokens in the multichain swap.
 *
 // TODO - the address can only be prefilled if the user has already connected to the token network.
 // We attempt to automatically connect the EVM chain when the user navigates to the inputs page,
 // however we do not initialise the EOS chain until the user has selected it from the network
 // selector.
 // For the multichain swap, we should either attempt to initialise all chains at the start, or do
 // this when the user selects a payout token from an uninitialised chain. The first would be
 // simpler but requires more immediate fetching. This needs revisiting.
 */
export function useDefaultRecipientAddress(): string {
  const user = useAppSelector(state => state.user)
  const network = useAppSelector(state => state.multiChain.payoutToken?.chain)

  return useMemo(() => getAddress(user, network), [user, network])
}
