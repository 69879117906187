import React, { FC } from 'react'
import { SelectionButton } from '../../elements/Buttons/Button'
import { ListHeader } from '../../elements/ListHeader/ListHeader'
import { ModalBase } from '../../elements/ModalBase/ModalBase'

import WOMBAT from '../../assets/images/wallet-icons/wombat.svg'
import WAX_CLOUD_WALLET from '../../assets/images/wallet-icons/wax-cloud-wallet.svg'
import METAMASK from '../../assets/images/wallet-icons/metamask.svg'

import styles from '../../elements/ModalBase/ModalBase.module.scss'

type ConnectWalletProps = {
  /**
   * Function to close the wallet selector
   */
  closeSelector: () => void
  /**
   * Function to connect using Wombat Wallet
   */
  connectWombat: () => Promise<unknown>
  /**
   * Function to connect using Wax Cloud Wallet
   */
  connectWaxCloudWallet?: () => Promise<unknown>
  /**
   * Function to connect using Metamask
   */
  connectMetamask?: () => Promise<unknown>
}

/**
 * UI component for when the user has no wallet connected
 */
export const ConnectWallet: FC<ConnectWalletProps> = props => {
  // TODO - if (!provider) {
  //   redirect to download wombat wallet
  // }

  /**
   * Whether the swap is being viewed from within the wombat browser
   */
  const openInWombat = window.navigator.userAgent.toLowerCase().includes('wombat')

  /**
   * Handles connecting a user wallet
   * @param connect the function to connect a wallet
   */
  function handleConnect(connect?: () => Promise<unknown>) {
    if (connect){
      connect()
    }
    props.closeSelector()
  }

  return (
    <ModalBase closeModal={props.closeSelector} opaqueBlockingLayer={true}>
      <div className={styles['partial-modal']}>
        <ListHeader closeList={props.closeSelector}>
          Connect a wallet
        </ListHeader>
        <SelectionButton onClick={() => handleConnect(props.connectWombat)} icon={WOMBAT}>
          Connect Wombat Wallet
        </SelectionButton>
        {props.connectWaxCloudWallet && !openInWombat ?
          <SelectionButton onClick={() => handleConnect(props.connectWaxCloudWallet)}
            icon={WAX_CLOUD_WALLET}>
            Connect Wax Cloud Wallet
          </SelectionButton> : null}
        {props.connectMetamask && !openInWombat ?
          <SelectionButton onClick={() => handleConnect(props.connectMetamask)} icon={METAMASK}>
            Connect Metamask
          </SelectionButton> : null}
      </div>
    </ModalBase>
  )
}
