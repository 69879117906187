import { Network } from '../../../configs/networks'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { ListItem } from '../../../hooks/useTokensList'
import { TokenAmount } from '../../../numbers/TokenAmount'

const EosioNetworks = [Network.EOS, Network.WAX]
const EvmNetworks = [Network.AVAX, Network.BNB, Network.ETH, Network.POL]

type BalanceAndAllowance = {
  /**
   * The user's balance of a token as a {@link TokenAmount}
   */
  balance: TokenAmount
  /**
   * The token address, when the allowance is not set
   */
  tokenContract: string | undefined
  /**
   * The address of the user
   */
  userAddress: string
  /**
   * Whether the token is owned or not
   */
  owned: boolean
}

/**
 * A hook providing the balance and contract for an owned token
 * TODO - revisit this, there has to be a better way of doing this where the address does not have
 * to be returned.
 * @param network Which network the token is on
 * @param token The selected token
 */
export function useBalanceAndContract(network: Network, token?: ListItem)
: BalanceAndAllowance {
  const user = useAppSelector(state => state.user)

  let balance = TokenAmount.ZERO(18, '')
  let tokenContract: string | undefined = undefined
  let userAddress: string
  let isOwned = false

  // Is an EOSIO network
  if (EosioNetworks.includes(network)) {
    const eosio: 'eos' | 'wax' = network.toLowerCase() as 'eos' | 'wax'
    userAddress = user[eosio].accountName

    if (!token) {
      return { balance, tokenContract, userAddress, owned: isOwned }
    }

    const owned = user[eosio].ownedTokens
      .find(owned => {
        return (owned.contract === token.contract)
         && (owned.currency.toLowerCase() === token.symbol.toLowerCase())
      })

    if (owned) {
      isOwned = true
      balance = TokenAmount.fromStringWithDecimals(owned.amount, owned.decimals, owned.currency)
    } else {
      balance = TokenAmount.ZERO(token.decimals, token.symbol)
    }
  // Is an EVM network
  } else if (EvmNetworks.includes(network)) {
    userAddress = user.evm.address

    if (!token) {
      return { balance, tokenContract, userAddress, owned: isOwned }
    }

    /**
     * The selected token, if owned
     */
    const ownedToken = user.evm.ownedTokens.find(owned => owned.address === token.contract)

    // user doesn't own the token
    if (!ownedToken) {
      balance = TokenAmount.ZERO(token.decimals, token.symbol)
      return { balance, tokenContract, userAddress: user.evm.address, owned: isOwned }
    } else {
      isOwned = true
      tokenContract = ownedToken.address
      balance = TokenAmount.fromUnscaledString(ownedToken.balance, token.decimals, token.symbol)
    }

  } else {
    throw new Error('No network found')
  }

  return { balance, tokenContract, userAddress, owned: isOwned }
}
