import React, { FC } from 'react'
import { useAppSelector } from '../../hooks/useAppSelector'
import { useSwap } from '../../hooks/useSwap'
import { Button } from '../../elements/Buttons/Button'
import { TransactionDetails } from '../TxInformation/TransactionDetails'
import { TokenWithAmount } from '../../hooks/useTokenInputs'
import { InfoBox } from '../../elements/InfoBox/InfoBox'
import { ModalBase } from '../../elements/ModalBase/ModalBase'

import ARROW_DOWN from '../../assets/images/arrow-down.svg'
import TOKEN from '../../assets/images/token.svg'

import styles from './TransactionPreview.module.scss'

type Props = {
  /**
   * Data to display the selected input token
   */
  from: TokenWithAmount
  /**
   * Data to display the selected output token, if present
   */
  to?: TokenWithAmount
  /**
   * Function to close the transaction preview
   */
  onClose: () => void
}

/**
 * UI component to preview the details of a transaction before confirming or aborting
 */
export const TransactionPreview: FC<Props> = props => {
  const { network, fromAmount, toAmount } = useAppSelector(state => state.singleChain)
  const swap = useSwap().swap

  // this case should not happen since the modal should only be shown when these values are present
  if (!toAmount || !fromAmount || !props.to) {
    return null
  }

  return (
    // TODO - rework to make ModalBase the wrapper for this component
    // <ModalBase closeModal={props.onClose}>
    <>
      <div role='region' className={styles['modal']} aria-live='polite'>
        <p className={styles['title']}>Your transaction</p>
        <div className={styles['preview']}>
          <div className={styles['token']}>
            <img src={props.from.iconUrl} className={styles['icon']} alt=''
              onError={e => e.currentTarget.src = TOKEN}/>
            <p>{fromAmount} {props.from.symbol}</p>
          </div>
          <img src={ARROW_DOWN} alt='swaps to' className={styles['icon']}/>
          <div className={styles['token']}>
            <img src={props.to.iconUrl} className={styles['icon']} alt=''
              onError={e => e.currentTarget.src = TOKEN}/>
            <p>{toAmount} {props.to.symbol}</p>
          </div>
        </div>
        <TransactionDetails/>
        <InfoBox type='info'>
          <p>The transaction output is estimated. Your received {props.to.symbol} amount may differ
            from the estimation.</p>
        </InfoBox>
        <div className={styles['ctas']}>
          <Button onClick={props.onClose} style='secondary'>Back</Button>
          <Button onClick={() => swap(network.symbol)}>Confirm</Button>
        </div>
      </div>
      {/* TODO - this is a very hacky means to get the blocking layer - remove ! */}
      <ModalBase closeModal={() => props.onClose} opaqueBlockingLayer={true}/>
    </>
    // </ModalBase>
  )
}
