import { Network } from './networks'

/**
 * Data which is present for every token on all available networks
 */
type BaseTokenData = {
  /**
   * The currency symbol of the token
   * e.g. 'AVAX', 'WETH.e'
   */
  symbol: string
  /**
   * The full name of the token
   * {@example} 'Avalanche', 'Wrapped Ether'
   */
  name: string
  /**
   * A link to the token's icon
   */
  iconUrl: string
}

/**
 * Token data available for evm networks, taken from
 * https://api.1inch.io/v4.0/{networkId}/tokens
 *
 * @example for AVAX: https://api.1inch.io/v4.0/43114/tokens
 */
export type EvmTokenData = BaseTokenData & {
  /**
   * The token address
   * @example '0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664'
   */
  address: string
  /**
   * The number of decimals used for displaying the
   * token readably
   * @example 18, 6
   */
  decimals: number
}

/**
 * Type for an object containing the data of all the tokens
 * available for an EVM network
 *
 * @property [x: string] - The currency symbol of the token
 */
export type EvmTokensData = Record<string, EvmTokenData>

/**
 * Token data for Eosio networks
 */
export type EosioTokenData = BaseTokenData & {
  /**
   * The token account name
   * @example 'beastreserve'
   */
  accountName: string
  /**
   * The number of decimals used for displaying the
   * token readably.
   * @example '4'
   */
  precision: string
  /**
   * The pair index is used to check the liquidity for
   * swapping EOS and WAX tokens on the Alcor exchange.
   *
   * The liquidity for a specific token can be found using the
   * pairIndex on pairs table of the `alcorammswap` contract.
   * WAX:
   * https://wax.bloks.io/account/alcorammswap?loadContract=true&tab=Tables&account=alcorammswap&scope=alcorammswap&limit=100&table=pairs
   * EOS:
   * https://bloks.io/account/alcorammswap?loadContract=true&tab=Tables&account=alcorammswap&scope=alcorammswap&limit=100&table=pairs
   */
  pairIndex: string
}

/**
 * Type for an object containing the data of all the tokens
 * available for an Eosio network e.g. WAX, EOS
 *
 * @property [x: string] - The currency symbol of the token
 */
export type EosioTokensData = Record<string, EosioTokenData>

/**
 * Tokens created by Spielworks
 *
 * These tokens sometimes have to have special config in the app. This is not an exhaustive list
 * but rather the addresses which are needed for such config in the app.
 */
export const SpielworksTokens = {
  [Network.POL]: {
    wombat: '0x0c9c7712c83b3c70e7c5e11100d33d9401bdf9dd',
    wasder: '0xd82a0cf45b952d685fe0a078ac38a5b1fd0b57bc',
  },
  [Network.ETH]: {
    wombat: '0x0c9c7712c83b3c70e7c5e11100d33d9401bdf9dd',
    wasder: '0x0c572544a4ee47904d54aaa6a970af96b6f00e1b',
  },
}
