import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ChangellyToken, ChangellyQuote, ChangellyTransaction, PastTransaction,
} from '../services/changelly/types'
import { NetworkState } from '../../../store/userSlice'
import { AllowedChains, allowedChangellyChains } from '../config/allowedChains'
import { DisplayError, multiChainSwapError } from '../../../store/warnings'
import { AsyncThunkOptions } from '../../../store'
import { Allowance, SupportedToken } from '../../../store/processRawTokenData'
import { ConfigsState } from '../../../store/configsSlice'
import { InputType, ListItem } from '../../../hooks/useTokensList'
import { TokenAmount } from '../../../numbers/TokenAmount'
import { Network, networksConfig, NetworkSymbol } from '../../../configs/networks'
import { txLink } from '../../../views/TransactionStatus'
import { ScanName } from '../../../services/evm/gas-prices'
import { convertChangellyToken } from '../hooks/useInputLists'
import { SpielworksTokens } from '../../../configs/tokens'

/**
 * The fee which we set with Changelly as a percentage which we receive for our users using
 * their service
 * @example 0.3%
 */
const PARTNER_COMMISSION_PERCENT = 0.3

/**
 * Saves a changelly txId in local storage
 */
function storeTxIdLocalStorage(id: string): void {
  // Return existing array or create new
  const existingArrayJSON = localStorage.getItem('changellyTxIds')
  const txIds = existingArrayJSON ? JSON.parse(existingArrayJSON) : []
  txIds.push(id)
  localStorage.setItem('changellyTxIds', JSON.stringify(txIds))
}

/**
 * Processes the transaction status as returned from Changelly and sets in the format required for
 * display in the cross-chain swap
 */
function formatStatus(newTx: PastTransaction): PastTransaction {
  const amountTo = parseFloat(newTx.amountTo) !== 0 ? newTx.amountTo : newTx.amountExpectedTo
  return {
    ...newTx,
    apiExtraFee: `${parseFloat(newTx.apiExtraFee) / 100 * parseFloat(amountTo)}`,
    changellyFee: `${parseFloat(newTx.changellyFee) / 100 * parseFloat(amountTo)}`,
    currencyFrom: newTx.currencyFrom.toUpperCase(),
    currencyTo: newTx.currencyTo.toUpperCase(),
    amountExpectedTo: `${parseFloat(newTx.amountExpectedTo) - parseFloat(newTx.networkFee)}`,
  }
}

export type MultiChainToken = {
  /**
   * The full name of the token
   * @example 'Ethereum'
   */
  fullName: string
  /**
   * The token symbol
   * @example 'EOS'
   */
  symbol: string
  /**
   * The identifier of the token for the Changelly API
   * @example 'eos'
   */
  ticker: string
  /**
   * Whether the token is enabled for swapping on the Changelly service
   */
  enabled: boolean
  /**
   * Whether the token is enabled for swapping from on the Changelly service
   */
  enabledFrom: boolean
  /**
   * Whether the token is enabled for swapping to on the Changelly service
   */
  enabledTo: boolean
  /**
   * The token icon
   */
  image: string
  /**
   * The token contract address or account name
   * @example 'eosio.token' 'Ox.....17c'
   */
  contract: string
  /**
   * The token protocol
   * @example 'EOS' 'ERC20'
   */
  protocol: string
  /**
   * The token blockchain as returned by Changelly API
   * @example 'binance_smart_coin' 'ethereum'
   */
  blockchain: AllowedChains
  /**
   * The decimal precision of the token
   * @example 18
   */
  decimals: number
  /**
   * Whether an allowance has been set for wombat to enable swapping the token for the user
   */
  allowance: Allowance
  /**
   * The name of an additional parameter which can be required in transactions in order to identify
   * the receiver.
   * This can be necessary to include for specific tokens 1. to pay them in, e.g. EOS as a payin
   * token requires a 'memo' in order for the payout token to be sent to the correct receiver via
   * Changelly, or 2. to pay them out on certain platforms e.g. to receive EOS as a payout token to
   * a DEX e.g. Coinbase.
   */
  txIdName?: string
}

/**
 * Combines data from Changelly and from token config data within the swap to create a token object
 * with all the information needed to make a cross-chain swap using the Changelly service
 * @param changellyToken {@link SupportedChangellyToken} data returned from the Changelly API
 * @param wombatToken {@link SupportedToken} data stored within the swap dapp
 * @returns A token object which can be used for cross-chain swaps
 */
function createMultichainSwapToken(
  changellyToken: SupportedChangellyToken, wombatToken: SupportedToken,
): MultiChainToken {
  return {
    fullName: changellyToken.fullName,
    symbol: wombatToken.symbol,
    ticker: changellyToken.ticker,
    enabled: changellyToken.enabled,
    enabledFrom: changellyToken.enabledFrom,
    enabledTo: changellyToken.enabledTo,
    image: changellyToken.image,
    contract: wombatToken.address,
    protocol: changellyToken.protocol,
    blockchain: changellyToken.blockchain,
    decimals: wombatToken.decimals,
    allowance: wombatToken.allowance,
    txIdName: changellyToken.extraIdName,
  }
}

const DEFAULT_PAYIN: { address: string, chain: AllowedChains } = {
  address: networksConfig.ETH.mainTokenZeroBalance.address,
  chain: 'ethereum',
}

const DEFAULT_PAYOUT: { address: string, chain: AllowedChains } = {
  address: SpielworksTokens[Network.POL].wombat,
  chain: 'polygon',
}

/**
 * Find a token by its address and chain from a {@link MultiChainToken[]}
 * @param tokens A {@link MultiChainToken[]}
 * @param address The token address
 * @example '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'
 * @param chain The chain the token is on
 * @example 'polygon'
 * @returns The token as a {@link ListItem} or undefined
 */
function getInputToken(tokens: MultiChainToken[], address: string, chain: AllowedChains)
: ListItem | undefined {
  const token = tokens.find(token => (token.contract === address) && (token.blockchain === chain))
  return token ? convertChangellyToken(token) : undefined
}

/**
 * A token from the Changelly api for which the blockchain is supported on Wombat
 */
export type SupportedChangellyToken = Omit<ChangellyToken, 'blockchain'> & { blockchain: AllowedChains }

/**
 * A list of an address's previous Changelly transactions
 */
type TxHistory = (PastTransaction & { payinTxLink?: string })[]

/**
 * The minimum and maximum allowed inputs for a token pair
 */
type InputRange = {
  /**
   * The minimum allowed input token
   */
  min: string
  /**
   * The maximum allowed input token
   */
  max: string
}

/**
 * The state for making cross-chain swaps
 **/
export type MultiChainState = {
  /**
   * Whether there are values being loaded for the state
   */
  loading: boolean
  /**
   * All the available tokens
   */
  tokens: MultiChainToken[]
  /**
   * The amount of the token being swapped in as a scaled string with decimals
   * @example '20.5'
   */
  payinAmount: string
  /**
   * Data of the token being input to the swap
   */
  payinToken: ListItem | undefined
  /**
   * Data of the token being output from the swap
   */
  payoutToken: ListItem | undefined
  /**
   * The address which should recieve the output token
   */
  recipientAddress: string | undefined
  /**
   * Information about a payout Id which can be necessary to pay out on certain chains and exchanges
   */
  payoutId: {
    /**
     * Whether it is necessary to include a memo/tag/message in the payout transaction in order for
     * the tokens to be received.
     */
    required: boolean
    /**
     * The optional value of the payoutId, if supplied by the user's receiving wallet/DEX
     * e.g. '4154751372'
     */
    value?: string
  }
  /**
   * The quote returned for swapping a set of token inputs
   */
  quote: ChangellyQuote | undefined
  /**
   * The actual amount estimated to be received by the user after the subtraction of all fees
   */
  youGet: {
    /**
     * The token amount as a scaled decimal value
     */
    amount: string
    /**
     * The decimal precision of the output token
     */
    decimals: number
    /**
     * The symbol of the output token
     */
    symbol: string
  } | undefined
  /**
   * Data about a newly prepared transaction
   */
  transaction: ChangellyTransaction | undefined
  /**
   * Data about transactions sent from a given address
   */
  txHistory: TxHistory
  /**
   * The allowed input token amount range
   */
  inputRange: InputRange | undefined
  /**
   * Any warnings for the multichain swap
   */
  warnings: DisplayError[]
  /**
   * The user-selected network
   */
  network: NetworkSymbol
  /**
   * Whether the network selector is open
   */
  networkSelectorOpen: boolean
  /**
   * A list of changelly transaction ids
   */
  txIds: string[]
  /**
   * The amount of the transaction output token which Spielworks receives as a partner fee, as a
   * scaled number string
   * @example '0.0032343727' AVAX
   */
  partnerFee: string
} & NetworkState

const initialState: MultiChainState = {
  loading: false,
  initialized: 'NO',
  tokens: [],
  quote: undefined,
  youGet: undefined,
  transaction: undefined,
  payinAmount: '',
  payinToken: undefined,
  payoutToken: undefined,
  recipientAddress: undefined,
  payoutId: {
    required: false,
  },
  txHistory: [],
  inputRange: undefined,
  warnings: [],
  network: Network.ETH,
  networkSelectorOpen: false,
  txIds: [],
  partnerFee: '0',
}

const multiChainSlice = createSlice({
  name: 'multi-chain',
  initialState: initialState,
  reducers: {
    /**
     * Resets state which a result of user inputs (excludes state which is the result of automatic
     * loading)
     */
    reset(state) {
      state.loading = initialState.loading
      state.quote = initialState.quote
      state.transaction = initialState.transaction
      state.payinAmount = initialState.payinAmount
      const payin = getInputToken(state.tokens, DEFAULT_PAYIN.address, DEFAULT_PAYIN.chain)
      const payout = getInputToken(state.tokens, DEFAULT_PAYOUT.address, DEFAULT_PAYOUT.chain)
      state.payinToken = payin
      state.payoutToken = payout
      state.recipientAddress = initialState.recipientAddress
      state.payoutId = initialState.payoutId
      state.inputRange = initialState.inputRange
      state.warnings = initialState.warnings
      state.youGet = initialState.youGet
      state.networkSelectorOpen = initialState.networkSelectorOpen
    },
    /**
     * Sets the multi-chain application state to loading
     */
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    /**
     * Saves the maximum and minimum allowed inputs for swapping a token pair
     */
    setInputRange(state, action: PayloadAction<InputRange | undefined>) {
      state.inputRange = action.payload
    },
    /**
     * Sets the quote received for swapping a token pair
     */
    setQuote(state, action: PayloadAction<ChangellyQuote>) {
      state.quote = action.payload
      const decimals = state.payoutToken?.decimals
      const symbol = state.payoutToken?.symbol
      if (!decimals || !symbol) {
        throw new Error('Can\'t find payout token decimals')
      }
      // subtract the fees to get the final output amount
      const amountTo = TokenAmount.fromStringWithDecimals(action.payload.amountTo, decimals, symbol)
      const serviceFee = TokenAmount.fromStringWithDecimals(action.payload.fee, decimals, symbol)
      const networkFee = TokenAmount.fromStringWithDecimals(action.payload.networkFee, decimals,
        symbol)

      // n.b. the partner fee is already subtracted from the `amountTo`
      const receivedAmount = amountTo.sub(serviceFee).sub(networkFee)

      state.partnerFee = amountTo.mul(PARTNER_COMMISSION_PERCENT / 100)
        .display(true, decimals)

      state.youGet = {
        amount: receivedAmount.asStringWithDecimalQuantity(decimals), decimals, symbol,
      }
    },
    /**
     * Sets the transaction data created by the changelly API to execute and track a swap
     */
    setTransactionData(state, action: PayloadAction<ChangellyTransaction>) {
      state.transaction = action.payload
    },
    /**
     * Sets the amount of the payin token for the transaction
     */
    setAmount(state, action: PayloadAction<string>) {
      state.payinAmount = action.payload
    },
    /**
     * Sets a token for the transaction
     */
    setSelectToken(state, action: PayloadAction<{type: InputType, item: ListItem}>) {
      switch(action.payload.type) {
        case 'from': {
          const enabledFrom = state.tokens
            .find(token => token.ticker === action.payload.item.id)?.enabledFrom
          if (enabledFrom) {
            state.payinToken = action.payload.item
          } else {
            storeWarnings([multiChainSwapError.INVALID_INPUT_TOKEN])
          }
          break
        }
        case 'to': {
          const enabledTo = state.tokens
            .find(token => token.ticker === action.payload.item.id)?.enabledTo
          if (enabledTo) {
            state.payoutToken = action.payload.item
            if (action.payload.item.txIdName) {
              state.payoutId.required = true
            } else {
              state.payoutId.required = false
            }
          } else {
            storeWarnings([multiChainSwapError.INVALID_OUTPUT_TOKEN])
          }
          break
        }
        default: {
          console.error('No token provided')
          break
        }
      }
    },
    /**
     * Sets the recipient address for the token
     */
    setReceiverAddress(state, action: PayloadAction<string | undefined>) {
      state.recipientAddress = action.payload
    },
    /**
     * Sets the requirement for an id (aka. memo, tag, message) in the payout transaction
     */
    setPayoutIdRequired(state, action: PayloadAction<boolean>) {
      state.payoutId.required = action.payload
    },
    /**
     * Sets the requirement for an id (aka. memo, tag, message) in the payout transaction
     */
    setPayoutIdValue(state, action: PayloadAction<string>) {
      if (action.payload.length > 0) {
        state.payoutId.value = action.payload
      } else {
        state.payoutId.value = undefined
      }
    },
    /**
     * Sets the txIds state, ensuring that it contains only unique ids by removing
     * duplicates if provided in the payload, before updating the state
     */
    setTxId(state, action: PayloadAction<string>) {
      storeTxIdLocalStorage(action.payload)
      const uniqueIds = state.txIds.filter(address => address !== action.payload)
      state.txIds = [...uniqueIds, action.payload]
    },
    /**
     * Clears the past transactions state
     */
    clearTxHistory(state) {
      state.txHistory = initialState.txHistory
    },
    /**
     * Saves warnings to show the user
     */
    storeWarnings: (
      state, action: PayloadAction<DisplayError[]>,
    ) => {
      action.payload.forEach(warning => {
        // in case the warning is already being show, don't add it to the warnings
        const alreadyDisplayed = state.warnings.find(storedWarning => {
          return storedWarning.code === warning.code
        })
        if (!alreadyDisplayed) {
          state.warnings.push(warning)
        }
      })
    },
    /**
     * Clears warnings shown in the state
     *
     * The warnings passed to clear warnings are removed from the state. If no warnings are
     * specified, all warnings are cleared.
     */
    clearWarnings: (state, action: PayloadAction<DisplayError[]>) => {
      if (action.payload.length !== 0) {
        action.payload.forEach(warning => {
          const cleanedWarnings = state.warnings.filter(storedWarning => {
            return storedWarning.code !== warning.code
          })
          state.warnings = cleanedWarnings
        })
      } else {
        state.warnings = []
      }
    },
    setNetwork: (state, action: PayloadAction<NetworkSymbol>) => {
      state.network = action.payload
    },
    toggleNetworkSelector: (state) => {
      state.networkSelectorOpen = !state.networkSelectorOpen
    },
  },
  extraReducers: builder => {
    builder
      .addCase(setTokens.fulfilled, (state, action) => {
        state.tokens = action.payload
      })
      .addCase(setTokens.rejected, (state, action) => {
        console.error('Failed to load Changelly tokens', action.error)
      })
      .addCase(setTxHistory.fulfilled, (state, action) => {
        action.payload.forEach(newTx => {
          const formatted = formatStatus(newTx)
          const index = state.txHistory.findIndex(tx => tx.id === newTx.id)
          if (index !== -1) {
            // If the transaction is found, replace it with the new one
            state.txHistory[index] = formatted
          } else {
            // Else push the new transaction to the state
            state.txHistory.push(formatted)
          }
        })
      })
      .addCase(setTxHistory.rejected, (state, action) => {
        console.error('Failed to load changelly transacton history', action.error)
      })
  },
})

/**
 * Returns only the tokens which belong to networks supported by the swap application
 */
function isSupportedNetwork(token: ChangellyToken): token is SupportedChangellyToken {
  return allowedChangellyChains.includes(token.blockchain)
}

/**
 * Returns the list of supported tokens based on a Changelly token's blockchain property
 // TODO - can be removed by replacing the changelly blockchain property or adding a wombat
 // chain property with the config network name.
 * @param blockchain The token blockchain as listed by Changelly ({@link AllowedChains})
 * @param configs The configs for all supported networks
 * @returns The tokens from which the user is able to trade
 */
function getSupportedTokens(blockchain: AllowedChains, configs: ConfigsState): SupportedToken[] {
  switch(blockchain) {
    case 'avaxc': {
      return configs.AVAX.availableTokens
    }
    case 'binance_smart_chain': {
      return configs.BNB.availableTokens
    }
    case 'eos': {
      return configs.EOS.availableTokens
    }
    case 'ethereum': {
      return configs.ETH.availableTokens
    }
    case 'polygon': {
      return configs.POL.availableTokens
    }
    case 'WAX': {
      return configs.WAX.availableTokens
    }
    default: throw new Error('Token on unsupported chain')
  }
}

/**
 * The available network system token full names as returned by Changelly
 */
const changellySystemTokens = {
  eosio: ['EOS', 'WAX'],
  evm: ['Ethereum', 'Polygon', 'Binance Coin (BSC)', 'AVAX C-Chain'],
}

/**
 * Function to cross-reference and combine the data of tokens returned by the Changelly API with
 * the tokens supported for the token network within the swap
 * @param changellyTokens The changelly tokens on supported networks
 * @param supported The tokens supported within the token swap
 * @returns A token with its combined data from Changelly and the tokens we support
 */
function combineSupportedAndChangellyTokens(
  changellyTokens: SupportedChangellyToken[], supported: SupportedToken[],
): MultiChainToken[] {
  const combined = changellyTokens.map(token => {
    let found

    if (changellySystemTokens.evm.includes(token.fullName)) {
      const evmSystemTokenAddressAlias = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'
      found = supported.find(supported => {
        return (supported.address === evmSystemTokenAddressAlias)
        && (token.name.includes(supported.symbol))
      })
    } else if (changellySystemTokens.eosio.includes(token.fullName)) {
      found = supported.find(supported => (supported.address === 'eosio.token') && (supported.name === token.fullName))
    } else if (token.contractAddress) {
      found = supported.find(supported => supported.address === token.contractAddress)
    }

    if (found) {
      return createMultichainSwapToken(token, found)
    } else {
      // console.log(`Changelly token ${token.fullName} is not supported`)
    }
  })

  return combined.filter(token => typeof token !== 'undefined') as MultiChainToken[]
}

/**
 * Stores all the tokens fetched from Changelly API
 *
 * The data is cross-referenced and combined with data about tokens supported by the swap. A token
 * is stored if it is among the list of supported tokens
 */
const setTokens = createAsyncThunk<MultiChainToken[], ChangellyToken[], AsyncThunkOptions>(
  'multi-chain/setTokens',
  async (changellyTokens, thunkApi) => {
    const configs = thunkApi.getState().configs
    const changelly: SupportedChangellyToken[] = changellyTokens
      .filter(token => {
        return token.enabled &&
        // Token with ticker `matic` is misleading - not the Polygon system token but a copy on ETH
        token.ticker !== 'matic' &&
        // Filter for tokens which are on supported networks
        isSupportedNetwork(token)
      }) as SupportedChangellyToken[]

    const supported: SupportedToken[] = (allowedChangellyChains as AllowedChains[])
      .flatMap(chain => getSupportedTokens(chain, configs))

    // Filter the tokens on supported networks by tokens explicitly listed for that network
    return combineSupportedAndChangellyTokens(changelly, supported)
      .sort((a, b) => a.symbol.localeCompare(b.symbol))
  })

/**
 * Sets the default token input values for the cross-chain swap
 */
export const setInitialTokens = createAsyncThunk<void, ChangellyToken[], AsyncThunkOptions>(
  'multi-chain/setInitialTokens',
  async (changellyTokens, thunkApi) => {
    try {
      await thunkApi.dispatch(setTokens(changellyTokens))
      const tokens = thunkApi.getState().multiChain.tokens
      const payin = getInputToken(tokens, DEFAULT_PAYIN.address, DEFAULT_PAYIN.chain)
      const payout = getInputToken(tokens, DEFAULT_PAYOUT.address, DEFAULT_PAYOUT.chain)
      if (payin) {
        thunkApi.dispatch(multiChainSlice.actions.setSelectToken({ type: 'from', item: payin }))
      }
      if (payout) {
        thunkApi.dispatch(multiChainSlice.actions.setSelectToken({ type: 'to', item: payout }))
      }
    } catch (e) {
      console.error(e)
    }
  },
)

const scanIndex: Record<AllowedChains, ScanName> = {
  avaxc: 'snowtrace',
  binance_smart_chain: 'bscscan',
  eos: 'bloks',
  ethereum: 'etherscan',
  polygon: 'polygonscan',
  WAX: 'wax.bloks',
}

/**
 * Sets the past transactions where tokens have been sent from a given address
 */
export const setTxHistory = createAsyncThunk<TxHistory, PastTransaction[], AsyncThunkOptions>(
  'multi-chain/setTxHistory',
  (txs, thunkApi) => {
    const tokens = thunkApi.getState().multiChain.tokens
    return txs.map(tx => {
      const payinChain = tokens.find(token => token.ticker === tx.currencyFrom)?.blockchain

      let payinTxLink

      if (payinChain && tx.payinHash) {
        payinTxLink = txLink(scanIndex[payinChain], tx.payinHash)
      }

      return { ...tx, payinTxLink }
    })
  },
)

export const { setLoading, setAmount, setQuote, setTransactionData, setSelectToken, reset,
  setReceiverAddress, clearTxHistory, setInputRange, storeWarnings, clearWarnings, setTxId,
  setNetwork, toggleNetworkSelector, setPayoutIdRequired, setPayoutIdValue,
} = multiChainSlice.actions

export default multiChainSlice.reducer
