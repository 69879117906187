import cl from 'classnames'
import React, { FC } from 'react'

import styles from './Details.module.scss'

type Props = {
  /**
   * The name of the value
   */
  label: string
  /**
   * The actual value being displayed
   */
  value: string
}

/**
 * A UI component to display a single label and associated value about a transaction
 */
export const Detail: FC<Props> = props => (
  <>
    <p className={cl(styles['detail'], styles['label'])}>{props.label}</p>
    <p className={cl(styles['detail'], styles['value'])}>{props.value}</p>
  </>
)
