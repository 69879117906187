import { useSearchParams } from 'react-router-dom'
import { OwnedToken, SupportedToken } from '../store/processRawTokenData'
import { useAppSelector } from './useAppSelector'
import { useMemo } from 'react'
import { DisplayName } from '../configs/networks'

// TODO - refactor to use ItemData
export type ListItem = {
  /**
   * The token symbol
   */
  symbol: string
  /**
   * An unique identifier for the token
   */
  id: string
  /**
   * The full token name
   */
  name: string
  // TODO - The two below values don't need to be available for the UI, there has to be a better
  // mechanism for making this info available without passing it around everywhere
  /**
   * The token contract / address
   */
  contract: string
  /**
   * The url of the token's icon
   */
  iconUrl: string
  /**
   * The decimal precision of the token
   * @example 6
   */
  decimals: number
  /**
   * The balance as a scaled decimal, if the user owns any of the token
   */
  balance?: string
  /**
   * The network the item belongs to
   */
  chain?: DisplayName
  /**
   * The name of an identifier (e.g. memo) which may be required for a payout of this token
   */
  txIdName?: string
}

/**
 * Whether the input type is a token being swapped from or being
 * swapped into
 */
export type InputType = 'from' | 'to'

function formatListToken(token: (OwnedToken | SupportedToken)): ListItem {
  return {
    symbol: token.symbol,
    iconUrl: token.iconUrl,
    balance: 'balance' in token ? token.balance : undefined,
    // TODO - replace with token name
    id: token.address,
    contract: token.address,
    name: token.name,
    decimals: token.decimals,
  }
}

/**
 * The data for displaying a list of tokens to select
 * to swap from or into
 */
type TokensListData = {
  /**
   * The list of available token options
   */
  tokens: ListItem[]
  /**
   * A token which is already selected, if present.
   * This is the token symbol
   * @example ‘WOMBAT’
   */
  preselected?: string
}

/**
 * Returns a list of tokens which are available for the user to
 * select for the swap, for either the input or the output token
 */
export const useTokensList = (type: InputType): TokensListData => {
  const [searchParams] = useSearchParams()
  const display = useAppSelector(state => state.singleChain)
  const defaultFrom = useAppSelector(state => {
    return state.configs[display.network.symbol].mainTokenZeroBalance.symbol
  })
  let tokens: (OwnedToken | SupportedToken)[]
  let preselected: string | undefined

  /**
   * Check the URL for params of tokens to swap to and from.
   * If present, these should be preselected in the token list.
   */
  const params = useMemo(() => ({
    // if no `from` value is provided, fall back to the network main token
    from: searchParams.get('from') ?? defaultFrom,
    to: searchParams.get('to') ?? undefined,
  }), [type])

  switch (type) {
    case 'from':
      tokens = display.ownedTokens
        // TODO - this is a bit rough, revisit how to do it better
        .filter(token => parseFloat(token.balance) !== 0)
      preselected = params.from
      break
    default:
      tokens = display.availableTokens
      preselected = params.to
      break
  }

  /**
   * A list from which to select tokens.
   *
   * This can be either the list of owned, 'input' tokens which
   * can be swapped from, or the supported 'output' tokens which
   * are available to be swapped into, depending on which is
   * being viewed.
   */
  const tokensList: ListItem[] = useMemo(() => {
    return tokens.map(it => formatListToken(it))
  }, [tokens])

  return { tokens: tokensList, preselected }
}
