import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ConfigType, EosIoNetworkConfig, EvmNetworkConfig, Network, NetworkSymbol, networksConfig,
} from '../configs/networks'
import { OwnedToken, SupportedToken } from './processRawTokenData'
import { DisplayError, PRICE_IMPACT_WARNING_CODES, singleChainSwapErrors, warningGenerator } from './warnings'
import { TransactionDetail } from '../features/TxInformation/TransactionDetails'
import { AsyncThunkOptions } from './index'

/**
 * The state for data in the UI which is being viewed, i.e. the network that the user is currently
 * on, the input amount which they have typed, etc. All token data for the UI should be taken from
 * here (as opposed to the other token slices).
 */
export type SingleChainState = {
  // TODO - maybe have one state for all modals?
  /**
   * Whether the network selector is open
   */
  networkSelectorOpen: boolean
  /**
   * The network for which data should be displayed
   */
  network: {
    /**
     * The symbol of the network
     * @example 'POL', 'EOS'
     */
    symbol: NetworkSymbol
    /**
     * The network id
     */
    id: string
    /**
     * The network's icon
     */
    icon: string
    /**
     * The type of the network
     */
    type: ConfigType
  },
  /**
   * The main token on the network
   */
  mainToken: OwnedToken
  /**
   * The tokens available to swap from
   */
  ownedTokens: OwnedToken[]
  /**
   * The tokens available to input or output from a swap
   */
  availableTokens: SupportedToken[]
  /**
   * The user's amount input to the `from` Token as a string with decimals
   */
  fromAmount: string
  /**
   * The amount output for the `to` Token as a string with decimals
   */
  toAmount?: string
  /**
   * The slippage which is estimated for the transaction, if returned
   *
   * Some services (e.g. paraswap) only return the slippage if it is above the user's max allowed
   */
  estimatedSlippage?: string
  /**
   * Maximum acceptable slippage in percentage. If actual percentage goes higher the transaction
   * fails automatically
   *
   * This is a number string due to the pattern allowed in the input component (0-100 with one
   * decimal point). It is converted to a number in the needed format (e.g. base percentage points)
   * at the point that it is used in a transaction
   // TODO - make this a number
   * @example 10  = 10%
   */
  maxPriceImpact: string
  /**
   * Values associated with making the swap between the `from` and `to` tokens
   */
  swapDetails: TransactionDetail[]
  /**
   * Warning to display in the UI. They don't have to be errors, this is also necessary to show
   * information which the user should be aware of
   */
  warnings: DisplayError[]
  /**
   * Whether the display is loading some values
   */
  loading: boolean
}

/**
 * The default value for the max allowed price impact
 */
const DEFAULT_MAX_IMPACT = '1.5'

/**
 * The initial state for the display
 */
const initialState: SingleChainState = {
  networkSelectorOpen: false,
  // The initial state is always WAX. The application has an effect to update the values based on
  // the network from the route in NetworkView.
  network: {
    symbol: networksConfig.WAX.name,
    id: networksConfig.WAX.id,
    icon: networksConfig.WAX.icon,
    type: ConfigType.EOSIO,
  },
  mainToken: networksConfig.WAX.mainTokenZeroBalance,
  fromAmount: '',
  ownedTokens: [networksConfig.WAX.mainTokenZeroBalance],
  availableTokens: [],
  swapDetails: [],
  maxPriceImpact: DEFAULT_MAX_IMPACT,
  loading: false,
  warnings: [],
}

const displaySlice = createSlice({
  name: 'display',
  initialState: initialState,
  reducers: {
    /**
     * Open and close the network selection menu
     */
    toggleNetworkSelector(state) {
      state.networkSelectorOpen = !state.networkSelectorOpen
    },
    /**
     * Sets default values for a new selected network
     */
    setDefaultValues(state, action: PayloadAction<EosIoNetworkConfig | EvmNetworkConfig>) {
      // `action.payload.availableTokens` is read-only so a copy is made to sort it
      const sortedTokens = action.payload.availableTokens.slice()
        .sort((a, b) => a.symbol.localeCompare(b.symbol))
      // Set the display state using the default data for that network
      state.network = {
        symbol: action.payload.name,
        id: action.payload.id,
        icon: action.payload.icon,
        type: action.payload.type,
      }
      state.mainToken = action.payload.mainTokenZeroBalance
      state.ownedTokens = [action.payload.mainTokenZeroBalance]
      state.availableTokens = sortedTokens
      state.fromAmount = ''
      state.toAmount = undefined
      state.estimatedSlippage = undefined
      state.swapDetails = []
      state.warnings = []
      state.loading = false
    },
    /**
     * Updates the store with the tokens the user owns
     */
    storeOwnedTokens(state, action: PayloadAction<OwnedToken[]>) {
      // `action.payload` is read-only so a copy is made to sort them
      const sortedTokens = action.payload.slice().sort((a, b) => a.symbol.localeCompare(b.symbol))
      state.ownedTokens = sortedTokens
    },
    /**
     * Saves the network's main token with alongside its balance
     */
    storeMainToken(state, action: PayloadAction<OwnedToken[]>) {
      const mainTokenWithBalance = action.payload
        .find(token => {
          return (token.address === state.mainToken.address)
           && (token.symbol.toLowerCase() === state.mainToken.symbol.toLowerCase())
        })
      if (mainTokenWithBalance) {
        state.mainToken = mainTokenWithBalance
      }
    },
    /**
     * Saves the token amount being input to the swap as a string with decimals
     */
    storeInput(state, action: PayloadAction<string>) {
      state.fromAmount = action.payload
    },
    /**
     * Saves the token amount being output from the swap as a string with decimals
     */
    storeOutput(state, action: PayloadAction<string | undefined>) {
      state.toAmount = action.payload
    },
    /**
     * Saves a value for the estimated slippage for a swap
     */
    storeEstimatedSlippage(state, action: PayloadAction<string | undefined>) {
      state.estimatedSlippage = action.payload
    },
    /**
     * Saves information about values which are important for making a potential swap, e.g. fees
     */
    storeDetails(state, action: PayloadAction<TransactionDetail[] | undefined>) {
      state.swapDetails = action.payload ?? []
    },
    /**
     * Saves a warning to show the user
     */
    storeWarning(state, action: PayloadAction<DisplayError[]>) {
      action.payload.forEach(warning => {
        // in case the warning is already being show, don't add it to the warnings
        const alreadyDisplayed = state.warnings.find(storedWarning => {
          return storedWarning.code === warning.code
        })
        if (!alreadyDisplayed) {
          state.warnings.push(warning)
        }
      })
    },
    /**
     * Replace all current warnings with a new array
     */
    replaceWarnings(state, action: PayloadAction<DisplayError[]>) {
      state.warnings = action.payload
    },
    /**
     * Clears all the warnings shown in the state
     */
    clearWarnings(state) {
      state.warnings = []
    },
    /**
     * Set whether the display is in the process of loading
     */
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    /**
     * Resets the allowed max price impact value
     */
    resetMaxPriceImpact(state) {
      state.maxPriceImpact = DEFAULT_MAX_IMPACT
    },
  },
  extraReducers: builder => {
    builder
      .addCase(storeSlippageTolerance.fulfilled, (state, action) => {
        state.maxPriceImpact = action.payload
      })
      .addCase(storeSlippageTolerance.rejected, (state, action) => {
        console.error('Error storing slippage tolerance', action.error)
      })
  },
})

/**
 * Set the initial values to display for the network being viewed
 */
export const initialiseDisplayValues = createAsyncThunk<void, Network, AsyncThunkOptions>(
  'singleChain/reset',
  (network, thunkApi) => {
    const config = thunkApi.getState().configs[network]
    thunkApi.dispatch(displaySlice.actions.setDefaultValues(config))
  },
)

/**
 * Update the storing of slippage tolerance
 */
export const storeSlippageTolerance = createAsyncThunk<string, string, AsyncThunkOptions>(
  'singleChain/slippage',
  (inputSlippage, thunkApi) => {
    // Handle UI slippage warnings
    const warnings = thunkApi.getState().singleChain.warnings
    const updatedWarnings = [...warnings]
    const slippage = thunkApi.getState().singleChain.estimatedSlippage?.replace('%', '')
    // if estimated slippage is not returned (a.k.a. low)
    // or if the new set slippage allowance is higher than the returned slippage
    // remove the warning
    if (slippage === undefined || (parseFloat(slippage)) < parseFloat(inputSlippage)) {
      const slippageWarningIndex = warnings.findIndex(storedWarning => {
        return PRICE_IMPACT_WARNING_CODES.includes(storedWarning.code)
      })
      updatedWarnings.splice(slippageWarningIndex, 1)
      thunkApi.dispatch(displaySlice.actions.replaceWarnings(updatedWarnings))
    } else {
      // if an estimated slippage is present and the new slippage allowance is not higher than the
      // estimated slippage, reset the `NOT_ALLOWED_PRICE_IMPACT` error in the warnings array
      // the error needs to be explicitly reset in case a user has raised the slippage allowance
      // to above the estimated slippage, thereby removing the warning about slippage, but then
      // lowers the allowed slippage again. This requires the error to be re-added.
      thunkApi.dispatch(displaySlice.actions.storeWarning([
        { ...singleChainSwapErrors.NOT_ALLOWED_PRICE_IMPACT,
          message: warningGenerator.NOT_ALLOWED_PRICE_IMPACT.message(slippage),
        },
      ]))
    }
    let newSlippage = inputSlippage
    if (inputSlippage.length === 0) {
      newSlippage = '0'
    }

    return newSlippage
  },
)

export const {
  storeInput, storeOutput, setDefaultValues, storeOwnedTokens, storeDetails, toggleNetworkSelector,
  storeMainToken, storeWarning, clearWarnings, setLoading, storeEstimatedSlippage,
  resetMaxPriceImpact,
} = displaySlice.actions

export default displaySlice.reducer
