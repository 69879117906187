import { useEffect } from 'react'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { useDebouncedCallback } from '../../../hooks/useDebouncedCallback'
import { getExchangeAmount } from '../services/changelly/api'
import { clearWarnings, setLoading, setQuote, storeWarnings } from '../store/multiChainSlice'
import { multiChainSwapError, warningGenerator } from '../../../store/warnings'

/**
 * The number of milliseconds to delay debouncing the api calls
 */
const DEBOUNCE_DELAY_MS = 1000

/**
 * Fetches a quote from Changelly API for the output amount received from the given inputs
 */
export function useQuote() {
  const dispatch = useAppDispatch()
  const debounce = useDebouncedCallback(getQuote, DEBOUNCE_DELAY_MS)
  const {
    payinAmount, payinToken, payoutToken, inputRange,
  } = useAppSelector(state => state.multiChain)

  useEffect(() => {
    debounce()
  }, [payinAmount, payinToken, payoutToken, inputRange])

  function getQuote() {
    if (payinToken && payoutToken && payinAmount !== '' && inputRange) {
      dispatch(setLoading(true))
      dispatch(clearWarnings([]))
      getExchangeAmount({ from: payinToken.id, to: payoutToken.id, amountFrom: payinAmount })
        // TODO - don't just index result
        .then(res => {
          if ('result' in res) {
            dispatch(setQuote(res.result[0]))
          } else if ('error' in res) {
            console.error(res.error)
            if (res.error.code !== -32603) {
              dispatch(storeWarnings([{ ...multiChainSwapError.INVALID_AMOUNT,
                message: warningGenerator.INVALID_AMOUNT.message(res.error.message) }]))
            } else {
              dispatch(storeWarnings([multiChainSwapError.CHANGELLY_ERROR]))
            }
          } })
        .catch(e => console.error(e))
      dispatch(setLoading(false))
    }
  }
}
