.spinner {
  max-height: 24px;
  max-width: 24px;
  align-self: center;
  animation-name: spin;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  margin: auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
