import { ethers } from 'ethers'
import { TokenAmount } from '../../numbers/TokenAmount'
import { singleChainSwapErrors } from '../../store/warnings'

/**
 * The name of the service for monitoring the network
 */
export type ScanName = 'snowtrace' | 'bscscan' | 'etherscan' | 'polygonscan' | 'wax.bloks' | 'bloks'

/**
 * The service used to monitor the network
 * @example Polygon: 'polyscan'
 */
export type ScanService = {
  /**
   * The name of the network scan service
   */
  name: ScanName
  /**
   * The api key to access the scan
   */
  apiKey: string
}

/**
 * A base URL for gas price queries
 * @param scanName the name of the network scan service
 */
const baseUrl = (scanName: Omit<ScanName, 'snowtrace'>) => `https://api.${scanName}.com/`

/**
 * The response for a successful query for the gas price on an EVM network
 * !Important, this is not the response for AVAX, see {@link ProposedGasPriceResponse}
 */
export type GasPriceResponse = {
  /**
   * The number of the last block
   * @example '45217838'
   */
  LastBlock: string
  /**
   * A lower gas price with which the transaction should still be processed
   * @example '90.3'
   */
  SafeGasPrice: string
  /**
   * A recommended gas price as a scaled string value with decimals
   * @example '93.5'
   */
  ProposeGasPrice: string
  /**
   * The gas price recommended to process a transaction quickly
   * @example '117.6'
   */
  FastGasPrice: string
  /**
   * The cost of a transaction in USD
   * @example '0.63877'
   */
  UsdPrice: string
}

/**
 * The gas price for a transaction as a number string in wei
 * @example '25000000000'
 */
export type ProposedGasPriceResponse = Pick<GasPriceResponse, 'ProposeGasPrice'>

/**
 * Fetches the gas price in GWEI for a transaction using a specified scan service
 *
 * !Important, this can not be used for AVAX
 * @param scanService the scan service being used to monitor prices
 * @returns The gas price for a transaction as a number string with a decimal, in gwei
 * @example '113.5'
 */
async function fetchGasPrices(scanService: ScanService): Promise<GasPriceResponse> {
  const query = `api?module=gastracker&action=gasoracle&apikey=${scanService.apiKey}`
  const endpoint = new URL(query, baseUrl(scanService.name))
  const response = await fetch(endpoint)
  const data = await response.json()
  return data.result
}

/**
 * Fetches gas prices in wei
 *
 * @param scanService the scan service being used to monitor prices
 * @returns The gas price for a transaction as a number string in wei
 * @example '25000000000'
 */
async function fetchGasPricesWei(scanService: ScanService): Promise<string> {
  const gasPrices = await fetchGasPrices(scanService)
  const gweiGasPrice = gasPrices.ProposeGasPrice
  // TODO - this works but make sure that it is not bad practice
  const multiplierForGweiToWei = ethers.FixedNumber.from('1000000000')
  const gweiGasPriceFixedFormat = ethers.FixedNumber.from(gweiGasPrice)
  // `mulUnsafe` is the only available multiplication function to use for numbers with decimals
  return (multiplierForGweiToWei.mulUnsafe(gweiGasPriceFixedFormat)).toString()
}

/**
 * A numerical value as a string in hex format
 * @example '0x5d21dba00'
 */
type AvaxHexResponse = { result: string }

/**
 * Fetches the gas price for a transaction on AVAX
 * @param scanService the scan service being used to monitor prices
 * @return The gas price for a transaction as a number string in wei
 * @example '25000000000'
 */
async function fetchGasPricesAvax(scanService: ScanService): Promise<string> {
  const baseUrl = 'https://api.snowtrace.io/'
  const query = `api?module=proxy&action=eth_gasPrice&apiKey=${scanService.apiKey}`
  const endpoint = new URL(query, baseUrl)
  const response = await fetch(endpoint)
  const data: AvaxHexResponse = await response.json()
  return ethers.BigNumber.from(data.result).toString()
}

/**
 * Price in wei per unit gas
 */
function getWeiPerUnitGas(scanService: ScanService): Promise<string> {
  switch (scanService.name) {
    case 'snowtrace': return fetchGasPricesAvax(scanService)
    default: return fetchGasPricesWei(scanService)
  }
}

/**
 * Returns the gas price as a {@link TokenAmount} of the network main token
 * @param gasQuantity the quantity of gas needed for the transaction
 * @example '179000'
 * @param scanService the scan service for fetching the price per unity gas
 * @param token the main token of the network - this is what gas is paid in
 */
export async function getGasPrice(gasQuantity: string, scanService: ScanService, token: TokenAmount)
: Promise<TokenAmount> {
  try {
    /**
     * Recommended gas prices for the transaction
     */
    const weiPerUnitGas = await getWeiPerUnitGas(scanService)
    // const tokenAmountQuantity = TokenAmount.fromUnscaledString(gasQuantity, token.decimals,
    //   token.symbol)
    const tokenAmountCost = TokenAmount.fromUnscaledString(weiPerUnitGas, token.decimals,
      token.symbol)
    // TODO - this is not working if multiplied by `tokenAmountQuantity` - why?! we get zero, almost
    // as if it were dividing... however the gasQuantity number is not so large (c. 1*10^6), and it
    // is not input as a parameter to any part of the swap so purely for display purposes converting
    // it here to a number to multiply the token amount - this works as expected
    // for the moment `gasQuantity` is parsed as a float, since this number is not so large
    // (e.g. 100073) and the gas is not used for any calculations which may affect the swap
    return tokenAmountCost.mul(parseFloat(gasQuantity))
  } catch (e) {
    throw new Error(singleChainSwapErrors.GAS_PRICE.code)
  }
}
