import React, { FC } from 'react'

import SPINNER from '../../assets/images/spinner.svg'

import styles from './Spinner.module.scss'

/**
 * A loading spinner element
 */
export const Spinner: FC = () => (
  <img src={SPINNER} alt='loading...' className={styles['spinner']}/>
)
