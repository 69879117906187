import { FC } from 'react'
import { Info } from '../Info'
import { TokenWithAmount } from '../../hooks/useTokenInputs'
import { useAppSelector } from '../../hooks/useAppSelector'

type Props = {
  /**
   * The selected input token
   */
  selected: TokenWithAmount
}

/**
 * Displays information below the `from` token input field
 */
export const InputTokenDetails: FC<Props> = (props) => {
  const warnings = useAppSelector(state => state.singleChain.warnings)

  return (
    <>
      <Info>
        <p>Amount available: {props.selected.balance
          .display(false, props.selected.decimals)} {props.selected.symbol}</p>
      </Info>
      {warnings.map(warning => {
        return (warning.type === 'input' && (
          <Info key={warning.code} type='warning'>{warning.message}</Info>
        ))
      })}
    </>
  )
}
