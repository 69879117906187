import { ErrorResponse, PriceRoute, TransactionBody, TransactionResponse } from './paraswap-types'

/**
 * The base url for the swap API
 * docs: https://app.swaggerhub.com/apis/paraswapv5/api/1.0/
 */
export const paraswapBaseUrl = 'https://apiv5.paraswap.io'

/**
 * Paths available for the paraswap swap API
 */
export const paths = {
  /**
   * The path to fetch an estimate of swap prices
   */
  prices: '/prices?',
  /**
   * The path to fetch the values to pass to the wallet for a swap transaction
   */
  transactions: '/transactions',
}

/**
 * Returns the data object for the wallet to use in a swap transaction
 * docs: https://app.swaggerhub.com/apis/paraswapv5/api/1.0#/transactions/post_transactions__network_
 */
export async function transactionData(
  data: PriceRoute, userAddress: string, slippagePercent: number, partnerFeePercentage: number,
): Promise<TransactionResponse | ErrorResponse> {
  /**
   * The evm account for spielworks which receives referral fees
   */
  const partnerAddress = '0xa32cdc038d2d414aa62709cc81d8732769e92db7'

  const body: TransactionBody = {
    srcToken: data.srcToken,
    destToken: data.destToken,
    srcAmount: data.srcAmount,
    // either the `destAmount` parameter can be passed to give a precise expected output, or an
    // acceptable maximum slippage can be provided. If both are included in the body the call fails
    slippage: slippagePercent * 100,
    priceRoute: data,
    userAddress: userAddress,
    partnerAddress: partnerAddress,
    // the parameter name is incorrect in the swagger documentation,
    // see here: https://developers.paraswap.network/api/build-parameters-for-transaction
    partnerFeeBps: partnerFeePercentage * 100,
    srcDecimals: data.srcDecimals,
    destDecimals: data.destDecimals,
  }

  const endpoint = new URL(`${paths.transactions}/${data.network}`, paraswapBaseUrl)

  const headers = {
    'accept': 'application/json',
    'Content-Type': 'application/json',
  }

  const response = await fetch(endpoint, {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  })
  return response.json()
}
