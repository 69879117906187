import { singleChainSwapErrors } from '../store/warnings'

/**
 * Ids used to identify each network on https://www.coingecko.com/
 */
export type CoinGeckoId = 'matic-network' | 'binancecoin' | 'ethereum' | 'avalanche-2' | 'eos' | 'wax'

/**
 * The API response
 */
type CoinGeckoResponse = Record<CoinGeckoId, { 'usd': number }>

/**
 * Fetches the current rate of the network's main token (e.g. Ethererum -> ETH) to USD
 *
 * @param id the {@link CoinGeckoId} of the network
 * @example 'matic-network'
 * @returns the exchange rate as a number with decimals
 * @example 1719.32
 */
export async function fetchUsdToMainTokenRate(id: CoinGeckoId): Promise<number> {
  try {
    const endpoint = `https://api.coingecko.com/api/v3/simple/price?ids=${id}&vs_currencies=usd`
    const response = await fetch(endpoint)
    const data: CoinGeckoResponse = await response.json()
    return data[id].usd
  } catch (e) {
    throw new Error(singleChainSwapErrors.USD_FEE.code)
  }
}
