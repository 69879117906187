import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'

// Set the rounding config to be conservative - i.e., use the slightly lower number
// e.g. 0.0041666666... -> 0.00416666
BigNumber.config({ ROUNDING_MODE: BigNumber.ROUND_DOWN })

/**
 * Calculates the power of 10 to the input number of decimals
 * @param decimals A decimal precision
 * @returns A power of 10 raised to the input decimal value
 */
function getPower(decimals: number): BigNumber {
  return new BigNumber(10).pow(decimals)
}

/**
 * The max number of decimals to display
 *
 * Should be used wherever a {@link TokenAmount} is formatted for display in the UI
 */
export const DISPLAY_DECIMALS = 8

/**
 * Caps the decimals based on the token decimal value or the default display decimals if smaller
 * @param tokenDecimals the decimal value of the {@link TokenAmount}
 * @returns the maximum display decimals for the token
 */
function displayDecimals(tokenDecimals: number): number {
  if (tokenDecimals < DISPLAY_DECIMALS) {
    return tokenDecimals
  } else {
    return DISPLAY_DECIMALS
  }
}

/**
 * Represents a token amount. Token amounts are scaled, meaning they match what we commonly display
 * and what we mean with "1 Token".
 */
export class TokenAmount {
  private constructor(
    /**
     * The numerical value of a token amount
     */
    private readonly amount: BigNumber,
    /**
     * The number of decimals used to scale the token amount between wei and decimal quantities
     */
    public readonly decimals: number,
    /**
     * The token symbol. This is required for making transactions with the token
     */
    public readonly symbol: string,
  ) {}

  /**
   * Create a {@link TokenAmount} from a Decimal value.
   * @param value the Decimal value of the {@link TokenAmount}
   * @param decimals how many decimal places to show. Trailing zeroes are never displayed.
   * @param symbol the symbol of the token, this is set of characters that a token is displayed and
   * recognised by. e.g. 'POL', 'USDC'
   */
  static fromDecimal(value: BigNumber, decimals: number, symbol: string): TokenAmount {
    return new TokenAmount(value, decimals, symbol)
  }

  /**
   * Create a {@link TokenAmount} from a Javascript Number. Should only be used for safe values of
   * number (i.e. not too large)
   * @param value The number to convert to a {@link TokenAmount}
   * @param decimals How many decimal places to show. Trailing zeroes are never displayed.
   * @param symbolThe symbol of the token, this is set of characters that a token is
   * displayed and recognised by. e.g. 'POL', 'USDC'
   */
  static fromNumber(value: number, decimals: number, symbol: string): TokenAmount {
    return new TokenAmount(new BigNumber(value), decimals, symbol)
  }

  /**
   * Create a {@link TokenAmount} from an unscaled value. This is commonly needed on EVM chains.
   * @param value The unscaled amount of tokens. This means no decimal places. One token is a 1 with
   *  18 zeroes.
   * @param decimals How many decimal places to show. Trailing zeroes are never displayed
   * @param symbol The symbol of the token, this is set of characters that a token is
   * displayed and recognised by. e.g. 'POL', 'USDC'
   * @example
   * TokenAmount.fromUnscaledString('1000000000000000000')
   */
  static fromUnscaledString(value: string, precision: number, symbol: string): TokenAmount {
    return new TokenAmount(new BigNumber(value).div(getPower(precision)), precision, symbol)
  }

  /**
   * Create a {@link TokenAmount} from a scaled value. This is commonly needed on EOSIO chains.
   * @param value The scaled amount of tokens. It can have decimal places.
   * @param decimals How many decimal places to show. Trailing zeroes are never displayed.
   * @param symbol The symbol of the token, this is set of characters that a token is
   * displayed and recognised by. e.g. 'POL', 'USDC'
   * @example
   * TokenAmount.fromStringWithDecimals('1.00000000')
   */
  static fromStringWithDecimals(value: string, decimals: number, symbol: string): TokenAmount {
    return new TokenAmount(new BigNumber(value), decimals, symbol)
  }

  /**
   * Add another token amount. This is immutable.
   * @param other The amount to add
   * @returns The sum of this amount and the other
   */
  add(other: TokenAmount): TokenAmount {
    return new TokenAmount(this.amount.plus(other.amount), this.decimals, this.symbol)
  }

  /**
   * Checks if this amount is greater than another one
   * @returns true if this amount is (true) greater than {@link other}
   */
  gt(other: TokenAmount): boolean {
    return this.amount.gt(other.amount)
  }

  /**
   * Checks if this amount is greater or equal than another one
   * @returns true if this amount is greater or equal than {@link other}
   */
  gte(other: TokenAmount): boolean {
    return this.amount.gte(other.amount)
  }

  /**
   * Checks if this amount is lower than another one
   * @returns true if this amount is (true) lower than {@link other}
   */
  lt(other: TokenAmount): boolean {
    return this.amount.lt(other.amount)
  }

  /**
   * Checks if this amount is lower or equal than another one
   * @returns true if this amount is lower or equal than {@link other}
   */
  lte(other: TokenAmount): boolean {
    return this.amount.lte(other.amount)
  }

  /**
   * Checks if the token amount is equal to another one
   * @true if the amounts are equal
   */
  eq(other: TokenAmount): boolean {
    return this.amount.eq(other.amount)
  }

  /**
   * Subtract another {@link TokenAmount}. This is immutable.
   * @param other The amount to subtract
   * @returns "this" - "other"
   */
  sub(other: TokenAmount): TokenAmount {
    return new TokenAmount(this.amount.minus(other.amount), this.decimals, this.symbol)
  }

  /**
   * Multiplies a token amount by another number
   * @param other The number
   * @returns "this" * "other"
   */
  mul(other: number | TokenAmount): TokenAmount {
    if (typeof other === 'number') {
      return new TokenAmount(this.amount.times(new BigNumber(other)), this.decimals, this.symbol)
    } else {
      return new TokenAmount(this.amount.times(other.amount), this.decimals, this.symbol)
    }
  }

  /**
   * Multiplies a token amount by another decimal
   * @param other The decimal
   * @returns "this" * "other"
   */
  mulByDecimal(other: BigNumber): TokenAmount {
    return new TokenAmount(this.amount.times(other), this.decimals, this.symbol)
  }

  /**
   * Divides a token amount by another number
   * @param other The number
   * @returns "this" / "other"
   */
  div(other: number | TokenAmount): TokenAmount {
    if (typeof other === 'number') {
      return new TokenAmount(this.amount.div(new BigNumber(other)), this.decimals, this.symbol)
    } else {
      return new TokenAmount(this.amount.div(other.amount), this.decimals, this.symbol)
    }
  }

  /**
   * Get a display version of this amount.
   * @param thousandsSeparator If to use thousands separators
   * @param decimals How many decimal places to show. Trailing zeroes are never displayed.
   */
  display(thousandsSeparator: boolean, decimals: number): string {
    // Cap the maximum decimals
    const maxDecimals = displayDecimals(decimals)
    const stringValue = this.amount.toFixed(maxDecimals, BigNumber.ROUND_FLOOR)
    if (thousandsSeparator) {
      // Since ethers has a working implementation of this, let's just use it.
      return ethers.utils.commify(stringValue)
    } else {
      return stringValue
    }
  }

  /**
   * Renders this amount as a string with a decimal (e.g., a WAX quantity). This means a
   * decimal number with 8 digits and the
   * symbol included.
   * @param decimals How many decimal places to show. Trailing zeroes are never displayed.
   * @example
   * 1.00314561
   */
  asStringWithDecimalQuantity(decimals: number): string {
    return this.amount.toFixed(decimals)
  }

  /**
   * Renders this amount as an unscaled value.
   * @param decimals How many decimal places to show. Trailing zeroes are never displayed.
   * @example
   * 1003145610000000000
   */
  asUnscaledQuantity(decimals: number): string {
    return this.amount.times(getPower(decimals)).toFixed(0)
  }

  /**
   * Converts the decimal {@link TokenAmount} of one token into the equivalent value in another
   * @param token The token to be converted to
   */
  convertToOtherToken(token: TokenAmount): TokenAmount {
    return new TokenAmount(this.amount, token.decimals, token.symbol)
  }

  /**
   * A {@link TokenAmount} of zero
   * @param decimals How many decimal places to show. Trailing zeroes are never displayed.
   * @param symbol The symbol of the token, this is set of characters that a token is
   * displayed and recognised by. e.g. 'POL', 'USDC'
   */
  static ZERO(decimals: number, symbol: string) {
    return TokenAmount.fromNumber(0, decimals, symbol)
  }

  /**
   * A {@link TokenAmount} of one
   * @param decimals How many decimal places to show. Trailing zeroes are never displayed.
   * @param symbol The symbol of the token, this is set of characters that a token is
   * displayed and recognised by. e.g. 'POL', 'USDC'
   */
  static ONE(decimals: number, symbol: string) {
    return TokenAmount.fromNumber(1, decimals, symbol)
  }
}
