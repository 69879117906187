import * as Sentry from '@sentry/react'

/**
 * Has Sentry already been initialised
 */
let initialised = false
/**
 * Whether an environment variable exists for the sentry project
 *
 * Local development environment does not use Sentry, therefore Sentry should only be initialised in
 * environments where it should be running. These will habe an env. variable available.
 */
const environmentHasSentry = process.env.SENTRY_PROJECT

/**
 * Initialisation for sentry error reporting
 */
export function initialiseSentry() {
  if (!initialised && environmentHasSentry) {
    Sentry.init({
      dsn: 'https://adf1551bbf92dd70745d904ac10158d7@o4506343849066496.ingest.sentry.io/4506394689798144',
      integrations: [
        new Sentry.BrowserTracing(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions
    })

    initialised = true
  }
}
