@import '../../assets/styles/_typography.scss';
@import '../../assets/styles/_variables';

.details {
  width: 100%;
  @include text-smallest();
  color: var(--colorTextSecondary);
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.numeric-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 32px 0 8px 0;
  border-top: 1px solid var(--colorDividerPrimary);
}

.row {
  display: flex;
  justify-content: space-between;

  &--highlight {
    color: var(--colorError);
  }
}

.label {
  display: flex;
  text-align: left;
}

.input {
  text-align: right;

  &:focus {
  background: var(--colorSurfacePale2);
  padding: 0 16px;
  border-radius: 8px;
  width: 1.4rem;
  text-align: center;
  margin-right: 4px;
 }
}

.editable-input {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.tooltip {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 4px;
}

.tooltip .tooltip-text {
  visibility: hidden;
  width: 220px;
  background-color: var(--colorSurfaceDark);
  @include text-smallest(left);
  line-height: normal;
  color: var(--colorTextSecondary);
  padding: 3px 6px;
  border-radius: 6px;
  border: 0.1px solid white;
 
  position: absolute;
  left: 22px;
  top: 8px;
  z-index: 1;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}

.icon {
  height: 16px;
  width: 16px;
}

.value {
  text-align: right;
}

.editable-value {
  display: flex;
}
