import cl from 'classnames'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { formatDate } from 'date-fns'
import { multiChainTransactionStatusRoute } from '../../../hooks/useSwap'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { PastTransaction } from '../services/changelly/types'
import { getContent } from './TransactionStatus'
import { getTransactionHistoryById, getTransactionHistoryByRecipient } from '../services/changelly/api'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { setTxHistory } from '../store/multiChainSlice'
import { useBalanceAndContract } from '../hooks/useBalanceAndAllowance'
import { Spinner } from '../../../elements/Spinner/Spinner'
import { InfoBox } from '../../../elements/InfoBox/InfoBox'
import { Submit } from '../../../elements/Buttons/Button'
import { features } from '../../../feature-flags/feature-flags'

import DEFAULT_TOKEN from '../../../assets/images/token.svg'
import TRANSFER_ARROW from '../../../assets/images/arrow-forward.svg'
import NAVIGATE from '../../../assets/images/chevron-right.svg'
import BULLET from '../../../assets/images/large-dot'

import styles from '../../../views/Views.module.scss'

/**
 * A UI component with basic information about a single past transaction
 */
const Transaction: FC<PastTransaction> = props => {
  const navigate = useNavigate()
  const tokens = useAppSelector(state => state.multiChain.tokens)
  const status = useMemo(() => getContent(props.status), [props.status])
  const inputToken = useMemo(() => tokens.find(token => props.currencyFrom === token.ticker),
    [props.currencyFrom, tokens])
  const outputToken = useMemo(() => tokens.find(token => props.currencyTo === token.ticker),
    [props.currencyTo, tokens])

  return (
    <button className={styles['tx-history__item']}
      onClick={() => navigate(multiChainTransactionStatusRoute({ txId: props.id, history: true }))}>
      <div className={styles['tx-history__item-content']}>
        <p className={styles['tx-history__item-date']}>
          {formatDate(Math.floor(props.createdAt / 1000), 'yyyy/MM/dd')}
        </p>
        <div className={styles['tx-history__item-amount']}>
          <img src={inputToken?.image ?? DEFAULT_TOKEN} className={styles['tx-history__item-icon']}/>
          <p><b>{props.amountFrom}</b></p>
          <img src={TRANSFER_ARROW} className={styles['tx-history__item-icon']}/>
          <img src={outputToken?.image ?? DEFAULT_TOKEN} className={styles['tx-history__item-icon']}/>
          <p><b>{props.amountTo}</b></p>
        </div>
        <p className={cl(styles['tx-history__item-status'], styles[status.highlight])}>
          <BULLET colourHex={status.hex} />
          <b>{status.header}</b></p>
      </div>
      <img src={NAVIGATE} className={styles['tx-history__item-icon--large']}/>
    </button>
  )
}

/**
 * Retrieves changelly transaction ids from localstorage
 */
function getTxIds(): string[] {
  const changellyTxIds = localStorage.getItem('changellyTxIds')
  return changellyTxIds ? JSON.parse(changellyTxIds) : []
}

/**
 * A view showing the statuses of all past transactions for an address
 */
export const TransactionHistory: FC = () => {
  const dispatch = useAppDispatch()
  const { txHistory, network } = useAppSelector(state => state.multiChain)
  const { userAddress } = useBalanceAndContract(network)
  const [inputAddress, setInputAddress] = useState<string>('')
  const [overridingAddress, setOverridingAddress] = useState<string>()
  const [loading, setLoading] = useState(true)
  const txIds = getTxIds()

  // Transactions sorted newest to oldest
  const txs = useMemo(() => {
    // the refund address is the user's address
    const filtered = txHistory.filter(it => {
      if (overridingAddress) {
        return it.payoutAddress === overridingAddress
      } else {
        return it.payoutAddress === userAddress
      }
    })
    return filtered.sort((a, b) => b.createdAt - a.createdAt)
  }, [txHistory, userAddress, overridingAddress])

  /**
   * Fetches the transaction history based on the address provided
   * @param address The recipient user address
   */
  async function fetchHistory(address: string): Promise<void> {
    try {
      const response = await getTransactionHistoryByRecipient(address)
      if ('result' in response) {
        dispatch(setTxHistory(response.result))
      } else if ('error' in response) {
        throw new Error(response.error.message)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  // TODO - temporary solution to fetch history by txIds from localstorage
  async function fetchHistoryByIds(): Promise<void> {
    const idBatches = []
    // changelly allows a max of 10 txIds per batch
    for (let i = 0; i < txIds.length; i += 10) {
      idBatches.push(txIds.slice(i, i + 10))
    }

    try {
      const results = await Promise.all(idBatches.map(batch => getTransactionHistoryById(batch)))
      results.forEach(res => {
        if ('result' in res) {
          dispatch(setTxHistory(res.result))
        } else if ('error' in res) {
          throw new Error(res.error.message)
        }
      })
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  // Set history based on the connected user address
  useEffect(() => {
    const initialiseHistory = async () => {
      setOverridingAddress(undefined)
      // If no user address is available, stop loading after 3000ms
      if (!userAddress) {
        setTimeout(() => setLoading(false), 3000)
        return
      }

      if (features.isChangellyHistoryByRecipientEnabled()) {
        fetchHistory(userAddress)
      } else {
        fetchHistoryByIds()
      }
    }

    initialiseHistory()
  }, [userAddress])

  /**
   * Handles the submission of a custom address for which to fetch the transaction history
   */
  function handleCustomAddress(e: React.FormEvent<HTMLFormElement>): void {
    e.preventDefault()
    if (inputAddress.trim() !== '') {
      setLoading(true)
      setOverridingAddress(inputAddress)
      fetchHistory(inputAddress)
    }
  }

  /**
   * Handles changes to the custom address input value
   * @param val The new value
   */
  function handleInputAddress(val: string) {
    if (val === '') {
      setOverridingAddress(undefined)
    }
    setInputAddress(val)
  }

  return (
    <div className={styles['content']}>
      <h2>Previous Transactions</h2>
      {!features.isChangellyHistoryByRecipientEnabled() && <InfoBox type='info'>
        You can track the statuses of all transactions made in the current browser.
      </InfoBox>}
      {features.isChangellyHistoryByRecipientEnabled() && (
        <div className={cl(styles['container'], styles['custom-address'])}>
          <p className={styles['label']}>View transactions for a different recipient address</p>
          <form onSubmit={handleCustomAddress} className={styles['form']}>
            <input type='text' placeholder='Other recipient' value={inputAddress}
              onChange={e => handleInputAddress(e.target.value)} className={styles['input']}/>
            <Submit disabled={inputAddress.length === 0} style='secondary--small'>
              <p className={styles['cta-text']}>View</p>
            </Submit>
          </form>
        </div>
      )}
      <div className={cl(styles['container'], styles['tx-history__list'])}>
        {(txs.length === 0 && !loading) ? (
          <div className={styles['tx-history__item']}>
            <p>You haven't made any cross-chain transactions yet.</p>
          </div>
        ) : (
          <>
            {loading && <div className={styles['tx-history__item']}>
              <Spinner/>
            </div>}
            {txs.map(tx => <Transaction key={tx.id} {...tx} />)}
          </>
        )}
      </div>
    </div>
  )
}
