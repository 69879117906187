import React, { FC, PropsWithChildren, useEffect } from 'react'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { useAppSelector } from '../../hooks/useAppSelector'
import { clearRefresh, getBalances } from '../../store/eosSlice'
import { initialiseDisplayValues, storeMainToken, storeOwnedTokens } from '../../store/singleChainSlice'
import { formatEosIoOwnedTokens } from '../../store/processRawTokenData'
import { Network } from '../../configs/networks'

/**
 * Component that ensures EOS state is initialized. This should be wrapped around all components
 * that use the EOS state.
 */
export const EosInitialiser: FC<PropsWithChildren> = props => {
  const dispatch = useAppDispatch()
  const eos = useAppSelector(state => state.user.eos)
  const eosConfig = useAppSelector(state => state.configs.EOS)

  /**
   * Fetches balances of tokens owned on the EOS network
   */
  function loadUserData() {
    dispatch(getBalances()).unwrap()
      .then(balances => {
        const formatted = formatEosIoOwnedTokens(
          balances, eosConfig.availableTokens, eosConfig.mainTokenZeroBalance,
        )
        // Stores tokens in the display state
        dispatch(storeOwnedTokens(formatted))
        dispatch(storeMainToken(formatted))
      })
  }

  // Effect set up the initial state for EOS:
  // 1. initialise EOS display values
  // 2. attempt to connect Wombat wallet automatically - DISABLED
  useEffect(() => {
    dispatch(initialiseDisplayValues(Network.EOS))
    //   if (eos.initialized === 'NO') {
    //     dispatch(connectWombat())
    //   }
  }, [])

  // Effect to load user data on the EOS network once the network is initialised
  useEffect(() => {
    if (eos.initialized === 'YES') {
      loadUserData()
    }
  }, [eos.initialized, eosConfig.availableTokens])

  // Effect to create a delayed refresh when the store property for that is set
  useEffect(() => {
    // Reload EOS user data if the reinitializeIn property is set to a value > 0.
    if (eos.reinitializeIn > 0) {
      console.log('Reloading EOS data in %d milliseconds', eos.reinitializeIn)
      // Immediately clear the refresh in the state, so it doesn't get triggered again. Since this
      // changes eos.reinitializeIn we can _not_ clear the timeout when the effect exits as a change
      // in dependencies will trigger the cleanup code and the timeout would always be cancelled.
      dispatch(clearRefresh())
      setTimeout(() => {
        loadUserData()
        console.log('EOS data successfully reloaded')
      }, eos.reinitializeIn)
    }
  }, [eos.reinitializeIn])

  return <>{props.children}</>
}
