@import '../../../../assets/styles/_variables';

.column {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.row {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.label {
  display: flex;
}

.input {
  padding: 0px 8px;
  border-radius: 8px;
  background-color: var(--colorSurfaceDisabled);
}
