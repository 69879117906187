import cl from 'classnames'
import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { reset } from '../../features/multichain-swap/store/multiChainSlice'

import BACK_ARROW from '../../assets/images/arrow-back.svg'

import styles from './Navigation.module.scss'

/**
 * The paths of the swap landing pages
 */
const landingPages = ['/', '/cross-chain']

/**
 * Navigational component to go between different parts of the swap
 */
export const Navigation: FC<{ pathname: string }> = props => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  if (landingPages.includes(props.pathname)) {
    const isClassic = props.pathname === '/'

    return (
      <div className={styles['header__title']}>
        <h1>Swap Office</h1>
        <div className={styles['nav']}>
          <button onClick={() => {
            if (!isClassic) {
              navigate('/')
            }
          }}
          className={cl(styles['nav__button'], isClassic ? styles['nav__button--selected'] : '')}>
            Classic
          </button>
          <button onClick={() => {
            if (isClassic) {
              navigate('/cross-chain')
            }
          }}
          className={cl(styles['nav__button'], isClassic ? '' : styles['nav__button--selected'])}>
            Cross-Chain
          </button>
        </div>
      </div>
    )
  }

  function goBack() {
    navigate(-1)
    // TODO - improve the back nav function - maybe can be passed by each component
    dispatch(reset())
  }

  return (
    <button className={styles['nav__back']} onClick={goBack}>
      <img src={BACK_ARROW} alt='back arrow'/>
      <p>Back</p>
    </button>
  )
}
