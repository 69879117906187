import { useEffect, useMemo } from 'react'
import { useAppSelector } from './useAppSelector'
import { useAppDispatch } from './useAppDispatch'
import { provider, setProvider } from '../services/evm/provider'
import { TxState, confirmTransaction } from '../store/transactionsSlice'
import { Network } from '../configs/networks'
import { loadTokenBalances } from '../store/evmNetworksSlice'
import { storeOwnedTokens } from '../store/singleChainSlice'
import { formatEvmOwnedTokens } from '../store/processRawTokenData'

/**
 * Hook to monitor pending transactions, i.e. wait for their confirmation on chain.
 *
 * Once a transaction is confirmed the state is updated and certain data reloading actions,
 * depending on the transaction type are initiated.
 *
 * This hook is meant to be called only once.
 */
export const monitorPendingTransactions = () => {
  const transactions = useAppSelector(state => state.transactions)
  const config = useAppSelector(state => state.configs)
  const pendingTransactions = useMemo(
    () => transactions.filter(tx => tx.state === TxState.PENDING),
    [transactions],
  )
  const dispatch = useAppDispatch()

  function reloadDataAfterConfirmation(network: Network) {
    // Reload certain data after a transaction has been confirmed
    dispatch(loadTokenBalances(network)).unwrap()
      .then(balances => {
        dispatch(storeOwnedTokens(formatEvmOwnedTokens(
          balances, config[network].availableTokens, config[network].mainTokenZeroBalance,
        )))
      })
  }

  useEffect(() => {
    pendingTransactions
      .forEach(tx => {

        if (!provider) {
          setProvider()
          return
        }
        provider.waitForTransaction(tx.hash)
          .then(receipt => {
            console.debug('Got receipt of transaction %s of type %s in block %d with %d confirmations',
              tx.hash, tx.network, receipt.blockNumber, receipt.confirmations)
            dispatch(confirmTransaction({
              ...tx,
              blockNumber: receipt.blockNumber,
              confirmations: receipt.confirmations,
            }))
            reloadDataAfterConfirmation(tx.network)
          })
          .catch(e => {
            console.error('Error confirming transaction', e)
          })
      })
  }, [pendingTransactions, provider])
}
