import { ethers } from 'ethers'
import IERC20Abi from '../abis/IERC20.json'
import { provider } from '../services/evm/provider'
import { EosIoNetworkSymbol, Network, evmNetworks } from '../configs/networks'
import { ParaswapTokenTransferProxyContract } from '../store/evmNetworksSlice'
import { SpielworksTokens } from '../configs/tokens'

/**
 * The format in which EVM token balances are initally fetched from the api
 *
 * @example
 * [0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d: {
 *    balance: '9770644072910153654', allowance: '9770644072910153654'
 * }]
 */
export type UnprocessedEvmTokenBalance = {
  /**
   * The total balance of the user for this token as an unscaled value
   * @example '9770644072910153654'
   */
  balance: string
  /**
   * The allowance of the user for this token as an unscaled value
   * @example '0'
   */
  allowance: string
}

export type EvmTokenBalance = UnprocessedEvmTokenBalance & {
  /**
   * The token address
   * @example 0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d
   */
  address: string
}

/**
 * 'string' - the token address
 * @example 0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d
 */
type UnprocessedEvmTokenBalances = Record<string, UnprocessedEvmTokenBalance>

/**
 * Converts the token balances into an array in order to be consistent with the array data
 * structure of balances on WAX and EOS
 *
 * @param balances the token balance as they are fetched from the api
 * @return token balances where the balance is not '0', in a standardised format
 */
function formatEvmTokenBalances(balances: UnprocessedEvmTokenBalances): EvmTokenBalance[] {
  // When a user does not own a token, the balance is returned as '0'
  const entriesWithBalance = Object.entries(balances).filter(([, val]) => {
    return val.balance !== '0'
  })
  return entriesWithBalance.map(([key, val]) => ({ ...val, address: key }))
}

/**
 * Queries the token ABI for the user's balance and allowance
 * @param tokenAddress The token contract address
 * @example '0xd82a0cf45b952d685fe0a078ac38a5b1fd0b57bc'
 * @param userAddress The user's EVM address
 * @example '0xf4bc55ad49573af16dd80bd3dd00e72d611b017c'
 */
export async function getEvmBalanceFromContract(tokenAddress: string, userAddress: string)
: Promise<EvmTokenBalance> {
  const contract = new ethers.Contract(tokenAddress, IERC20Abi, provider)
  const allowance = await contract.functions.allowance(userAddress,
    ParaswapTokenTransferProxyContract)
  const balance = await contract.functions.balanceOf(userAddress)
  return { address: tokenAddress, balance: BigInt(balance).toString(),
    allowance: BigInt(allowance).toString() }
}

/**
 * Returns the data of all tokens provided by 1Inch on a network. NB, this does not necessarily
 * include all the tokens for which we require the balances.
 * https://portal.1inch.dev/documentation/balance/swagger
 *
 * @param networkId The id number of the network
 * @example '1' (ethereum), '137' (polygon)
 * @param address The account address of the user
 */
function balances1Inch(networkId: string, address: string): string {
  return `https://balances.1inch.io/v1.2/${networkId}/allowancesAndBalances/${ParaswapTokenTransferProxyContract}/${address}?tokensFetchType=listedTokens`
}

/**
 * Tokens for which is it essential to fetch balances
 *
 * These have been added since they are not being returned by the default balance API (1inch),
 * however it is essential to have their balances in the app
 */
const essentialBalances = {
  [evmNetworks[Network.AVAX].id]: [],
  [evmNetworks[Network.BNB].id]: [],
  [evmNetworks[Network.ETH].id]: [
    SpielworksTokens[Network.ETH].wombat, SpielworksTokens[Network.ETH].wasder,
  ],
  [evmNetworks[Network.POL].id]: [
    SpielworksTokens[Network.POL].wombat, SpielworksTokens[Network.POL].wasder,
  ],
}

/**
 * Function to fetch essential token balances from the token contracts
 * @param networkId The network id number
 * @example '1' (ethereum), '137' (polygon)
 * @param address The account address of the user
 */
async function getEssentialBalances(networkId: string, address: string)
: Promise<EvmTokenBalance[]> {
  const contractAddresses = essentialBalances[networkId]
  const balancePromises = contractAddresses
    .map(contract => getEvmBalanceFromContract(contract, address)
      .catch(error => {
        console.error(`Error fetching balance for contract ${address}:`, error)
        return null
      }))

  try {
    const results = await Promise.all(balancePromises)
    // Filter out null results (failed fetches)
    return results.filter(balance => balance !== null) as EvmTokenBalance[]
  } catch (e) {
    console.error('Error fetching balances:', e)
    throw e
  }
}

/**
 * Returns the balance and allowance data for all tokens the user owns on an EVM network
 *
 * @param account The evm account of the user
 * @param networkId The id number of the evm network
 * @example '1' (ethereum), '137' (polygon)
 */
export async function getEvmTokenBalances(account: string, networkId: string)
: Promise<EvmTokenBalance[]> {
  const res = await fetch(balances1Inch(networkId, account))
  const data: UnprocessedEvmTokenBalances = await res.json()
  const balances = formatEvmTokenBalances(data)
  // The 1inch api is not returning certain tokens which are supported within Wombat. We augment
  // the balances response from 1inch with the balances of essential tokens by calling the token
  // contracts directly
  try {
    const spielworksTokens = await getEssentialBalances(networkId, account)
    if (spielworksTokens !== undefined) {
      return balances.concat(spielworksTokens)
    }
  } catch (e) {
    console.error('Fetching essential balances fails with error: ', e)
  }
  return balances
}

/**
 * Tokens which are owned on EosIo networks (WAX/EOS)
 */
export type EosIoTokenBalance = {
  /**
   * The contract name
   * @example 'eosio.token', 'wombattokens'
   */
  contract: string
  /**
   * The symbol of the token
   * @example 'EOS', 'WUMBAT'
   */
  currency: string
  /**
   * The number of decimals for displaying a readable token quantity
   * @example 8
   */
  decimals: number
  /**
   * The readable amount of the token owned. Should be converted to a {@link TokenAmount} based on
   * the number of decimals.
   * @example '11.99780552'
   */
  amount: string
}

/**
 * A token balance as it is recieved from the api
 */
type RawBalance = Omit<EosIoTokenBalance, 'decimals'> & { decimals: string }

/**
 * Returns the data for all owned tokens on an EosIo network
 * The endpoint used is the EOSIO Light Api https://github.com/cc32d9/eosio_light_api:
 * `Retrieve only token balances for an account: http://apihost.domain/api/balances/CHAIN/ACCOUNT`
 *
 * @param account the scatter account of the user
 * @param network the EosIo network name for which the balances are being fetched
 */
export async function getEosIoTokenBalances(account: string, network: EosIoNetworkSymbol)
: Promise<EosIoTokenBalance[]> {
  const res = await fetch(`https://lightapi.eosamsterdam.net/api/balances/${network.toLowerCase()}/${account}`)
  const data = await res.json()
  const balances: RawBalance[] = data.balances
  return balances.map(it => ({
    ...it, decimals: parseInt(it.decimals),
  }))
}
