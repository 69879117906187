import { EVM_SYSTEM_TOKEN_ADDRESS, Network } from '../../configs/networks'
import { TokenPairIndexes, eosPairs } from '../../configs/eos-pairs-indices'
import { SupportedNetworkTokens } from '../../store/configsSlice'
import { Allowance, SupportedToken } from '../../store/processRawTokenData'
import {
  EosIoConfig, EthereumSystemToken, EvmConfig, SupportedErc20Token, WombatSupportedTokens,
} from './types'

/**
 * Transform EOSIO token config to a list of tokens which can be used in the swap
 * @param config The Wombat {@link EosIoConfig} for an EosIo network
 * @param pairData A list of token pair indexes, if provided. This is necessary for EOS and not
 * returned by the backend.
 * @returns A list of tokens for the EosIo network, ready for use in the swap
 */
function mapEosIoConfig(config: EosIoConfig, pairData?: TokenPairIndexes): SupportedToken[] {
  if (pairData) {
    const tokens = config.map(token => {
      return ({
        symbol: token.symbol,
        name: token.name,
        decimals: token.precision,
        iconUrl: token.iconUrl,
        address: token.account,
        // This is required for EOS swaps. The system for this should be reviewed to see if this
        // value can be removed.
        pairIndex: Object.entries(pairData)
          .find(([symbol, pair]) => {
            return (
              pair.accountName === token.account)
              && (symbol.toLowerCase() === token.symbol.toLowerCase()) },
          )?.[1].pairIndex,
        allowance: 'SET' as Allowance,
        exchangeRate: token.exchangeRate,
      }) })

    return tokens.filter(token => {
      // all EOS tokens should have pairIds configured - this will need to be updated until added
      // to backend
      if (!token.pairIndex) {
        // console.error('no pair id for token', token)
      }
      return token.pairIndex
    })
  }

  return config.map(token => ({
    symbol: token.symbol,
    name: token.name,
    decimals: token.precision,
    iconUrl: token.iconUrl,
    address: token.account,
    allowance: 'SET',
    exchangeRate: token.exchangeRate,
  }))
}

/**
 * Transform EVM token to a token object used in the swap
 * @param config The Wombat {@link } for an EosIo network
 * @param pairData A list of token pair indexes, if provided. This is necessary for EOS and not
 * returned by the backend.
 * @returns A token ready for use in the swap
 */
function evmToSupportedToken(token: EthereumSystemToken | SupportedErc20Token): SupportedToken {
  return {
    symbol: token.symbol,
    name: token.name,
    decimals: token.decimals,
    iconUrl: token.iconUrl,
    address: 'address' in token ? token.address : EVM_SYSTEM_TOKEN_ADDRESS,
    allowance: 'NOT_SET',
    exchangeRate: token.exchangeRate,
  }
}

/**
 * Transform EVM token config to a token object used in the swap
 * @param config The Wombat {@link EvmConfig} for an EVM network
 * @returns A list of tokens for the EVM network, ready for use in the swap
 */
function mapEvmConfig(config: EvmConfig): SupportedToken[] {
  const systemToken = evmToSupportedToken(config.eth)
  const erc20Tokens = config.erc20.map(token => evmToSupportedToken(token))
  return [systemToken, ...erc20Tokens]
}

/**
 * Transform the token config from the Wombat API into tokens which can be used in the swap
 * @param tokens Tokens supported in Wombat
 * @returns Tokens ready to use for each network in the swap
 */
export function convertWombatToSwapTokens(tokens: WombatSupportedTokens): SupportedNetworkTokens {
  return {
    [Network.AVAX]: mapEvmConfig(tokens.avalanche),
    [Network.BNB]: mapEvmConfig(tokens.bnb),
    [Network.ETH]: mapEvmConfig(tokens.ethereum),
    [Network.POL]: mapEvmConfig(tokens.polygon),
    [Network.EOS]: mapEosIoConfig(tokens.eos, eosPairs),
    [Network.WAX]: mapEosIoConfig(tokens.wax),
  }
}
