@import '../../assets/styles/_variables';

.input {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border: 1px solid var(--colorWhite);
  border-radius: 0 16px 16px 0;
  box-sizing: content-box;
  height: 20px;

  &--highlight {
    border: 1px solid var(--colorError);
    border-radius: 0 16px 16px 0;
  }
}

.label {
  position: absolute;
  top: -7px;
  background: var(--colorSurfaceDark);
  padding: 0 4px;
  font-size: 12px;
  color: var(--colorTextSecondary);

  &--highlight {
    color: var(--colorError);
  }
}

.amount {
  min-width: 50px;
  overflow: auto;
}

.amount--disabled::placeholder {
    font-size: 0.75rem;
}

.cta {
  &__max {
    font-weight: 600;
  }
}
