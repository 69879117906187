import cl from 'classnames'
import React, { FC, useMemo, useRef, useState } from 'react'
import { useAppSelector } from '../../hooks/useAppSelector'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { useOutsideClick } from '../../hooks/useOutsideClick'
import { Action } from '../../elements/Buttons/Action'
// TODO - need to reset anything for multichain slice?
import { setDefaultValues } from '../../store/singleChainSlice'
import { logout as waxLogout } from '../../store/waxSlice'
import { logout as eosLogout } from '../../store/eosSlice'
import { logout as evmLogout } from '../../store/evmNetworksSlice'
import { NetworkManagementProps } from '../../views/initialisers/NetworkStateProviders'

import CONNECTED from '../../assets/images/link-connected.svg'
import DISCONNECTED from '../../assets/images/link-disconnected.svg'
import DISCONNECT from '../../assets/images/link-disconnected-highlighted.svg'
import CHEVRON_DOWN from '../../assets/images/chevron-down.svg'
import WAX_CLOUD_WALLET from '../../assets/images/wallet-icons/wax-cloud-wallet.svg'
import WOMBAT from '../../assets/images/wallet-icons/wombat.svg'

import styles from './ManageConnection.module.scss'

/**
 * Formats the user address in a style suitable to display in the UI
 * @example '0xf4...017c'
 */
export function formatAddress(address: string): string {
  const start = address.slice(0, 4)
  const end = address.slice(address.length - 4)
  return start.concat('...', end)
}

/**
 * Returns the icon for the wallet according to the authentication method
 * @param wallet the wallet with which the user is authenticating
 */
export function getIcon(wallet: 'WOMBAT' | 'WAX_CLOUD_WALLET') {
  switch(wallet) {
    case 'WAX_CLOUD_WALLET': return WAX_CLOUD_WALLET
    default: return WOMBAT
  }
}

type Props = Pick<NetworkManagementProps, 'networkState'> & {
  /**
   * A function to open and close the network selector
   */
  toggleNetworkSelector: () => void
  /**
   * If the actions are only for displaying information
   */
  readonly?: boolean
  /**
   * An optional function for managing the wallet connection component
   */
  openWalletConnect?: () => void
  /**
   * Extra setting for custom styles
   */
  isSecondaryHeader?: boolean
}

/**
 * A UI component which allows the user to handle the state of their wallet, e.g. which networks
 * they view and are connected to.
 */
export const ManageConnection: FC<Props> = props => {
  const dispatch = useAppDispatch()
  const config = useAppSelector(state => state.configs[props.networkState.networkName])
  const [viewDisconnect, setViewDisconnect] = useState(false)
  const disconnectRef = useRef<HTMLButtonElement>(null)

  // Handles clicks outside of the `Disconnect` button component
  useOutsideClick({ refs: [disconnectRef], handleClick: () => setViewDisconnect(false) })

  /**
   * Manage a click on the connect wallet button
   */
  function handleClick() {
    if (connected) {
      setViewDisconnect(true)
    } else if (props.openWalletConnect) {
      props.openWalletConnect()
    }
  }

  /**
   * Function for disconnecting the user wallet from all networks
   */
  function handleDisconnect() {
    dispatch(waxLogout())
    dispatch(eosLogout())
    dispatch(evmLogout())
    // reset state for single-chain swap
    dispatch(setDefaultValues(config))
    setViewDisconnect(false)
  }

  /**
   * Whether a wallet is connected to the network being viewed
   */
  const connected = useMemo(() => props.networkState.initialized === 'YES',
    [props.networkState.initialized])

  /**
   * The account action, depending on whether the user wallet is connected for the network
   */
  const accountAction = useMemo(() => {
    if (connected) {
      return {
        leftIcon: getIcon(props.networkState.icon), rightIcon: CONNECTED,
        text: formatAddress(props.networkState.account),
      }
    }
    return { leftIcon: undefined, rightIcon: DISCONNECTED, text: 'Not connected' }
  }, [props.networkState])

  return (
    <div className={cl(styles['actions'], props.isSecondaryHeader ? styles['actions--secondary-header'] : '')}>
      <Action rightIcon={{ imgUrl: !props.readonly ? CHEVRON_DOWN: undefined, hideForMobile: true }}
        leftIcon={{ imgUrl: config.icon, desktop: false }} onClick={props.toggleNetworkSelector}
        text={{ value: config.displayName, style: 'hide-mobile' }} disabled={props.readonly}
        label='select blockchain network'/>
      <div>
        <Action onClick={handleClick} text={{ value: accountAction.text,
          style: 'hide-mobile' }} disconnected={!connected} label='manage wallet connection'
        disabled={(!connected && !props.openWalletConnect) || props.readonly}
        leftIcon={{ imgUrl: accountAction.leftIcon, desktop: true }}
        rightIcon={{ imgUrl: accountAction.rightIcon, hideForMobile: false }}/>
        {viewDisconnect && (
          <button ref={disconnectRef} onClick={handleDisconnect} className={styles['button']}
            aria-live='polite'>
            <img src={DISCONNECT} alt='' className={styles['icon']}/>
            Disconnect
          </button>
        )}
      </div>
    </div>
  )
}
