import { useMemo } from 'react'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { InputType, ListItem } from '../../../hooks/useTokensList'
import { MultiChainToken } from '../store/multiChainSlice'
import { changellyNetworkMap } from '../config/allowedChains'

/**
 * Convert a token as it is received from Changelly API to an object which can be displayed in lists
 * @param token an {@link ChangellyToken}
 * @returns A {@link ListItem} which is generic and suitable for displaying in a list
 */
export function convertChangellyToken(token: MultiChainToken): ListItem {
  return {
    symbol: token.symbol,
    name: token.fullName,
    iconUrl: token.image,
    balance: undefined,
    id: token.ticker,
    chain: changellyNetworkMap[token.blockchain].displayName,
    contract: token.contract,
    decimals: token.decimals,
    txIdName: token.txIdName,
  }
}

type Args = {
  /**
   * Whether the input type is for being swapped from or being swapped into
   */
  type: InputType
}

type TokenInputs = {
  /**
   * A list of all available tokens
   */
  tokens: ListItem[]
}

/**
 * The list of available tokens for either paying in or paying out
 */
export function useInputLists(args: Args): TokenInputs {
  const { tokens: tokensState } = useAppSelector(state => state.multiChain)

  /**
   * A list of tokens available either for input or for output
   */
  const tokensOfInputType = useMemo(() => {
    switch(args.type) {
      case 'from': return tokensState.filter(token => token.enabledFrom)
      case 'to': return tokensState.filter(token => token.enabledTo)
      default: return tokensState
    }
  }, [args.type, tokensState])

  /**
   * The list of token options for users
   */
  const tokens = tokensOfInputType.map(token => convertChangellyToken(token))

  return { tokens }
}
