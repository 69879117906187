import { TokenAmount } from '../../../numbers/TokenAmount'
import { PairResponse } from './pairs'

/**
 * Reduces the given {@link TokenAmount} by a specified percentage
 * This is often needed in order to input a return token amount to as swap transaction which
 * accounts for a margin of fluctuation in the token exchange rate. If the expected {@link
 * TokenAmount} is submitted without an acceptable margin of error, the transaction will often
 * fail.
 * @param amount the {@link TokenAmount} to reduce
 * @param percent the percentage by which the amount should be reduced, input as a whole number
 * @returns the reduced {@link TokenAmount}
 */
export function reduceAmount(amount: TokenAmount, percent: number) {
  const multiplier = 1 - (percent / 100)
  return amount.mul(multiplier)
}

/**
 * Finds the pair with the largest liquidity pool
 * @param pairs All the pairs available for the selected tokens
 * @returns The pair with the largest liquidity, undefined if there are no pools available
 */
export function largestLiquidityPair(pairs: PairResponse[]): PairResponse | undefined {
  if (pairs.length === 1) {
    return pairs[0]
  }

  return pairs.reduce((current, next) => {
    return parseInt(next.liquidity_token) > parseInt(current.liquidity_token) ? next : current
  }, pairs[0])
}
