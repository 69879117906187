import React, { FC } from 'react'
import { Amount } from '../../../../elements/AmountInputs/Amount'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { truncateNumber } from '../../views/Preview'

/**
 * UI component to display the cross-chain swap output amount value
 */
export const OutputAmount: FC = () => {
  const amount = useAppSelector(state => state.multiChain.youGet)?.amount
  return (
    <div>
      <Amount amount={amount ? truncateNumber(amount) : amount}/>
    </div>
  )
}
