import React, { FC, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { getCurrenciesFull } from '../../features/multichain-swap/services/changelly/api'
import { setInitialTokens, setLoading, storeWarnings } from '../../features/multichain-swap/store/multiChainSlice'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { useAppSelector } from '../../hooks/useAppSelector'
import { multiChainSwapError } from '../../store/warnings'

/**
 * Initialises the state for the cross-chain swap
 */
export const MultiChainInitialiser: FC = () => {
  const dispatch = useAppDispatch()
  const initialized = useAppSelector(state => state.multiChain.initialized)

  useEffect(() => {
    const fetchTokens = async () => {
      if (initialized === 'NO') {
        try {
          dispatch(setLoading(true))
          const res = await getCurrenciesFull()
          if ('result' in res) {
            dispatch(setInitialTokens(res.result))
          } else if ('error' in res) {
            console.error(res.error)
          } else {
            console.error('Multichain initialisation failed')
          }
        } catch (e) {
          dispatch(storeWarnings([multiChainSwapError.FAILED_TO_FETCH]))
          console.error(e)
        } finally {
          dispatch(setLoading(false))
        }
      }
    }

    fetchTokens()
  }, [])

  return <Outlet/>
}
