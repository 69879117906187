import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { EOS_CHAIN_ID, getScatterAccount } from '../services/eosio/account'
import { eosioAction, scatterTransact } from '../services/eosio/transact'
import { getEosIoTokenBalances, EosIoTokenBalance } from '../services/balances-api'
import { EosIoNetworkState, EosIoAuthentication, ActionData } from './userSlice'
import { AsyncThunkOptions } from './index'
import { Network } from '../configs/networks'
import { TokenAmount } from '../numbers/TokenAmount'
import { Action } from 'eosjs/dist/eosjs-serialize'
import { storeWarning } from './singleChainSlice'
import { authErrors, warningGenerator } from './warnings'

/**
 * Prepares the data for a token amount to be swapped from one token into another in a transaction
 * using the `swap.defi` contract
 * @returns an action ready to be executed in a swap transaction
 */
function singleChainTransferAction(args: ActionData, userAccount: string, slippagePercent: number)
: Action {
  // the `toAmount` in the memo has to be a quantity in wei
  const toAmount = TokenAmount
    .fromStringWithDecimals(args.to.amount, args.to.decimals, args.to.symbol)
    .mul((100 - slippagePercent)/100)
    .asUnscaledQuantity(args.to.decimals)

  return {
    name: 'transfer',
    account: args.from.contract,
    authorization: [{ actor: userAccount, permission: 'active' }],
    data: {
      from: userAccount,
      quantity: `${args.from.amount} ${args.from.symbol}`,
      memo: `swap, ${toAmount}, ${args.pairId}`,
      to: args.exchangeContract,
    },
  }
}

/**
 * Timeout after which to reinitialize EOS data after any action that changes the data
 */
const DEFAULT_REINITIALIZE_IN_TIMEOUT_MS = 2000

/**
 * The state for eos when the app initiates
 */
const initialState: EosIoNetworkState = {
  initialized: 'NO',
  accountName: '',
  publicKeys: [],
  authenticationMethod: 'WOMBAT',
  ownedTokens: [],
  reinitializeIn: 0,
  actionsData: [],
}

const eosSlice = createSlice({
  name: 'eos',
  initialState: initialState,
  reducers: {
    /**
     * Action to reset the user's eos state
     */
    logout() {
      return initialState
    },
    /**
     * Resets the {@link EosIoNetworkState#reinitializeIn} to 0, meaning no further data refreshes
     * will be scheduled.
     */
    clearRefresh(state) {
      state.reinitializeIn = 0
    },
    /**
     * Stores the data for executing an action to swap two tokens
     */
    setActions(state, action: PayloadAction<ActionData[]>) {
      // ensure the actions data is reset
      state.actionsData = []
      state.actionsData = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(connectWombat.fulfilled, (state, action) => {
        state.initialized = 'YES'
        state.accountName = action.payload.accountName
        state.publicKeys = action.payload.publicKeys
        state.authenticationMethod = action.payload.authenticationMethod
      })
      .addCase(connectWombat.rejected, (state, action) => {
        console.error('Error getting scatter user EOS', action.error)
      })
      .addCase(getBalances.fulfilled, (state, action) => {
        state.ownedTokens = action.payload
      })
      .addCase(getBalances.rejected, (state, action) => {
        console.error('Error loading EOS token balances', action.error)
      })
      .addCase(doSingleChainSwap.fulfilled, (state) => {
        state.reinitializeIn = DEFAULT_REINITIALIZE_IN_TIMEOUT_MS
      })
      .addCase(doSingleChainSwap.rejected, (state, action) => {
        console.error('Error executing EOS token swap', action.error)
      })
      .addCase(doMultiChainSwap.fulfilled, (state) => {
        state.reinitializeIn = DEFAULT_REINITIALIZE_IN_TIMEOUT_MS
      })
      .addCase(doMultiChainSwap.rejected, (state, action) => {
        console.error('Error executing EOS token swap', action.error)
      })
  },
})

/**
 * Action to get the EOS account via Scatter ("login")
 */
export const connectWombat = createAsyncThunk<EosIoAuthentication, void, AsyncThunkOptions>(
  'user/eos/connectScatter',
  async (_, thunkApi) => {
    try {
      const accountData = await getScatterAccount(EOS_CHAIN_ID)
      const authentication: EosIoAuthentication = {
        accountName: accountData.accountName,
        publicKeys: accountData.publicKeys,
        authenticationMethod: 'WOMBAT',
      }
      return authentication
    } catch (e) {
      if (['The wallet is locked.', 'no wallet matching chainId found'].includes((e as Error).message)) {
        thunkApi.dispatch(storeWarning([{ ...authErrors.CHAIN_NOT_FOUND, message: warningGenerator.CHAIN_NOT_FOUND.message('EOS') }]))
      } else {
        thunkApi.dispatch(storeWarning([authErrors.WALLET_NOT_FOUND]))
      }
      throw e
    }
  },
)

/**
 * Action to load the balances of all owned EOS tokens
 */
export const getBalances = createAsyncThunk<EosIoTokenBalance[], void, AsyncThunkOptions>(
  'user/eos/getBalances',
  async (args, thunkApi) => {
    const userAccount = thunkApi.getState().user.eos.accountName
    // Only allow supported tokens to be stored
    return (await getEosIoTokenBalances(userAccount, Network.EOS))
  },
)

/**
 * Make a single swap transaction on EOS network
 */
export const doSingleChainSwap = createAsyncThunk<void, void, AsyncThunkOptions>(
  'user/eos/swap',
  async (_, thunkApi) => {
    const config = thunkApi.getState().configs.EOS
    const { maxPriceImpact } = thunkApi.getState().singleChain
    const { actionsData, accountName } = thunkApi.getState().user.eos

    const priceImpactTolerance = parseFloat(maxPriceImpact)

    const actions = actionsData.map(data => singleChainTransferAction(data, accountName,
      priceImpactTolerance))

    await scatterTransact({ actions: actions }, config.id, config.node)
  },
)

/**
 * Make a cross-chain transaction on EOS
 */
export const doMultiChainSwap = createAsyncThunk<void, void, AsyncThunkOptions>(
  'user/eos/multiswap',
  async (_, thunkApi) => {
    const { accountName, ownedTokens } = thunkApi.getState().user.eos
    const { transaction, payinToken, payoutId } = thunkApi.getState().multiChain
    const config = thunkApi.getState().configs.EOS
    // TODO - make a neater way to do these checks and handle the errors
    if (!transaction) {
      throw new Error('Executing cross-chain swap: No transaction data found')
    } else if (!payinToken) {
      throw new Error('Executing cross-chain swap: No pay-in token data found')
    } else if (payinToken.id !== transaction.currencyFrom) {
      throw new Error('Executing cross-chain swap: Selected and transaction pay in tokens don\'t match')
    }

    let ownedToken

    // TODO - extract this logic somewhere else where it is reusable for all chains.
    // essentially the payin token needs to have the contract address and decimals and
    // symbol available
    if (payinToken.contract) {
      ownedToken = ownedTokens.find(owned => {
        return owned.contract === payinToken.contract
        && owned.currency.toLowerCase() === payinToken.symbol.toLowerCase()
      })
    } else {
    // TODO - ASK ABOUT THIS! - what do we do when there is no contract address provided?
      const token = config.availableTokens.find(available => {
        return (available.name === payinToken.name) && (available.symbol === payinToken.symbol)
      })
      ownedToken = ownedTokens.find(owned => {
        return (
          (owned.contract === token?.address)
          && owned.currency.toLowerCase() === payinToken.symbol.toLowerCase()
        )
      })
    }

    if (!ownedToken) {
      throw new Error('Token isn\'t owned in wallet, can\'t be sent')
    }

    if (payinToken.txIdName && !transaction.payinExtraId) {
      throw new Error(`No payin transaction memo provided for tx ${transaction.id}`)
    }

    if (payoutId.required && payoutId.value && !transaction.payoutExtraId) {
      throw new Error(`No payout transaction memo returned by Changelly for tx ${transaction.id}`)
    }

    const scaledAmount = TokenAmount.fromStringWithDecimals(transaction.amountExpectedFrom,
      ownedToken.decimals, ownedToken.currency).asStringWithDecimalQuantity(ownedToken.decimals)

    const action = eosioAction({ sender: accountName, recipient: transaction.payinAddress,
      memo: transaction.payinExtraId ?? undefined, sentToken: {
        contract: ownedToken.contract, scaledAmount: scaledAmount, symbol: ownedToken.currency,
      } })

    await scatterTransact({ actions: [action] }, config.id, config.node)
  },
)

export const { logout, clearRefresh, setActions } = eosSlice.actions

export default eosSlice.reducer
