@import '../../assets/styles/_typography';
@import '../../assets/styles/_variables';

.network-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  @include text-small(left, 600);
  background-color: var(--colorLightGreyPurple);
  height: 48px;
  padding: 8px;
  border-radius: 24px;
  
  @media (min-width: 768px) {
    width: 170px;
  }
}

.icon {
  width: 32px;
  height: 32px;

  &__placeholder {
    width: 20px;
  }
}

.left {
  background-color: var(--colorWhite);
  border-radius: 50%;
}

.hide-mobile {
  display: none;

  @media (min-width: 768px) {
    display: unset;    
  }
}

.disabled {
  cursor: not-allowed;
}

.disconnected {
  color: var(--colorError);
  background: var(--colorSurfaceError);
}
