/**
 * Pair indices for EOS tokens
 *
 * Pair indices are used to identify the swap routes between a token and the system token
 */
export type TokenPairIndexes = Record<string, { accountName: string, pairIndex: string }>

/**
 * Pair indices for tokens on the EOS chain
 */
export const eosPairs: TokenPairIndexes = {
// TODO - this data should be either included in the backend, or (probably better) the
// EOS system changed so that it is fetched separately based on the available tokens.
// For the moment it is hardcoded so that this can be got out to testing, it will be
// addressed in a separate PR
  EOS: { accountName: 'eosio.token', pairIndex: '177' },
  PWOMBAT: { accountName: 'wmbt.ptokens', pairIndex: '1933' },
  TRYBE: { accountName: 'trybenetwork', pairIndex: '417' },
  WOOL: { accountName: 'woolfintoken', pairIndex: '562' },
  EMT: { accountName: 'emanateoneos', pairIndex: '255' },
  PIXEOS: { accountName: 'pixeos1token', pairIndex: '110' },
  DEOS: { accountName: 'eosdmddtoken', pairIndex: '667' },
  KROWN: { accountName: 'krowndactokn', pairIndex: '97' },
  CHEX: { accountName: 'chexchexchex', pairIndex: '28' },
  EIDOS: { accountName: 'eidosonecoin', pairIndex: '27' },
  SLT: { accountName: 'nblabtokenss', pairIndex: '106' },
  POW: { accountName: 'eosiopowcoin', pairIndex: '128' },
  OGX: { accountName: 'core.ogx', pairIndex: '878' },
  CHL: { accountName: 'challengedac', pairIndex: '232' },
  PEOS: { accountName: 'thepeostoken', pairIndex: '149' },
  PETH: { accountName: 'eth.ptokens', pairIndex: '765' },
  BOID: { accountName: 'boidcomtoken', pairIndex: '8' },
  PUML: { accountName: 'pumlhealthio', pairIndex: '302' },
  BOX: { accountName: 'token.defi', pairIndex: '194' },
  SOUL: { accountName: 'soultokeneos', pairIndex: '51' },
  DMD: { accountName: 'eosdmdtokens', pairIndex: '472' },
  DFS: { accountName: 'minedfstoken', pairIndex: '22' },
  SENSE: { accountName: 'sensegenesis', pairIndex: '54' },
  EOSDT: { accountName: 'eosdtsttoken', pairIndex: '7' },
  PNT: { accountName: 'pnt.ptokens', pairIndex: '797' },
  POOL: { accountName: 'stpool.eos', pairIndex: '385' },
  ATMOS: { accountName: 'novusphereio', pairIndex: '254' },
  DAD: { accountName: 'dadtoken1111', pairIndex: '588' },
  DICE: { accountName: 'betdicetoken', pairIndex: '5' },
  NDX: { accountName: 'newdexissuer', pairIndex: '1' },
  NUT: { accountName: 'eosdtnutoken', pairIndex: '108' },
  IQ: { accountName: 'everipediaiq', pairIndex: '93' },
  TXT: { accountName: 'trusteamwins', pairIndex: '256' },
  CRL: { accountName: 'coralfitoken', pairIndex: '504' },
  VIGOR: { accountName: 'vigortoken11', pairIndex: '76' },
  PIZZA: { accountName: 'pizzatotoken', pairIndex: '14' },
  DAPP: { accountName: 'dappservices', pairIndex: '193' },
  AMZ: { accountName: 'amzcoinvault', pairIndex: '409' },
  EFX: { accountName: 'effecttokens', pairIndex: '191' },
  SVX: { accountName: 'svxmintofeos', pairIndex: '75' },
  USN: { accountName: 'danchortoken', pairIndex: '9' },
  JOKER: { accountName: 'joker.eos', pairIndex: '1273' },
  PBTC: { accountName: 'btc.ptokens', pairIndex: '177' },
  USDT: { accountName: 'tethertether', pairIndex: '12' },
  MEETONE: { accountName: 'eosiomeetone', pairIndex: '18' },
  HVT: { accountName: 'hirevibeshvt', pairIndex: '412' },
  TPT: { accountName: 'eosiotptoken', pairIndex: '4' },
  EOSDAC: { accountName: 'eosdactokens', pairIndex: '105' },
  YFC: { accountName: 'yfctokenmain', pairIndex: '501' },
  YUP: { accountName: 'token.yup', pairIndex: '706' },
  DOP: { accountName: 'dolphintoken', pairIndex: '606' },
  PGL: { accountName: 'prospectorsg', pairIndex: '112' },
  VIG: { accountName: 'vig111111111', pairIndex: '11' },
  HUB: { accountName: 'hub.efi', pairIndex: '1716' },
  BG: { accountName: 'bgbgbgbgbgbg', pairIndex: '3' },
  PMKR: { accountName: 'mkr.ptokens', pairIndex: '793' },
  SBET: { accountName: 'sportbetsbet', pairIndex: '257' },
  GMRX: { accountName: 'gaimin14eosw', pairIndex: '137' },
  OWN: { accountName: 'pickowntoken', pairIndex: '258' },
  SAND: { accountName: 'sandiegocoin', pairIndex: '586' },
  HASH: { accountName: 'eoshashcoins', pairIndex: '1041' },
  GEM: { accountName: 'gemtokencode', pairIndex: '585' },
  START: { accountName: 'token.start', pairIndex: '1609' },
}

// todo - CROSS REFERENCE WITH TOKEN SYMBOLS
