@import '../../../../assets/styles/_variables';

.partner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: var(--colorTextSecondary);
  font-size: 0.75rem;
  padding-top: 32px;
  border-top: solid 1px #504E59;
  cursor: pointer;

  &__icon {
    height: 16px;
  }

  a {
    text-decoration: none;
  }
}
