import { SwapRouteRequest, SwapRouteResponse, AlcorToken } from './types'

/**
 * The base URL for the alcor exchange API
 * @docs https://api.alcor.exchange/#introduction
 */
const baseUrl = 'https://alcor.exchange/api/v2'

const swapRoute = '/swapRouter/getRoute'

/**
 * Gets the data needed to make a swap between two tokens
 * @docs https://api.alcor.exchange/#output-amp-route-calculation
 */
export async function getSwapRoute(args: SwapRouteRequest): Promise<SwapRouteResponse> {
  const query = new URLSearchParams({
    trade_type: 'EXACT_INPUT',
    input: args.payinId,
    output: args.payoutId,
    amount: args.amount,
    // TODO - this doesnt seem to do anything - i.e. response is unchanged regardless of value
    slippage: args.slippage,
    receiver: args.receiver,
    // Optimal number of hops for 1) best exchange rate 2) minimal price impact aka. slippage
    // The other two options, 1 or 3 hops, are either high on price impact or bad on the rate
    maxHops: '2',
  })

  const response = await fetch(`${baseUrl}${swapRoute}?${query.toString()}`, {
    method: 'GET',
  })

  return response.json()
}

/**
 * Fetches the data for tokens which can be swapped on the alcor exchange
 * @docs https://api.alcor.exchange/#all-tokens-prices
 */
export async function getTokens(): Promise<AlcorToken[]> {
  const response = await fetch(`${baseUrl}/tokens`, {
    method: 'GET',
  })
  return response.json()
}
