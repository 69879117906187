import cl from 'classnames'
import React, { FC, useEffect, useState } from 'react'
import { useHandleRecipientInput } from '../../hooks/useHandleRecipientInput'
import { useDefaultRecipientAddress } from '../../hooks/useDefaultRecipientAddress'

import styles from './Inputs.module.scss'

/**
 * An input component for the address of the wallet to receive tokens from a transfer
 */
export const Recipient: FC = () => {
  const [address, setAddress] = useState<string>('')
  const userAddress = useDefaultRecipientAddress()
  useHandleRecipientInput(address)

  // The `userAddress` is initially ''. This is updated as Polygon is initialised, however if
  // the address is directly set to the userAddress, the address value is not updated after the
  // `userAddress` updates. This hook sets the `address` to be the `userAddress` once the value
  // has loaded
  useEffect(() => {
    if (userAddress !== address) {
      setAddress(userAddress)
    }
  }, [userAddress])

  return <div className={cl(styles['token-select'], styles['token-select--tall'])}>
    <label htmlFor='recipient address' className={styles['label']}>Recipient</label>
    <textarea id='recipient address' className={styles['input']} rows={2} value={address}
      placeholder='Recipient' onChange={e => setAddress(e.currentTarget.value)}/>
  </div>
}
