import React, { FC, useMemo, useState } from 'react'
import { useAppSelector } from '../../hooks/useAppSelector'
import { TokenWithAmount } from '../../hooks/useTokenInputs'
import { Button } from '../../elements/Buttons/Button'
import { TransactionPreview } from './TransactionPreview'
import { SetAllowance } from '../SetAllowance'

import styles from './OpenPreviewCta.module.scss'

type Props = {
  /**
   * Data to display the selected input token
   */
  from: TokenWithAmount
  /**
   * Data to display the selected output token, if present
   */
  to?: TokenWithAmount
}

/**
 * A UI component to open the transaction preview
 */
export const OpenPreviewCta: FC<Props> = props => {
  const { fromAmount, toAmount, warnings } = useAppSelector(state => state.singleChain)
  const [modalOpen, setModalOpen] = useState(false)

  /**
   * A warning which should prevent the user from swapping
   */
  const criticalWarning = useMemo(() => {
    return warnings.some(warning => warning.severity === 'error')
  }, [warnings])

  if (props.from.allowance === 'NOT_SET') {
    return <SetAllowance tokenAddress={props.from.address}/>
  }

  return (
    <>
      <div className={styles['swap']}>
        <div className={styles['swap__cta']}>
          <Button disabled={!fromAmount || !toAmount || criticalWarning}
            onClick={() => setModalOpen(true)}>
            Swap
          </Button>
        </div>
      </div>
      {/* TODO - do I remember correctly that you don't like this {...props} syntax?
        if so can't remember the reason */}
      {modalOpen && <TransactionPreview onClose={() => setModalOpen(false)} {...props} />}
    </>
  )
}
