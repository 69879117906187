import cl from 'classnames'
import React, { FC, useMemo } from 'react'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { useSwap } from '../../../../hooks/useSwap'
import { useBalanceAndContract } from '../../hooks/useBalanceAndAllowance'
import { useBlockchain } from '../../hooks/useInputBlockchain'
import { createTransaction } from '../../services/changelly/api'
import { setLoading, setTransactionData, storeWarnings } from '../../store/multiChainSlice'
import { ChangellyQuote } from '../../services/changelly/types'
import { InfoBox } from '../../../../elements/InfoBox/InfoBox'
import { Button } from '../../../../elements/Buttons/Button'
import { multiChainSwapError, warningGenerator } from '../../../../store/warnings'
import { TokenAmount } from '../../../../numbers/TokenAmount'

import styles from '../../../../views/Views.module.scss'

/**
 * The CTA for making a transaction from the preview step
 */
export const PreviewCta: FC<{ quote: ChangellyQuote, recipient: string }> = props => {
  const dispatch = useAppDispatch()
  const network = useBlockchain({ source: 'input-token' })
  const { payinToken, payinAmount, payoutId } = useAppSelector(state => state.multiChain)
  const { balance, userAddress, owned } = useBalanceAndContract(network,
    payinToken)
  const swap = useSwap().multiChainSwap

  // If the user has not enough balance for the transaction
  const insufficientBalance = useMemo(() => {
    if (!owned) {
      return true
    } else {
      const payinAsTokenAmount = TokenAmount.fromStringWithDecimals(payinAmount, balance.decimals,
        balance.symbol)
      return balance.lt(payinAsTokenAmount)
    }
  }, [owned, balance, payinAmount])

  if (userAddress === undefined) {
    return (
      <div>
        <p>Connect your wallet in order to be able to swap</p>
        <Button onClick={() => {}} disabled={true}>Swap</Button>
      </div>
    )
  }

  function handleClick(address: string) {
    dispatch(setLoading(true))
    createTransaction({ from: props.quote.from, to: props.quote.to, address: props.recipient,
      amountFrom: props.quote.amountFrom, refundAddress: address, extraId: payoutId.value })
      .then(res => {
        if ('result' in res) {
          dispatch(setTransactionData(res.result))
          swap(network, res.result.id)
        } else if ('error' in res) {
          console.error(res.error)
          if (res.error.code === -32602) {
            dispatch(storeWarnings([{ ...multiChainSwapError.INVALID_PARAMETER,
              message: warningGenerator.INVALID_PARAMETER.message(res.error.message) }]))
          }
        }
      })
      .catch(e => console.error(e))
    dispatch(setLoading(false))
  }

  return (
    <div className={cl(styles['detail-container'], styles['detail-container--large-spacing'])}>
      {insufficientBalance && <InfoBox type='warning'>
        <p>Your balance of<b> {balance.display(true, balance.decimals)} {payinToken?.symbol} </b>
        is not enough to make this swap</p>
      </InfoBox>}
      <Button onClick={() => handleClick(userAddress)} disabled={insufficientBalance}>
        Swap
      </Button>
    </div>
  )
}
