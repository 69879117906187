import cl from 'classnames'
import React, { FC, PropsWithChildren } from 'react'

import styles from './Button.module.scss'

type Props = PropsWithChildren & {
  /**
   * The function to execute on click
   */
  onClick: () => void
  /**
   * The style of the button
   */
  style?: 'narrow' | 'secondary' | 'secondary--small'
  /**
   * If the button is disabled
   */
  disabled?: boolean
}

/**
 * The UI component for a basic button
 */
export const Button: FC<Props> = props => (
  <button
    className={cl(styles['button'], props.style ? styles[props.style] : '')}
    disabled={props.disabled}
    onClick={props.onClick}
  >
    {props.children}
  </button>
)

type SubmitProps = Omit<Props, 'onClick'>

/**
 * UI component for a submit button
 */
export const Submit: FC<SubmitProps> = props => (
  <button
    className={cl(styles['button'], props.style ? styles[props.style] : '')}
    disabled={props.disabled}
    type='submit'
  >
    {props.children}
  </button>
)

type SelectionButtonProps = Props & {
  /**
   * The icon for the option
   */
  icon: string
}

/**
 * The UI component for a button used when there are several options to select from
 * e.g. different wallets to connect to
 */
export const SelectionButton: FC<SelectionButtonProps> = props => (
  <button
    className={cl(styles['button'], styles['selection-button'])}
    disabled={props.disabled}
    onClick={props.onClick}
  >
    <img src={props.icon} className={styles['icon']}/>
    {props.children}
  </button>
)
