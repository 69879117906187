import cl from 'classnames'
import React, { FC } from 'react'
import { type TransactionDetail } from './TransactionDetails'

import INFO from '../../assets/images/info-outline'

import styles from './TransactionDetails.module.scss'

/**
 * A row displaying a single value related to a potential transaction
 */
export const Detail: FC<TransactionDetail> = props => (
  <p className={cl(styles['row'], props.highlight ? styles['row--highlight'] : '')}>
    <span className={styles['label']}>
      <span>{props.label}</span>
      {props.infoText && (
        <span className={styles['tooltip']}>
          <span className={styles['icon']}>
            <INFO colourHex={props.highlight ? '#FF5A5A' : '#C0C0C0' } />
          </span>
          <span className={styles['tooltip-text']}>
            {props.infoText}
          </span>
        </span>
      )}
    </span>
    <span className={styles['value']}>{props.value}</span>
  </p>
)
