import React, { FC } from 'react'

import styles from './AmountInput.module.scss'

type Props = {
  /**
   * An amount to display
   */
  amount: string | undefined
}

/**
 * UI component for displaying the a read-only output amount estimated from the swap
 */
export const Amount: FC<Props> = props => (
  <label className={styles['input']}>
    <p className={styles['amount']} aria-live='assertive' aria-label='token amount you receive:'>
      {props.amount}
    </p>
    <p className={styles['label']}>Amount</p>
  </label>
)
