import cl from 'classnames'
import React, { FC } from 'react'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { InfoBox } from '../../../elements/InfoBox/InfoBox'
import { Detail } from '../features/TransactionDetails/Detail'
import { PreviewCta } from '../features/PreviewCta/PreviewCta'

import styles from '../../../views/Views.module.scss'

function limitToEightDecimals(num: string) {
  return num.replace(/^(\d+\.\d{0,8})\d*$/, '$1')
}

function removeTrailingZeroes(num: string) {
  return num.replace(/(?:\.0*|(\.\d+?)0+)$/, '$1')
}

/**
 * Limit to 8 decimals and remove trailing zeroes from a number in string format
 * @param num A number in string format
 * @returns A number in string format with max 8 digit and no trailing zeroes
 */
export function truncateNumber(num: string): string {
  const limit = limitToEightDecimals(num)
  return removeTrailingZeroes(limit)
}

/**
 * UI component to preview the values for a transaction once these have been returned by the
 * changelly API
 */
export const Preview: FC = () => {
  const { quote, recipientAddress, youGet, partnerFee } = useAppSelector(state => state.multiChain)

  if (!quote || !recipientAddress || !youGet) {
    // TODO - redirect to cross-chain
    return <InfoBox type='warning'>Oops! Preview data is missing</InfoBox>
  }

  { /* TODO the to and from values are tickers! consider replacing with input values */ }
  const payinSymbol = quote.from.toUpperCase()
  const payoutSymbol = quote.to.toUpperCase()

  return (
    <div className={styles['content']}>
      <h2>Preview</h2>
      <div className={cl(styles['container'], styles['detail-container'])}>
        <Detail label='Swap from'
          value={`${truncateNumber(quote.amountFrom)} ${payinSymbol}`}/>
        <Detail label='Swap to'
          value={`${truncateNumber(youGet.amount)} ${payoutSymbol}`}/>
        <Detail label='Rate'
          value={`1 ${payinSymbol} ≈ ${quote.rate} ${payoutSymbol}`}/>
        <Detail label='Network fee' value={`${truncateNumber(quote.networkFee)} ${payoutSymbol}`}/>
        <Detail label='Service fee' value={`${truncateNumber(quote.fee)} ${payoutSymbol}`}/>
        <Detail label='Partner fee' value={`${truncateNumber(partnerFee)} ${payoutSymbol}`}/>
        <Detail label='Recipient address' value={recipientAddress}/>
        <PreviewCta quote={quote} recipient={recipientAddress}/>
      </div>
    </div>
  )
}
