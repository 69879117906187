import React, { FC, PropsWithChildren } from 'react'
import { useAppSelector } from '../../hooks/useAppSelector'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { connectWaxCloud, connectWombat as connectWombatWax } from '../../store/waxSlice'
import { connectWombat as connectWombatEos } from '../../store/eosSlice'
import { connectWallet as connectWalletEvm } from '../../store/evmNetworksSlice'
import { SimpleSwap } from '../SingleChainSwap'
import { Header } from '../../features/multichain-swap/features/Connector/Header'
import { Network, NetworkSymbol } from '../../configs/networks'

// TODO - this module feels like it is all about state and not UI - perhaps should be reworked as a
// hook.

/**
 * Returns the correct swap, with the correct state passed, based on the swap type
 * @param type Which swap is being viewed
 * @param state The state to pass to the swap being viewed
 */
function getSwap(type: Props, state: NetworkManagementProps): JSX.Element {
  switch (type.swapType) {
    case 'single-chain' : return <SimpleSwap {...state} />
    case 'cross-chain' : return <><Header {...state}/>{type.children}</>
    default: return <div>Oops! Something went wrong</div>
  }
}

/**
 * Props needed to manage the state of a user on a network (e.g. connect, disconnect etc.)
 */
export type NetworkManagementProps = {
  /**
   * A function to connect wombat wallet for the network
   */
  connectWombat: () => Promise<unknown>
  /**
   * A function to connect wax cloud wallet for the network
   */
  connectWaxCloudWallet?: () => Promise<unknown>
  /**
   * Data about the network being viewed
   */
  networkState: {
    /**
     * Whether the network state has been initialised
     */
    initialized: string
    /**
     * The connected account name
     */
    account: string
    /**
     * The icon of the connected wallet
     */
    icon: 'WOMBAT' | 'WAX_CLOUD_WALLET'
    /**
     * The name of the connected network
     */
    networkName: NetworkSymbol
  }
}

type Props = { swapType: 'single-chain' } | ({ swapType: 'cross-chain' } & PropsWithChildren)

/**
 * A component which provides state to Wax Swaps
 */
export const WaxSwap: FC<Props> = props => {
  const wax = useAppSelector(state => state.user.wax)
  const dispatch = useAppDispatch()

  const state: NetworkManagementProps = {
    networkState: {
      initialized: wax.initialized, account: wax.accountName, icon: wax.authenticationMethod,
      networkName: Network.WAX,
    },
    connectWombat: () => dispatch(connectWombatWax()),
    connectWaxCloudWallet: () => dispatch(connectWaxCloud()),
  }

  return getSwap(props, state)
}

/**
 * A component which provides state to Eos Swaps
 */
export const EosSwap: FC<Props> = props => {
  const eos = useAppSelector(state => state.user.eos)
  const dispatch = useAppDispatch()

  const state: NetworkManagementProps = {
    networkState: {
      initialized: eos.initialized, account: eos.accountName, icon: eos.authenticationMethod,
      networkName: Network.EOS,
    },
    connectWombat: () => dispatch(connectWombatEos()),
  }

  return getSwap(props, state)
}

/**
 * A component which provides state to Evm Swaps
 */
export const EvmSwap: FC<Props & { network: NetworkSymbol }> = props => {
  const { initialized, address } = useAppSelector(state => state.user.evm)
  const dispatch = useAppDispatch()

  const state: NetworkManagementProps = {
    networkState: { initialized, account: address, icon: 'WOMBAT', networkName: props.network },
    connectWombat: () => dispatch(connectWalletEvm()),
  }

  return getSwap(props, state)
}
