@import '../../../../assets/styles/_variables';

.detail {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 18px;
}

.label {
  color: var(--colorTextDisabled);
}
