import cl from 'classnames'
import { FC, PropsWithChildren } from 'react'

import styles from './Info.module.scss'

type Props = PropsWithChildren & {
  /**
   * The type of information being shown
   */
  type?: 'info' | 'warning'
}

// TODO - think about refactoring/removing this. Is it really necessary?
/**
 * A standardised UI info text component
 */
export const Info: FC<Props> = ({ type = 'info', children }) => (
  <div className={cl(styles['container'], styles[type])} aria-live='assertive'>
    {children}
  </div>
)
