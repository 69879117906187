import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch } from './index'
import { Network } from '../configs/networks'

export enum TxState {
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  REPLACED = 'REPLACED',
  CONFIRMED = 'CONFIRMED'
}

export enum TxType {
  SWAP = 'SWAP',
  SET_ALLOWANCE = 'SET_ALLOWANCE'
}

type Transaction = {
  hash: string
  network: Network
  type: TxType
  tokenAddress?: string
  state: TxState
  blockNumber: number | null
  confirmations: number | null
  /**
   * Whether the transaction has been shown already to the user
   */
  isShown: boolean
}

export type TransactionsState = Transaction[]

/**
 * Returns any transaction data stored in local storage
 */
function getLocalStorageState(): TransactionsState {
  const storedTransactions = localStorage.getItem('transactions')
  if (storedTransactions !== null) {
    let parsedTransactions
    try {
      parsedTransactions = JSON.parse(storedTransactions)
    } catch (e) {
      console.warn('Stored transactions %s in local storage are not JSON.',
        storedTransactions, e)
      return []
    }
    if (!Array.isArray(parsedTransactions)) {
      console.warn('Stored transactions %o are not an array', storedTransactions)
      return []
    }
    return parsedTransactions
  }
  return []
}

/**
 * Creates a template for a transaction to be stored in local storage
 * @param hash the hash of the transaction
 * @param network the network on which the transaction is made
 */
function createEmptyTx(
  hash: string, type: TxType, network: Network, address?: string,
): Transaction {
  return {
    hash: hash,
    network: network,
    type: type,
    tokenAddress: address,
    state: TxState.PENDING,
    blockNumber: null,
    confirmations: null,
    isShown: false,
  }
}

/**
 * A slice of the state containing transactions that were done. This can be useful to track their
 * state.
 *
 * The transactions are also stored in local storage so they are persistent over dApp reloads
 */
const transactionsSlice = createSlice({
  name: 'transactions',
  initialState: getLocalStorageState,
  reducers: {
    addTransaction(state, action: PayloadAction<Transaction>) {
      if (state.find(tx => tx.hash === action.payload.hash)) {
        return
      }
      state.push(action.payload)
    },
    deleteTransaction(state, action: PayloadAction<Transaction>) {
      return state.filter(tx => tx.hash !== action.payload.hash)
    },
    confirmTransaction(state, action: PayloadAction<Transaction>) {
      const stateTx = state.find(tx => tx.hash === action.payload.hash)
      if (stateTx) {
        stateTx.state = TxState.CONFIRMED
        stateTx.blockNumber = action.payload.blockNumber
        stateTx.confirmations = action.payload.confirmations
        stateTx.isShown = false
      }
    },
  },
})

/**
 * Store details of a new transaction
 *
 * @param hash the hash of the transaction
 * @example 0x94550b9b6bd46a3f03be079ff12e6e71a7aea95818d2cc7b7d581a4b9f5e5ae6
 * @param network the {@link Network} on which the transaction is being made
 * @example 'POL'
 */
export function addTransaction(
  hash: string, network: Network, type: TxType, tokenAddress?: string,
) {
  return (dispatch: AppDispatch) => {
    const localStorageState = getLocalStorageState()
    const tx = createEmptyTx(hash, type, network, tokenAddress)
    if (!localStorageState.find(tx => tx.hash === hash)) {
      localStorageState.push(tx)
      localStorage.setItem('transactions', JSON.stringify(localStorageState))
    }
    dispatch(transactionsSlice.actions.addTransaction(tx))
  }
}

/**
 * Mark a transaction as confirmed, supplying the additional data. The confirmed transaction is
 * also removed from the local storage.
 *
 * @param confirmedTransaction The data for the confirmed transaction
 */
export function confirmTransaction(confirmedTransaction: Transaction) {
  return (dispatch: AppDispatch) => {
    const localStorageState = getLocalStorageState()
    const newLocalStorageState = localStorageState
      .filter(tx => tx.hash !== confirmedTransaction.hash)
    localStorage.setItem('transactions', JSON.stringify(newLocalStorageState))

    dispatch(transactionsSlice.actions.confirmTransaction(confirmedTransaction))
  }
}

/**
 * Delete transaction from the store
 *
 * @param tx The transaction for deleting
 */
export function deleteTransaction(tx: Transaction) {
  return (dispatch: AppDispatch) => {
    const localStorageState = getLocalStorageState()
    const localStorageTx = localStorageState
      .find(storageTx => storageTx.hash === tx.hash)

    if (localStorageTx) {
      const filteredState = localStorageState.filter(tx => tx.hash !== tx.hash)
      localStorage.setItem('transactions', JSON.stringify(filteredState))
    }

    dispatch(transactionsSlice.actions.deleteTransaction(tx))
  }
}

export default transactionsSlice.reducer
