@import '../../assets/styles/_variables';
@import '../../assets/styles/_typography';

.moonpay {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}

.title {
  @include text-small()
}

.link {
  display: flex;
  gap: 8px;
  text-decoration: none;
  padding: 8px;
  color: var(--colorHighlight);
  @include text-normal(center, 600)
}

.icon {
  height: 16px;
  width: 16px;
}
