@import '../assets/styles/_variables';

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 768px) {
    gap: 32px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  
  @media (min-width: 768px) {
    gap: 32px;
    padding: 32px;
    border-radius: 32px;
    border: 1px solid var(--colorBorder);
    background: var(--colorSurfaceDark);
  }
}

.detail-container {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &--large-spacing {
    gap: 32px;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  @media (min-width: 768px) {
    flex-direction: column;    
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &-icon {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  &__back {
    display: flex;
    align-items: center;
    font-weight: 700;
  }
}

.icon {
  height: 25px;
  width: 25px;
}

.custom-address {
  padding: 16px;
  gap: 8px;
}

.form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  align-items: center;
  

  @media (min-width: 375px) {
    grid-template-columns: 2fr 1fr;
  }
}

.label {
  font-size: 0.75rem;
  color: var(--colorTextSecondary);
}

.input {
  min-width: 200px;
  max-width: 400px;
  color: var(--colorWhite);
  font-size: 0.8rem;
  background: var(--colorSurface);
  padding: 8px 16px;
  border: 1px solid var(--colorBorder);
  border-radius: 16px;

  &__subtext {
    font-size: 0.75rem;
    padding: 4px 24px;
  }
}

.input::placeholder {
  font-size: 0.75rem;
  color: var(--colorTextDisabled);
}

.cta-text {
  font-size: 0.8rem;
}

.tx-history {
  &__list {
    display: flex;
    flex-direction: column;
    font-size: 0.75rem;
    padding: 0;
    gap: 0;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    &:not(:last-child) {
      border-bottom: 0.1rem solid var(--colorWhiteDisabled);
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: flex-start;
    }

    &-date {
      color: var(--colorTextSecondary);
    }

    &-amount {
      display: flex;
      gap: 4px;
      align-items: center;
      font-size: 0.875rem;
    }

    &-icon {
      height: 1rem;
      width: 1rem;

      &--large {
        height: 1.25rem;
        width: 1.25rem;
      }
    }

    &-status {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
}

.green {
  color: var(--colorSuccess);
}

.yellow {
  color: var(--colorPending);
}

.blue {
  color: var(--colorUnresolved);
}

.red {
  color: var(--colorError);
}

.status {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
}
