@import '../assets/styles/_typography';
@import '../assets/styles/_variables';

.container {
  padding: 4px 24px;
  @include text-smallest(left);
}

.warning {
  color: var(--colorError);
}
