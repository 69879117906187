/**
 * Indicates if the cross-chain swap history feature is enabled
 *
 * This flag can be enabled at build time with the `CROSS_CHAIN_SWAP_HISTORY_ENABLED`
 * environment variable set to `true`. Otherwise the cross-chain swap history feature is disabled.
 */
function isCrossChainSwapHistoryEnabled(): boolean {
  return process.env.CROSS_CHAIN_SWAP_HISTORY_ENABLED === 'true'
}

/**
 * Indicates if fetching the changelly history by the token recipient is enabled
 *
 * This flag can be enabled at build time with the `CHANGELLY_HISTORY_BY_RECIPIENT`
 * environment variable set to `true`. Otherwise fetching the changelly history by the token
 * recipient is disabled.
 */
function isChangellyHistoryByRecipientEnabled(): boolean {
  return process.env.CHANGELLY_HISTORY_BY_RECIPIENT === 'true'
}

/**
 * Indicates if bypassing disbaled tokens e.g. re-enabling is enabled for an environment
 *
 * This flag can be enabled at build time with the `BYPASS_DISABLED_TOKENS` environment variable
 * set to `true`. This is useful for testing tokens which are disabled on production. Otherwise the
 * disabled tokens will be disabled for all environments.
 */
function isDisableTokensBypassed(): boolean {
  return process.env.BYPASS_DISABLED_TOKENS === 'true'
}

/**
 * Feature flags for features which can be enabled and disabled for different environments
 */
export const features = {
  isCrossChainSwapHistoryEnabled,
  isChangellyHistoryByRecipientEnabled,
  isDisableTokensBypassed,
}
