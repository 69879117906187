import React, { FC, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { useAppDispatch } from '../hooks/useAppDispatch'
import { initialiseDisplayValues } from '../store/singleChainSlice'
import { useAppSelector } from '../hooks/useAppSelector'
import { Network } from '../configs/networks'
import { MultiChainNetworkSelector, SingleChainNetworkSelector } from '../features/NetworkSelector'
import { initialiseEosIoNodes, setSupportedTokens } from '../store/configsSlice'

/**
 * Custom hook to correctly initialise the displaySlice based on the location (path in the URL)
 * that the page was opened in.
 * It is important to run this hook high level in the component tree and only use it once.
 */
function useSetDefaultTokens() {
  const location = useLocation()
  const dispatch = useAppDispatch()

  // Effect to set the default values for the display slice based on the network in the URL
  // This is meant to only run during a full page refresh so the initialState of the displaySlice
  // is correct (as it defaults to hardcoded WAX)
  useEffect(() => {
    async function initialise() {
    // Remove the leading slash.
      const network = location.pathname.substring(1).toUpperCase()
      // Check if the pathname corresponds to a known network, if so dispatch its default values
      try {
        await dispatch(setSupportedTokens()).unwrap()
        if (network in Network) {
          dispatch(initialiseDisplayValues(network as Network))
        } else if (network === 'MATIC') {
          dispatch(initialiseDisplayValues(Network.POL))
        } else if (network === '') {
          dispatch(initialiseDisplayValues(Network.WAX))
        } else if (network === '/cross-chain') {
          dispatch(initialiseDisplayValues(Network.ETH))
        }
      } catch (e) {
        console.error(e)
      }
    }

    initialise()
  }, [])
}

/**
 * Custom hook to initialise the nodes for EOSIO networks.
 * It is important to run this hook high level in the component tree and only use it once.
 */
function useSetEosIoNodes() {
  const dispatch = useAppDispatch()

  // Effect to set the values for EOSIO nodes. This is meant to only run during a full page refresh.
  useEffect(() => {
    dispatch(initialiseEosIoNodes(Network.EOS))
    dispatch(initialiseEosIoNodes(Network.WAX))
  }, [])
}

/**
 * Root view of the application containing the network selector and a router outlet for child
 * routes.
 */
export const Root: FC = () => {
  // TODO - refactor this to use one component
  const selectorOpenSingle = useAppSelector(state => state.singleChain.networkSelectorOpen)
  const selectorOpenMulti = useAppSelector(state => state.multiChain.networkSelectorOpen)
  useSetDefaultTokens()
  useSetEosIoNodes()
  return <>
    {selectorOpenMulti && <MultiChainNetworkSelector/>}
    {selectorOpenSingle && <SingleChainNetworkSelector/>}
    <Outlet />
  </>
}
