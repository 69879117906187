import React, { FC, useMemo, useState } from 'react'
import { useAppSelector } from '../hooks/useAppSelector'
import { useAppDispatch } from '../hooks/useAppDispatch'
import { toggleNetworkSelector } from '../store/singleChainSlice'
import { Button } from '../elements/Buttons/Button'
import { InfoBox } from '../elements/InfoBox/InfoBox'
import { ConnectWallet } from '../features/WalletConnection/ConnectWallet'
import { ChooseInputs } from './ChooseInputs'
import { NetworkManagementProps } from './initialisers/NetworkStateProviders'
import { ManageConnection } from '../features/WalletConnection/ManageConnection'
import { Navigation } from '../elements/Navigation/Navigation'

import WAX_CLOUD_WALLET from '../assets/images/wallet-icons/wax-cloud-wallet.svg'
import WOMBAT from '../assets/images/wallet-icons/wombat.svg'

import styles from './Views.module.scss'

/**
 * Returns the icon for the wallet according to the authentication method
 * @param wallet the wallet with which the user is authenticating
 */
export function getIcon(wallet: 'WOMBAT' | 'WAX_CLOUD_WALLET') {
  switch(wallet) {
    case 'WAX_CLOUD_WALLET': return WAX_CLOUD_WALLET
    default: return WOMBAT
  }
}

/**
 * The initial view for the simple swap, a page with wallet connection options and user inputs.
 */
export const SimpleSwap: FC<NetworkManagementProps> = props => {
  const dispatch = useAppDispatch()
  const { warnings } = useAppSelector(state => state.singleChain)
  const [connectorOpen, setConnectorOpen] = useState(false)

  /**
   * Whether a wallet is connected to the network being viewed
   */
  const connected = useMemo(() => props.networkState.initialized === 'YES',
    [props.networkState.initialized])

  function manageNetwork() {
    dispatch(toggleNetworkSelector())
  }

  return (
    <>
      <div className={styles['header']}>
        <Navigation pathname='/'/>
        <ManageConnection networkState={props.networkState} toggleNetworkSelector={manageNetwork}/>
      </div>
      <div className={styles['container']}>
        {!connected && <Button style='narrow' onClick={() => setConnectorOpen(true)}>
        Connect Wallet
        </Button>}
        {warnings.map(warning => {
          return (warning.type === 'auth' && (
            <InfoBox key={warning.code} type='warning'>{warning.message}</InfoBox>
          ))
        })}
        {connectorOpen && (
          <ConnectWallet connectWombat={props.connectWombat}
            connectWaxCloudWallet={props.connectWaxCloudWallet}
            closeSelector={() => setConnectorOpen(false)}/>
        )}
        <ChooseInputs/>
      </div>
    </>
  )
}
