import React, { FC } from 'react'
import { useLocation } from 'react-router-dom'

import ICON from '../../../../assets/images/changelly-bw.svg'

import styles from './Partner.module.scss'

/**
 * UI component to show partnership with Changelly
 */
export const Partner: FC = () => {
  const { pathname } = useLocation()

  if (!pathname.includes('/cross-chain')) {
    return null
  }

  return (
    <div className={styles['partner']}>
      <p>Powered by <a href='https://changelly.com/' target='_blank'>Changelly</a></p>
      <img src={ICON} alt='' className={styles['partner__icon']}/>
    </div>
  )
}
