@import '../assets/styles/_typography';

.transaction-status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
  height: 100vh;
  text-decoration: inherit;

  @media (min-width: 768px) {
    height: 50vh;
  }

  &__text {
    @include text-smaller(center, 600);
    color: white;
  }

  &__icon {
    width: 20px;
  }

  &__chevron {
    height: 20px;
    width: 20px;
    transform: rotate(270deg);
  }

  &__btn {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    margin: 5rem 0 0 0;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    @include text-normal(center, 700)
  }
}
