import { WombatSupportedTokens } from './types'

const baseUrl = process.env.WOMBAT_BACKEND ?? 'https://api.getwombat.io'

/**
 * Fetches the tokens which are available within Wombat
 */
export async function fetchTokens(): Promise<WombatSupportedTokens> {
  const data = await fetch(`${baseUrl}/supported-tokens`, {
    method: 'GET',
  })
  return data.json()
}
