import { TokenAmount } from '../numbers/TokenAmount'
import type { EvmTokensData, EosioTokensData } from '../configs/tokens'
import type { EvmTokenBalance, EosIoTokenBalance } from '../services/balances-api'

export type Allowance = 'SET' | 'NOT_SET'

/**
 * Data for all the tokens which are available for swapping to and from on a given network
 */
export type SupportedToken = {
  /**
   * The symbol of the token, this is set of characters that a token is displayed and recognised
   * by. e.g. 'POL', 'USDC'
   */
  symbol: string
  /**
   * The full name of a token, e.g. 'Ethereum'
   */
  name: string
  /**
   * The number of decimals for converting to a {@link TokenAmount}
   * @example 8
   */
  decimals: number
  /**
   * The url of the token's icon
   */
  iconUrl: string
  /**
   * The token address/account
   */
  address: string
  /**
   * The pairIndex is only present for the EosIo tokens, comes from the {@link EosioTokenData}
   */
  pairIndex?: string
  /**
   * Whether the user has approved the swapping smart contract for a certain allowance (amount that
   * it is allowed to trade) of this token
   */
  allowance: Allowance
  /**
   * The exchange rate to the requested currency of the token (in currency/System token)
   * @example 2.45
   */
  exchangeRate?: number
}

/**
 * The data for tokens which can be the input for a swap, i.e. swapped from
 */
export type OwnedToken = SupportedToken & {
  /**
   * The string with decimal representation of a token balance. Should be converted to a
   * {@link TokenAmount} based on the number of decimals.
   * @example '11.99780552'
   */
  balance: string
}

/**
 * Standardises owned EVM tokens to be stored in redux for the display state
 *
 * @param tokens The tokens owned by a user on an EVM network
 * @param config The config for every available token on the network
 * @param fallback A default token to display for the network in case no owned tokens can be found
 * @returns The ready-for-display tokens owned by the user on a given network
 */
export function formatEvmOwnedTokens(
  tokens: EvmTokenBalance[], config: SupportedToken[], fallback: OwnedToken,
): OwnedToken[] {
  const formatted: OwnedToken[] = []
  tokens.forEach(it => {
    const data = config.find(token => token.address === it.address)
    if (data) {
      formatted.push({
        ...data,
        // convert to a string with decimals to match the EosIo format
        // TODO - I guess actually both would be better stored as unscaled values?
        balance: TokenAmount.fromUnscaledString(
          it.balance, data.decimals, data.symbol,
        ).asStringWithDecimalQuantity(data.decimals),
        allowance: it.allowance !== '0' ? 'SET' : 'NOT_SET',
      })
    }
  })
  if (formatted.length === 0) {
    formatted.push(fallback)
  }
  return formatted
}

/**
 * Standardises owned EosIO tokens to be stored in redux for the display state
 *
 * @param tokens The tokens owned by a user on an EosIo network
 * @param config The config for every available token on the network
 * @param fallback A default token to display for the network in case no owned tokens can be found
 * @returns The ready-for-display tokens owned by the user on a given network
 */
export function formatEosIoOwnedTokens(
  tokens: EosIoTokenBalance[], config: SupportedToken[], fallback: OwnedToken,
): OwnedToken[] {
  const formatted: OwnedToken[] = []
  tokens.forEach(it => {
    const data = config.find(token => {
      return (token.address === it.contract)
      && (token.symbol.toLowerCase() === it.currency.toLowerCase())
    })
    if (data) {
      formatted.push({
        ...data,
        balance: it.amount,
        // TODO - EOSIO tokens don't need to set an allowance, it is set here to 'SET' in order not
        // to have the allowance setting requested, however there is probably a better way to handle
        // this
        allowance: 'SET',
      })
    }
  })
  if (formatted.length === 0) {
    formatted.push(fallback)
  }
  return formatted
}

/**
 * Standardises supported EVM tokens to be stored in redux for swapping for all networks
 *
 * @param tokens the tokens available to swap
 */
export function formatEvmSupportedTokens(tokens: EvmTokensData): SupportedToken[] {
  return Object.values(tokens).map(val => ({
    ...val,
    // TODO - expand all properties
    // TODO - why is this 'account'
    account: val.address,
    symbol: val.symbol,
    name: val.name,
    allowance: 'NOT_SET',
  }))
}

/**
 * Standardises supported EosIo tokens to be stored in redux for swapping for all networks
 *
 * @param tokens the tokens available to swap
 */
export function formatEosIoSupportedTokens(tokens: EosioTokensData): SupportedToken[] {
  return Object.values(tokens).map(val => ({
    ...val,
    address: val.accountName,
    decimals: parseInt(val.precision),
    symbol: val.symbol,
    name: val.name,
    allowance: 'NOT_SET',
  }))
}
