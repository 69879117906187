import { useEffect } from 'react'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { useDebouncedCallback } from '../../../hooks/useDebouncedCallback'
import { getPairRange } from '../services/changelly/api'
import { clearWarnings, setInputRange, setLoading, storeWarnings } from '../store/multiChainSlice'
import { multiChainSwapError } from '../../../store/warnings'

/**
 * The number of milliseconds to delay debouncing the api calls
 */
const DEBOUNCE_DELAY_MS = 1000

/**
 * Fetches the minimum and maximum allowed inputs for the provided token pair
 */
export function useMinMaxInputs() {
  const dispatch = useAppDispatch()
  const debounce = useDebouncedCallback(getMinMaxInputs, DEBOUNCE_DELAY_MS)
  const { payinToken, payoutToken } = useAppSelector(state => state.multiChain)

  useEffect(() => {
    debounce()
  }, [payinToken, payoutToken])

  function getMinMaxInputs() {
    if (payinToken && payoutToken) {
      dispatch(setLoading(true))
      dispatch(clearWarnings([multiChainSwapError.NO_MIN_MAX]))
      getPairRange({ from: payinToken.id, to: payoutToken.id })
        .then(res => {
          if ('result' in res) {
            if (res.result.length === 0) {
              throw new Error('Error - no result for min and max values returned for this pair')
            } else {
              const data = res.result.find(result => {
                return result.from == payinToken.id && result.to === payoutToken.id
              })
              const min = data?.minAmountFloat
              const max = data?.maxAmountFloat
              if (min && max) {
                dispatch(setInputRange({ min, max }))
              } else {
                throw new Error('Error - no result for min and max values returned for this pair')
              }
            }
          } else if ('error' in res) {
            throw new Error(res.error.message)
          } })
        .catch(() => {
          dispatch(storeWarnings([multiChainSwapError.NO_MIN_MAX]))
          dispatch(setInputRange(undefined))
        })
      dispatch(setLoading(false))
    }
  }
}
