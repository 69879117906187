import { FC } from 'react'
import { useAppSelector } from '../../hooks/useAppSelector'
import { Amount } from '../../elements/AmountInputs/Amount'

/**
 * A UI component to show the output token amount from the classic swap
 */
export const OutputAmount: FC = () => {
  const recieved = useAppSelector(state => state.singleChain.toAmount)

  return <Amount amount={recieved}/>
}
