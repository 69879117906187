import { TokenAmount } from '../../numbers/TokenAmount'

// https://uniswapv3book.com/docs/introduction/constant-function-market-maker/

/**
 * Get the constant for reserves of a liquidity pool
 *
 * @param reserveX the liquidity reserve of the token input to the swap (i.e. swapped from)
 * @param reserveY the liquidity reserve of the token output from the swap (i.e. swapped to)
 * @returns the constant k which shows the relationship between the liquidity pool reserves
 * as a {@link TokenAmount}
 */
function getLiquidityPoolConstant(reserveX: TokenAmount, reserveY: TokenAmount): TokenAmount {
  // using `k = x * y` where x and y are the token reserves of a liquidity pool
  return reserveX.mul(reserveY)
}

/**
 * Get the remaining liquidity for the output
 *
 * @param reserveX the liquidity reserve of the token input to the swap (i.e. swapped from)
 * @param inputX the {@link TokenAmount} of token X input to the swap (i.e. swapped from)
 * @param k the constant between the reserves of a liquidity pool
 * @returns the {@link TokenAmount} of reserve remaining for the output token after the swap
 */
function getReserveYAfterSwap(reserveX: TokenAmount, inputX: TokenAmount, k: TokenAmount):
TokenAmount {
  // get the size of the x reserve in the liquidity pool, after the user has injected their
  // input tokens to it
  const reserveXAfterSwap = reserveX.add(inputX)
  // using `k = x * y`, to get y reserve after the swap `y = k / x`
  return k.div(reserveXAfterSwap)
}

/**
 * Get the average exchange rate at which the swap would be performed
 *
 * @param y2 the {@link TokenAmount} of the reserveY (OUTPUT token reserve) AFTER the swap
 * @param y1 the {@link TokenAmount} of the reserveY (OUTPUT token reserve) BEFORE the swap
 * @param x2 the {@link TokenAmount} of the reserveX (INPUT token reserve) AFTER the swap
 * @param x1 the {@link TokenAmount} of the reserveX (INPUT token reserve) BEFORE the swap
 * @returns the swap's average exchange rate
 */
function getAverageExchRate(y2: TokenAmount, y1: TokenAmount, x2: TokenAmount, x1: TokenAmount):
TokenAmount {
  // using `m = y2 - y1 / x2 - x1` to get the average exchange rate at which the swap was performed
  const changeInY = y2.sub(y1)
  const changeInX = x2.sub(x1)
  return changeInY.div(changeInX).mul(-1)
}

/**
 * Get the price impact as a percentage
 *
 * @param originalExRate the exchange rate between two tokens before a swap
 * @param averageExRate the average exchange rate between two tokens during a swap
 * @returns the price impact on the output token as a percentage
 */
function getPriceImpact(originalExRate: TokenAmount, averageExRate: TokenAmount):
TokenAmount {
  // using `impact = (originalExRate - averageExRate) / originalExRate`
  const numerator = originalExRate.sub(averageExRate)
  return numerator.div(originalExRate)
}

/**
 * Calculates the price impact of a swap on the output token
 *
 * @param reserveFrom the liquidity reserve of the token input to the swap (i.e. swapped from)
 * @param reserveTo the liquidity reserve of the token output from the swap (i.e. swapped to)
 * @param amountFrom the {@link TokenAmount} of `reserveFrom` token input to the swap
 * (i.e. swapped from)
 */
export function calculatePriceImpact(reserveFrom: TokenAmount, reserveTo: TokenAmount,
  amountFrom: TokenAmount): TokenAmount {
  const k = getLiquidityPoolConstant(reserveFrom, reserveTo)
  const newReserveX = reserveFrom.add(amountFrom)
  const newReserveY = getReserveYAfterSwap(reserveFrom, amountFrom, k)
  // get the exchange rate between the reserves in a liquidity pool before the swap
  const originalExchangeRate = reserveTo.div(reserveFrom)
  const averageExchangeRate = getAverageExchRate(newReserveY, reserveTo, newReserveX, reserveFrom)
  return getPriceImpact(originalExchangeRate, averageExchangeRate)
}

/**
 * Returns the price impact for a single path swap, e.g. WAX -> PBTC, as a percentage with single
 * decimal value in string format
 *
 * @param reserveFrom the liquidity reserve of the token input to the swap (i.e. swapped from)
 * @param reserveTo the liquidity reserve of the token output from the swap (i.e. swapped to)
 * @param inputFrom the {@link TokenAmount} of token X input to the swap (i.e. swapped from)
 * @returns percentage value for the price impact, e.g. '18.4'
 */
export function singlePathPriceImpactPercent(
  reserveFrom: TokenAmount, reserveTo: TokenAmount, inputFrom: TokenAmount,
): string {
  // multiply by 100 for the percentage
  const priceImpact = calculatePriceImpact(reserveFrom, reserveTo, inputFrom).mul(100)
  return priceImpact.asStringWithDecimalQuantity(1)
}

/**
 * Returns the price impact for a multi-path swap, e.g. PBTC -> EOS, EOS -> WOMBAT,
 * as a percentage with single decimal value in string format
 *
 * @param swap1 the values to calculate the price impact for the first transaction in the swap
 * @param swap2 the values to calculate the price impact for the second transaction in the swap
 * @returns the total price impact percentage for a multi-path swap
 */
export function multiPathPriceImpactPercentage(
  swap1: { reserveFrom: TokenAmount, reserveTo: TokenAmount, inputFrom: TokenAmount },
  swap2: { reserveFrom: TokenAmount, reserveTo: TokenAmount, inputFrom: TokenAmount },
): string {
  // create a single unit of the output tokens for both swaps, these are needed in the next step
  const oneSwap1OutputToken = TokenAmount.ONE(swap1.reserveTo.decimals, swap1.reserveTo.symbol)
  const oneSwap2OutputToken = TokenAmount.ONE(swap2.reserveTo.decimals, swap2.reserveTo.symbol)

  // using the formula `effective rate = 1 - price impact`
  const effectiveRateSwap1 = oneSwap1OutputToken.sub(calculatePriceImpact(swap1.reserveFrom,
    swap1.reserveTo, swap1.inputFrom))
  const effectiveRateSwap2 = oneSwap2OutputToken.sub(calculatePriceImpact(swap2.reserveFrom,
    swap2.reserveTo, swap2.inputFrom))

  // get the combined effective swap rate by multiplying the two rates together
  const totalEffectiveSwapRate = effectiveRateSwap1.mul(effectiveRateSwap2)
  // again using the formula `effective rate = 1 - price impact`, get the composite price impact
  const compositePriceImpact = oneSwap2OutputToken.sub(totalEffectiveSwapRate)
  // multiply by 100 for the percentage
  const priceImpact = compositePriceImpact.mul(100)
  return priceImpact.asStringWithDecimalQuantity(1)
}

/**
 * Returns true if the price impact is higher than the user's max allowed price impact
 * @param estPriceImpact The estimated price impact for the transaction
 * @param maxPiceImpact The user's highest allowed price impact
 */
export function isSignificantPriceImpact(estPriceImpact: string | undefined, maxPiceImpact: number)
: boolean {
  if (estPriceImpact === undefined) {
    return false
  }
  return parseFloat(estPriceImpact.replace('%', '')) > maxPiceImpact
}

/**
 * Returns the price impact based on the estimated input and output USD amounts
 * @param srcUsd The input amount as a USD value in string format
 * @example '36.993'
 * @param destUsd The output amount as a USD value in string format
 * @example '35.9439393'
 */
export function impactFromSrcAndDestAmounts({ srcUsd, destUsd }: {srcUsd: string, destUsd: string})
: number {
  // Since USD values being traded over the swap are within the range of normal JS numbers, the
  // string values can simply be parsed.
  const remainder = (parseFloat(destUsd) / parseFloat(srcUsd)) * 100
  return 100 - remainder
}
