import cl from 'classnames'
import React, { FC, PropsWithChildren } from 'react'

import styles from './AmountInput.module.scss'

/**
 * Format an input string with dots instead of commas
 * @returns An amount with comma inputs converted to full stops in orer to have the decimals
 * formatted in the english locale format which is used throughout the app
 */
function englishDecimals(amount: string) {
  return amount.replace(',', '.')
}

/**
 * The allowed pattern for amount inputs
 */
// `^` from the start of the string `$` to the end of the string, you can have for the integer
// either zero, or any number 1-9 followed by other digits. Then for the decimal value all
// digits are allowed, but also the decimal value can be omitted. The regex also allows empty
// strings (ending `?`)
const pattern = /^(?:(?:0|[1-9]\d*)(?:\.\d*)?|\.\d+)$/

/**
 * Determines whether the amount is a valid input
 * @param amount the user input as a string
 * @param from the token being swapped from
 */
function isValidInput(amount: string, decimals: number): boolean {
  // this check needs to be made here in order not to pass an empty string to the `TokenAmount`
  if (amount.length === 0) {
    return true
  }

  if (!pattern.test(amount)) {
    return false
  }

  const inputDecimals = amount.split('.')[1]?.length ?? 0
  // Ensure that the user input doesn't exceed the maximum precision of the inputToken
  // even if the amount is smaller than the owned amount, this would cause the swap to
  // fail
  return inputDecimals <= decimals
}

type Props = PropsWithChildren & {
  /**
   * The input value
   */
  value: string
  /**
   * The maximum number of decimals the input can have
   */
  maxDecimals: number
  /**
   * Whether to highlight the input
   */
  highlight: boolean
  /**
   * Updates the input amount
   */
  onChange: (amount: string) => void
  /**
   * Whether the input is disabled or not
   */
  disabled?: boolean
}

/**
 * A UI component which allows the user to input a token amount
 */
export const EditableAmount: FC<Props> = props => (
  <label className={cl(styles['input'], props.highlight ? styles['input--highlight'] : '')}>
    <p className={cl(styles['label'], props.highlight ? styles['label--highlight'] : '')}>
      Amount
    </p>
    <input className={cl(styles['amount'], props.disabled ? styles['amount--disabled'] : '')}
      required type='text' inputMode='decimal' aria-label='swap in amount' value={props.value}
      disabled={props.disabled} placeholder={props.disabled ? 'Select tokens' : '0.0'}
      onChange={e => {
        const englishAmount = englishDecimals(e.currentTarget.value)
        if (isValidInput(englishAmount, props.maxDecimals)) {
          props.onChange(englishAmount)
        }
      }}
    />
    {props.children}
  </label>
)
