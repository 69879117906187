import React, { FC, ReactNode } from 'react'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { InputType, ListItem } from '../../../../hooks/useTokensList'
import { useInputLists } from '../../hooks/useInputLists'
import { setSelectToken } from '../../store/multiChainSlice'
import { DropdownInput } from './DropdownInput'
import { Info } from '../../../../elements/Info'
import { useMinMaxInputs } from '../../hooks/useMinMaxInputs'

import styles from './Inputs.module.scss'

type Props = {
  /**
   * If the selector is set to be open, whether it should display the values to swap 'from' or to
   * swap 'into'
   */
  type: InputType
  /**
   * The UI component for displaying and inputting amounts: this is different depending on whether
   * it is a `to` or `from` token InputsGroup, i.e. whether the user should be able to input a
   * value or whether the amount should be display-only
   */
  amountInput: ReactNode
  /**
   * The token which is selected in the input group
   */
  token?: ListItem
}

/**
 * A set of UI input components to be used for both pay in and pay out inputs
 *
 * These comprise of a blockchain, token and amount input field.
 */
export const InputsGroup: FC<Props> = props => {
  const dispatch = useAppDispatch()
  const state = useAppSelector(state => state.multiChain)
  const warnings = state.warnings.filter(warning => {
    if (props.type === 'from') {
      return warning.type === 'input'
    } else if (props.type === 'to') {
      return warning.type === 'output'
    }
  })
  const { tokens } = useInputLists({ type: props.type })

  useMinMaxInputs()

  return (
    <div>
      <div className={styles['input-group']}>
        <DropdownInput name='token' type={props.type} selected={props.token} options={tokens}
          onSelect={(type, item) => dispatch(setSelectToken({ type, item })) }/>
        {props.amountInput}
      </div>
      {state.inputRange && props.type === 'from' && (
        <p className={styles['input__subtext']}>
          Min. is: {state.inputRange.min}, Max. is: {state.inputRange.max}
        </p>
      )}
      {warnings.map(warning => <Info key={warning.code} type='warning'>{warning.message}</Info>)}
    </div>
  )
}
