import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../hooks/useAppSelector'
import { useAppDispatch } from '../hooks/useAppDispatch'
import { toggleNetworkSelector as toggleSingleNetworkSelector } from '../store/singleChainSlice'
import {
  toggleNetworkSelector as toggleMultiChainSelector,
} from '../features/multichain-swap/store/multiChainSlice'
import { NetworkSymbol } from '../configs/networks'
import { ListHeader } from '../elements/ListHeader/ListHeader'
import { ModalBase } from '../elements/ModalBase/ModalBase'
import { ConfigsState } from '../store/configsSlice'
import { setNetwork } from './multichain-swap/store/multiChainSlice'

import CHECKED_CIRCLE from '../assets/images/checked-circle.svg'

import styles from './NetworkSelector.module.scss'

/**
 * UI component to select a network for the single-chain swap
 */
export const SingleChainNetworkSelector: FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const networks = useAppSelector(state => state.configs)
  const selectedNetwork = useAppSelector(state => state.singleChain.network.symbol)

  /**
   * Handles closing the network selector
   */
  function closeSelector() {
    dispatch(toggleSingleNetworkSelector())
  }

  /**
   * Handles when a network is selected
   * @param network the network which the user has clicked
   */
  function handleSelect(network: NetworkSymbol) {
    navigate(network)
    closeSelector()
  }

  return <NetworkSelectorView selectedNetwork={selectedNetwork} networks={networks}
    handleSelect={handleSelect} closeSelector={closeSelector} />
}

/**
 * UI component to select a network for the cross-chain swap
 */
export const MultiChainNetworkSelector: FC = () => {
  const dispatch = useAppDispatch()
  const networks = useAppSelector(state => state.configs)
  const selectedNetwork = useAppSelector(state => state.multiChain.network)

  // Filter the networks we connect to for multichain swap
  const fiteredNetworks: ConfigsState = {
    BNB: networks.BNB,
    ETH: networks.ETH,
    POL: networks.POL,
    AVAX: networks.AVAX,
    EOS: networks.EOS,
    WAX: networks.WAX,
  }

  /**
   * Handles closing the network selector
   */
  function closeSelector() {
    dispatch(toggleMultiChainSelector())
  }

  /**
   * Handles when a network is selected
   * @param network the network which the user has clicked
   */
  function handleSelect(network: NetworkSymbol) {
    dispatch(setNetwork(network))
    closeSelector()
  }

  return <NetworkSelectorView selectedNetwork={selectedNetwork} networks={fiteredNetworks}
    handleSelect={handleSelect} closeSelector={closeSelector} />
}

type Props = {
  /**
   * The id number of the currently selected network
   * @example '1'
   */
  selectedNetwork: string
  /**
   * Every available network and its config
   */
  networks: ConfigsState
  /**
   * A function to select a network
   */
  handleSelect: (name: NetworkSymbol) => void
  /**
   * A function to close the selector
   */
  closeSelector: () => void

}

/**
 * A dumb UI component for selecting networks
 */
const NetworkSelectorView: FC<Props> = props => (
  <ModalBase closeModal={props.closeSelector}>
    <ul className={styles['networks']} aria-label='network selector'>
      <ListHeader closeList={props.closeSelector} className='header--desktop-hidden'>
          Please select a network
      </ListHeader>
      {Object.values(props.networks).map(network => {
        return (
          <li key={network.id} className={styles['list-item']}>
            <button className={styles['network']} onClick={() => props.handleSelect(network.name)}>
              <img src={network.icon} alt=''/>
              {network.displayName}
              {network.name === props.selectedNetwork && <img src={CHECKED_CIRCLE} alt='selected'/>}
            </button>
          </li>)
      })}
    </ul>
  </ModalBase>
)
