import React, { FC } from 'react'
import { createBrowserRouter, Navigate, RouteObject, RouterProvider } from 'react-router-dom'
import { Root } from './views/NetworkView'
import { ErrorView } from './views/ErrorView'
import { EosInitialiser } from './views/initialisers/EosInitialiser'
import { WaxInitialiser } from './views/initialisers/WaxInitialiser'
import { EvmInitialiser } from './views/initialisers/EvmInitialiser'
import { Network } from './configs/networks'
import { TransactionStatus } from './views/TransactionStatus'
import { MultiChainTransactionStatus } from './features/multichain-swap/views/TransactionStatus'
import { MoonPay } from './features/MoonPay/MoonPay'
import { features } from './feature-flags/feature-flags'
import { MultiChainInitialiser } from './views/initialisers/MultiChainInitialiser'
import { NetworkWrapper } from './features/multichain-swap/features/Connector/NetworkWrapper'
import { Preview } from './features/multichain-swap/views/Preview'
import { ChooseInputs } from './features/multichain-swap/views/ChooseInputs'
import { EosSwap, EvmSwap, WaxSwap } from './views/initialisers/NetworkStateProviders'
import { TransactionHistory } from './features/multichain-swap/views/TransactionHistory'
import { Partner } from './features/multichain-swap/features/Partner/Partner'

import './App.scss'

const routes: RouteObject = {
  path: '/',
  element: <Root />,
  errorElement: <ErrorView />,
  children: [
    {
      // An index route is like a default route, i.e. it will render ath the parents route. In
      // this case this means the default displayed swap chain is WAX.
      index: true,
      element: <WaxInitialiser>
        <WaxSwap swapType='single-chain'/>
        <MoonPay />
      </WaxInitialiser>,
    },
    {
      path: Network.EOS,
      element: <EosInitialiser>
        <EosSwap swapType='single-chain'/>
        <MoonPay />
      </EosInitialiser>,
    },
    {
      path: Network.WAX,
      element: <WaxInitialiser>
        <WaxSwap swapType='single-chain'/>
        <MoonPay />
      </WaxInitialiser>,
    },
    {
      path: Network.ETH,
      element: <EvmInitialiser network={Network.ETH}>
        <EvmSwap network={Network.ETH} swapType='single-chain'/>
        <MoonPay />
      </EvmInitialiser>,
    },
    {
      path: Network.POL,
      element: <EvmInitialiser network={Network.POL}>
        <EvmSwap network={Network.POL} swapType='single-chain'/>
        <MoonPay />
      </EvmInitialiser>,
    },
    // handles the obsolete route for Polygon based on the old system token symbol
    {
      path: 'MATIC',
      element: <Navigate to={`/${Network.POL}`}/>,
    },
    {
      path: Network.AVAX,
      element: <EvmInitialiser network={Network.AVAX}>
        <EvmSwap network={Network.AVAX} swapType='single-chain'/>
        <MoonPay />
      </EvmInitialiser>,
    },
    {
      path: Network.BNB,
      element: <EvmInitialiser network={Network.BNB}>
        <EvmSwap network={Network.BNB} swapType='single-chain'/>
        <MoonPay />
      </EvmInitialiser>,
    },
    {
      path: 'transaction',
      element: <TransactionStatus/>,
    },
    {
      path: 'cross-chain',
      element: <MultiChainInitialiser />,
      children: [
        {
          index: true,
          element: <NetworkWrapper source='user-selection'>
            <ChooseInputs/>
            <Partner/>
          </NetworkWrapper>,
        },
        {
          path: 'preview',
          element: <NetworkWrapper source='input-token'>
            <Preview/>
            <Partner/>
          </NetworkWrapper>,
        },
        {
          path: 'transaction',
          element: <MultiChainTransactionStatus/>,
        },
        {
          path: 'history',
          element: features.isCrossChainSwapHistoryEnabled() ? (
            <NetworkWrapper source='user-selection'>
              <TransactionHistory />
              <Partner/>
            </NetworkWrapper>
          ) : null,
        },
      ],
    },
  ],
}

/**
 * The router for the application
 */
const router = createBrowserRouter([routes])

export const App: FC = () => (
  <section className='container'>
    <RouterProvider router={router} />
  </section>
)
