import { ScanService } from '../services/evm/gas-prices'
import { OwnedToken, SupportedToken } from '../store/processRawTokenData'

import icons from '../assets/images/network-icons'

/**
 * All available networks
 */
export enum Network {
  EOS = 'EOS',
  WAX = 'WAX',
  ETH = 'ETH',
  POL = 'POL',
  AVAX = 'AVAX',
  BNB = 'BNB'
}

export type DisplayName = 'Ethereum' | 'Avalanche' | 'BNB' | 'EOS' | 'WAX' | 'Polygon'

export const evmNetworkNames: DisplayName[] = ['Avalanche', 'BNB', 'Ethereum', 'Polygon']
const eosNetworkNames: DisplayName[] = ['EOS', 'WAX']

export const supportedNetworks: DisplayName[] = evmNetworkNames.concat(eosNetworkNames)

/**
 * The id of a network on https://www.coingecko.com/
 */
export type CoinGeckoId = 'matic-network' | 'binancecoin' | 'ethereum' | 'avalanche-2' | 'eos' | 'wax'

/**
 * Config data necessary for working with any network
 */
export type SharedNetworkConfig = {
  /**
   * The id of the network
   */
  id: string
  /**
   * The network name for display in the UI. This can overlap with the {@link NetworkSymbol} e.g.
   * 'BNB' but can be different e.g. 'Ethereum'
   */
  displayName: DisplayName
  /**
   * The main/default token of the network
   */
  mainTokenZeroBalance: OwnedToken
  /**
   * The tokens which are available to swap into on the network
   */
  // TODO - change to OwnedToken array
  availableTokens: SupportedToken[] // OwnedToken[]
  /**
   * The percentage fee that the referrer address takes for a swap on a given chain when it is
   * submitted to the 1inch api. This percentage is subtracted from the input amount for the swap.
   *
   * Min: 0, max: 3, default: 0. e.g. a fee of 1.5%: fee=1.5
   */
  feePercentage: number
  /**
   * The id for the token to use for the CoinGecko api in order to retrieve its USD rate
   * docs: https://www.coingecko.com/en/api/documentation
   * @example 'binancecoin'
   */
  coinGeckoId: CoinGeckoId
  /**
   * The icon of the network
   */
  icon: string
  /**
   * {@link ScanName} and api key for the service which is used for monitoring the network
   */
  scanService: ScanService
}

/**
 * The various configuration types for different networks
 */
export enum ConfigType {
  EOSIO, EVM
}

/**
 * The symbols of all available EVM networks
 */
export type EvmNetworkSymbol = Network.AVAX | Network.BNB | Network.ETH | Network.POL

export const evmNetworkSymbols: EvmNetworkSymbol[] = [
  Network.AVAX, Network.BNB, Network.ETH, Network.POL,
]

export type EvmNetworkConfig = SharedNetworkConfig & {
  /**
   * The configuration type the network belongs to
   */
  type: ConfigType.EVM
  /**
   * A symbol identifying the network internally and for routes
   */
  name: EvmNetworkSymbol
}

export const EVM_SYSTEM_TOKEN_ADDRESS = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'

/**
 * The config data for all EVM token networks
 */
export const evmNetworks: Record<EvmNetworkSymbol, EvmNetworkConfig> = {
  AVAX: {
    type: ConfigType.EVM,
    name: Network.AVAX,
    displayName: 'Avalanche',
    id: process.env.AVAX_SUPPORTED_NETWORK ?? '43114',
    availableTokens: [],
    mainTokenZeroBalance: { symbol: 'AVAX', balance: '0000000000000000000', decimals: 18, name: 'Avalanche',
    // Allowance is 'SET' by default for the chain main tokens before the data has been loaded
      allowance: 'SET', address: EVM_SYSTEM_TOKEN_ADDRESS, iconUrl: icons.avax },
    feePercentage: 0.5,
    scanService: {
      name: 'snowtrace',
      apiKey: process.env.AVAXSCAN_API_KEY ?? '',
    },
    coinGeckoId: 'avalanche-2',
    icon: icons.avax,
  },
  BNB: {
    type: ConfigType.EVM,
    name: Network.BNB,
    displayName: 'BNB',
    id: process.env.BNB_SUPPORTED_NETWORK ?? '56',
    availableTokens: [],
    mainTokenZeroBalance: { symbol: 'BNB', balance: '0000000000000000000', decimals: 18, name: 'BNB',
    // Allowance is 'SET' by default for the chain main tokens before the data has been loaded
      allowance: 'SET', address: EVM_SYSTEM_TOKEN_ADDRESS, iconUrl: icons.bnb },
    feePercentage: 0.5,
    scanService: {
      name: 'bscscan',
      apiKey: process.env.BSCSCAN_API_KEY ?? '',
    },
    coinGeckoId: 'binancecoin',
    icon: icons.bnb,
  },
  ETH: {
    type: ConfigType.EVM,
    name: Network.ETH,
    displayName: 'Ethereum',
    id: process.env.ETH_SUPPORTED_NETWORK ?? '1',
    availableTokens: [],
    mainTokenZeroBalance: { symbol: 'ETH', balance: '0000000000000000000', decimals: 18, name: 'Ethereum',
    // Allowance is 'SET' by default for the chain main tokens before the data has been loaded
      allowance: 'SET', address: EVM_SYSTEM_TOKEN_ADDRESS, iconUrl: icons.eth },
    feePercentage: 0.5,
    scanService: {
      name: 'etherscan',
      apiKey: process.env.ETHERSCAN_API_KEY ?? '',
    },
    coinGeckoId: 'ethereum',
    icon: icons.eth,
  },
  POL: {
    type: ConfigType.EVM,
    name: Network.POL,
    displayName: 'Polygon',
    id: process.env.MATIC_SUPPORTED_NETWORK ?? '137',
    availableTokens: [],
    mainTokenZeroBalance: { symbol: 'POL', balance: '0000000000000000000', decimals: 18,
    // Allowance is 'SET' by default for the chain main tokens before the data has been loaded
      allowance: 'SET', name: 'POL', address: EVM_SYSTEM_TOKEN_ADDRESS,
      iconUrl: icons.pol },
    feePercentage: 0.5,
    scanService: {
      name: 'polygonscan',
      apiKey: process.env.POLYGONSCAN_API_KEY ?? '',
    },
    coinGeckoId: 'matic-network',
    icon: icons.pol,
  },
}

/**
 * The symbols of all available EOSIO networks
 */
export type EosIoNetworkSymbol = Network.EOS | Network.WAX

/**
 * The name of the EOSIO contract used for making the swap
 */
export type Contract = 'swap.defi' | 'swap.alcor'

/**
 * The symbols of all available EosIo networks
 */
export const eosIoNetworkSymbols: EosIoNetworkSymbol[] = [Network.WAX, Network.EOS]

/**
 * Config data necessary for working with an EosIo network
 */
export type EosIoNetworkConfig = SharedNetworkConfig & {
  /**
   * The configuration type the network belongs to
   */
  type: ConfigType.EOSIO
  /**
   * A symbol identifying the network internally and for routes
   */
  name: EosIoNetworkSymbol
  /**
   * The URL of an EOSIO RPC node being used for the network
   */
  node: string
  /**
   * The contract used for making swaps on the network
   */
  contract: Contract
}

/**
 * The config data for all EosIo token networks
 */
export const eosIoNetworks: Record<EosIoNetworkSymbol, EosIoNetworkConfig> = {
  EOS: {
    type: ConfigType.EOSIO,
    name: Network.EOS,
    displayName: 'EOS',
    id: 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906',
    availableTokens: [],
    mainTokenZeroBalance: { symbol: 'EOS', balance: '0.000', decimals: 4, name: 'EOS', address: 'eosio.token',
    // Allowance is 'SET' by default for EOSIO tokens
      allowance: 'SET', pairIndex: '177', iconUrl: icons.eos },
    feePercentage: 0.5,
    scanService: {
      name: 'bloks',
      apiKey: '',
    },
    node: 'https://eos-rpc.wombat.app',
    contract: 'swap.defi',
    coinGeckoId: 'eos',
    icon: icons.eos,
  },
  WAX: {
    type: ConfigType.EOSIO,
    name: Network.WAX,
    displayName: 'WAX',
    id: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
    availableTokens: [],
    mainTokenZeroBalance: { symbol: 'WAX', balance: '0.0000000', decimals: 8, name: 'WAX', address: 'eosio.token',
    // Allowance is 'SET' by default for EOSIO tokens
      allowance: 'SET', pairIndex: '177', iconUrl: icons.wax },
    feePercentage: 0.5,
    scanService: {
      name: 'wax.bloks',
      apiKey: '',
    },
    node: 'https://wax-rpc.wombat.app',
    contract: 'swap.alcor',
    coinGeckoId: 'wax',
    icon: icons.wax,
  },
}

/**
 * The names of all available networks
 */
export type NetworkSymbol = EvmNetworkSymbol | EosIoNetworkSymbol

/**
 * The configs for working with all available token networks
 */
export const networksConfig = { ...eosIoNetworks, ...evmNetworks }
