import { DisplayName, Network } from '../../../configs/networks'

/**
 * A list of chains returned from Changelly which are available to swap from
 */
export const allowedChangellyChains = ['eos', 'WAX', 'polygon', 'binance_smart_chain', 'ethereum', 'avaxc']

export type AllowedChains = 'eos' | 'WAX' | 'polygon' | 'binance_smart_chain' | 'ethereum' | 'avaxc'

type NetworkIdentifiers = {
  /**
   * A symbol used to identify the network
   * @example 'ETH', 'EOS', 'POL'
   */
  symbol: Network
  /**
   * The network name in display format
   * @example 'Ethereum', 'EOS, 'Polygon'
   */
  displayName: DisplayName
}

/**
 * A map of changelly blockchain property names to networks supported by the token swap
 */
export const changellyNetworkMap: Record<AllowedChains, NetworkIdentifiers> = {
  eos: { symbol: Network.EOS, displayName: 'EOS' },
  ethereum: { symbol: Network.ETH, displayName: 'Ethereum' },
  WAX: { symbol: Network.WAX, displayName: 'WAX' },
  polygon: { symbol: Network.POL, displayName: 'Polygon' },
  avaxc: { symbol: Network.AVAX, displayName: 'Avalanche' },
  binance_smart_chain: { symbol: Network.BNB, displayName: 'BNB' },
}
