import React, { FC, PropsWithChildren, ReactNode, useRef, useState } from 'react'
import { TokenWithAmount, useTokenInputs } from '../../hooks/useTokenInputs'
import { Selector } from './Selector'
import { useOutsideClick } from '../../hooks/useOutsideClick'
import { InputType, useTokensList } from '../../hooks/useTokensList'

import CHEVRON_DOWN from '../../assets/images/triangle-down.svg'
import TOKEN from '../../assets/images/token.svg'

import styles from './InputsGroup.module.scss'

type Props = {
  /**
   * If the selector is set to be open, whether it should display the values to swap 'from' or to
   * swap 'into'
   */
  inputType: InputType
  /**
   * The UI component for displaying and inputting amounts: this is different depending on whether
   * it is a `to` or `from` token InputsGroup, i.e. whether the user should be able to input a
   * value or whether the amount should be display-only
   */
  amountInput: ReactNode
  /**
   * The selected token input, if present
   */
  selected?: TokenWithAmount
}

/**
 * A visual component to display token inputs to a swap, i.e. an identifier for the token and the
 * selected token amount.
 * It can be used to display both the token being swapped `from` and `to`
 */
export const TokenInputs: FC<PropsWithChildren<Props>> = props => {
  const [ selectorOpen, setSelectorOpen ] = useState(false)
  const { tokens, preselected } = useTokensList(props.inputType)
  const { updateUrlTokens } = useTokenInputs()
  const listRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)

  // Handles clicks outside of the provided refs
  useOutsideClick({ refs: [listRef, buttonRef], handleClick: () => setSelectorOpen(false) })

  return (
    <div className={styles['input-group']}>
      <div className={styles['inputs']}>
        <button ref={buttonRef} className={styles['token-select']} onClick={() => setSelectorOpen(!selectorOpen)}>
          <p className={styles['label']}>Swap {props.inputType}</p>
          <div className={styles['token']}>
            {props.selected?.iconUrl && (
              <img src={props.selected.iconUrl} className={styles['icon']} alt=''
                onError={e => e.currentTarget.src = TOKEN}/>
            )}
            <p>{props.selected?.symbol ?? ''}</p>
          </div>
          <img className={styles[`icon${selectorOpen ? '--rotated' : '--unrotated' }`]}
            src={CHEVRON_DOWN} alt=''/>
        </button>
        {props.amountInput}
      </div>
      {selectorOpen &&
        <div ref={listRef}>
          <Selector name='token' items={tokens} preselected={preselected} type={props.inputType}
            onSelect={(type, item) => updateUrlTokens(type, item.symbol)}
            closeSelector={() => setSelectorOpen(false)}/>
        </div>}
      {props.children}
    </div>
  )
}
