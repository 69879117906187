import React, { FC } from 'react'
import { useTokenInputs, TokenWithAmount } from '../hooks/useTokenInputs'
import { TokenInputs } from '../features/TokenInputs/InputsGroup'
import { InputAmount } from '../features/TokenInputs/InputAmount'
import { OpenPreviewCta } from '../features/TxPreview/OpenPreviewCta'
import { TransactionDetails } from '../features/TxInformation/TransactionDetails'
import { ReverseCurrencies } from '../elements/ReverseTokens'
import { InputTokenDetails } from '../elements/AmountInputs/InputAmountDetails'
import { OutputAmount } from '../features/TokenInputs/OutputAmount'

/**
 * A component consisting of the inputs for choosing input tokens and amounts
 */
export const ChooseInputs: FC = () => {
  const { from, to, reverseTokens } = useTokenInputs()

  return <ChooseInputsView inputToken={from} outputToken={to} reverseTokens={reverseTokens}/>
}

type Props = {
  /**
   * Data to display the selected input token
   */
  inputToken: TokenWithAmount
  /**
   * Data to display the selected output token, if present
   */
  outputToken?: TokenWithAmount
  /**
   * Function to switch the input and output tokens
   */
  reverseTokens: () => void
}

/**
 * The dumb view component displaying the input tokens and amounts
 */
const ChooseInputsView: FC<Props> = props => (
  <>
    <TokenInputs inputType='from' selected={props.inputToken} amountInput={<InputAmount/>}>
      <InputTokenDetails selected={props.inputToken}/>
    </TokenInputs>
    <ReverseCurrencies onClick={props.reverseTokens}/>
    <TokenInputs inputType='to' selected={props.outputToken} amountInput={<OutputAmount/>}/>
    <TransactionDetails/>
    <OpenPreviewCta from={props.inputToken} to={props.outputToken}/>
  </>
)
