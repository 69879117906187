import { type TransactionDetail } from './TransactionDetails'
import { DISPLAY_DECIMALS, TokenAmount } from '../../numbers/TokenAmount'

/**
 * Calculates the exchange rate for the tokens being swapped
 *
 * @param from the reserve of the token being input to the swap
 * @param to the reserve of the token being output from the swap
 * @returns the value of the token being swapped `to` in terms of the `from` token, i.e. the amount
 * of `to` token that 1 `from` token is worth
 */
export function calculateRate(from: TokenAmount, to: TokenAmount): TokenAmount {
  return to.div(from)
}

/**
 * Estimates the new exchange rate of the tokens being exchanged, after a swap has been made
 *
 * @param reserveFrom the size of the `from` token liquidity pool
 * @param reserveTo the size of the `to` token liquidity pool
 * @param from the {@link TokenAmount} of the `from` token being input to the swap
 * @param to the {@link TokenAmount} of the `to` token being output from the swap
 * @returns an approximate new exchange rate based on the swap's impact on liquidity pools
 */
export function calculateRateAfterSwap(reserveFrom: TokenAmount, reserveTo: TokenAmount,
  from: TokenAmount, to: TokenAmount): TokenAmount {
  const finalReserveFrom = reserveFrom.sub(from)
  const finalReserveTo = reserveTo.add(to)
  return finalReserveTo.div(finalReserveFrom)
}

/**
 * Data to describe the token exchange rate
 */
type RateArgs = {
  /**
   * The symbol of the token being swapped `from`
   * @example 'EOS'
   */
  fromSymbol: string
  /**
   * The exchange rate for the token being swapped `to` for one `from` token. This can be either a
   * {@link TokenAmount}, or a string if an API returns the exchange rate as a string.
   * @example 'WOMBAT'
   */
  rate: TokenAmount | string | number
  /**
   * The symbol of the token being swapped `to`
   * @example 'WAX'
   */
  toSymbol: string
}

/**
 * Creates an information object for showing the rate of the swap in the UI
 */
export function rateInformation(args: RateArgs): TransactionDetail {
  if (typeof args.rate === 'string' || typeof args.rate === 'number') {
    let rate = args.rate
    if (typeof args.rate === 'number') {
      rate = args.rate.toFixed(DISPLAY_DECIMALS)
    }
    return {
      label: 'Rate',
      value: `1 ${args.fromSymbol} ≈ ${rate} ${args.toSymbol}`,
      highlight: false,
    }
  }

  return {
    label: 'Rate',
    value: `1 ${args.fromSymbol} ≈ ${args.rate.display(true, DISPLAY_DECIMALS)} ${args.rate.symbol}`,
    highlight: false,
  }
}

type Impact = {
  /**
   * The amount returned for the detail
   * This can be returned as a number or string by the apis used and is standardised here
   * @example '23%', 0.09994302032
   */
  value: string | number
  /**
   * Whether the value should be highlighted in the UI
   */
  highlight: boolean
}

/**
 * Creates an information object for showing the price impact of the swap in the UI
 * @param args the {@link Impact} for the price impact
 */
export function priceImpactInformation(args: Impact): TransactionDetail {
  let impact = args.value
  if (typeof args.value === 'number') {
    impact = args.value.toFixed(2)
  }
  if (typeof args.value === 'string') {
    impact = args.value.replace('%', '')
  }
  return {
    label: 'Price impact',
    value: `${impact}%`,
    highlight: args.highlight,
  }
}

/**
 * Data for displaying the transaction fees
 */
export type FeeArgs = {
  /**
   * The {@link TokenAmount} gas fee - this is in the chain main token
   */
  gas: { amount: TokenAmount, type: 'token' } | {
    type: 'string', amount: { value: string, symbol: string}
  }
  /**
   * The {@link TokenAmount} referrer fee - this is in the input main token
   */
  referrer?: TokenAmount
  /**
   * A commission which might be taken by a service being used to execute the swap, e.g. Changelly
   * This is a floating point number in string format in terms of the payout token.
   * @example '0.000264334869739478'
   */
  serviceFee?: string
  /**
   * The gas fee in USD
   * @example '0.18'
   */
  usdGasFee?: string
  /**
   * The referrer fee in USD
   * @example '0.58'
   */
  usdReferrerFee?: string
  /**
   * A token for which the fee should be highlighted
   */
  highlight?: TokenAmount
}

/**
 * Creates an information object for showing the fees for a swap in the UI
 */
export function feeInformation(args: FeeArgs): TransactionDetail[] {
  let scaledAmountGas
  if (args.gas.type === 'string') {
    scaledAmountGas = Number(args.gas.amount.value)
  } else {
    scaledAmountGas = args.gas.amount.display(true, args.gas.amount.decimals)
  }

  const gasUsd = args.usdGasFee !== undefined ? ` ≈ ${args.usdGasFee} USD` : ''

  const fees = []

  if (args.referrer) {
    const scaledAmountReferrer = args.referrer.display(true, args.referrer.decimals)
    const referrerUsd = args.usdReferrerFee !== undefined ? ` ≈ ${args.usdReferrerFee} USD` : ''

    fees.push(
      {
        label: 'Referrer fee',
        value: `${scaledAmountReferrer} ${args.referrer.symbol}${referrerUsd}`,
        highlight: args.highlight?.symbol === args.referrer.symbol ? true : false,
      })
  }

  if (args.serviceFee) {
    fees.push({
      label: 'Service fee',
      value: 'Service fee has to be calculated',
      highlight: args.highlight?.symbol === args.gas.amount.symbol ? true : false,
    })
  }

  return fees.concat([{
    label: 'Gas fee',
    value: `${scaledAmountGas} ${args.gas.amount.symbol}${gasUsd}`,
    highlight: args.highlight?.symbol === args.gas.amount.symbol ? true : false,
  }])
}

type Args = {
  /**
   * Data for displaying the rate at which the transaction is made
   */
  rate: RateArgs
  /**
   * Data for displaying fees for the transaction
   */
  fees?: FeeArgs
  /**
   * Data for displaying price impact for the transaction, where the price impact is significant
   */
  priceImpact?: Omit<Impact, 'value'> & { value?: string | number }
}

/**
 * Prepare details for a transaction which should be displayed in the UI
 *
 * @returns details about the transaction which can be listed directly in the UI
 */
export function formatTransactionDetails({ rate, fees, priceImpact }: Args): TransactionDetail[] {
  let details = [rateInformation(rate)]
  if (fees) {
    details = details.concat(feeInformation(fees))
  }
  if (priceImpact?.value !== undefined) {
    details.push(priceImpactInformation(priceImpact as Impact))
  }
  return details
}
