.networks {
  position: absolute;
  padding: 0;
  margin: 0;
  background-color: var(--colorSurfaceDark);
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100vw;
  
  @media (min-width: 768px) {
    bottom: unset;
    right: calc(50% - 73px);
    top: 150px;
    left: unset;
    width: unset;
    border-radius: 24px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.30), 0px 8px 12px 6px rgba(0, 0, 0, 0.15);
  }
}

.list-item {
  padding: 16px 24px;

  @media (min-width: 768px) {
    width: 200px;
  }
}

.network {
  display: flex;
  gap: 8px;
  width: 100%;
}
