@import '../../assets/styles/_variables';

// a transparent background layer in order to block the UI underneath a modal networks list
.blocking-layer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  z-index: 1;
  background-color: var(--modalBackgroundOverlay);

  @media (min-width: 768px) {
    background-color: transparent;
  }

  &--opaque {
    @media (min-width: 768px) {
      background-color: var(--modalBackgroundOverlay);
    }
  }
}

.modal-content {
  margin: -16px;
  z-index: 2;
}

.partial-modal {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 16px 32px 32px 32px;
  background: var(--colorSurfaceDark);
  border-radius: 32px 32px 0px 0px;
  border: 1px solid var(--divider-primary, rgba(255, 255, 255, 0.20));
  width: calc(100% - 64px);

  @media (min-width: 768px) {
    top: calc(50% - 200px);
    bottom: unset;
    left: calc(50% - 236px);
    border-radius: 32px;
    width: 408px;
    z-index: 1;
    padding: 32px;
  }
}

