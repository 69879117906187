import { WaxJS } from '@waxio/waxjs/dist'

/**
 * Basic information about the user's account
 */
export type AccountData = {
  /**
   * The eosio network's account name of the user
   */
  accountName: string
  /**
   * The public key of the users network's account (for the active permission)
   */
  publicKeys: string[]
}

/**
 * The Scatter chainId for EOS
 */
export const EOS_CHAIN_ID = 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906'

/**
 * The scatter chain ID for WAX
 */
export const WAX_CHAIN_ID = '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4'

type SupportedChainIds = typeof EOS_CHAIN_ID | typeof WAX_CHAIN_ID

/**
 * Gets the user's scatter account for a given chain
 *
 * Scatter is used to interact with accounts on WAX and EOS
 * @param chainId The id number of the network
 * eg. WAX_CHAIN_ID = '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4'
 */
export async function getScatterAccount(chainId: SupportedChainIds): Promise<AccountData> {
  if (!window.scatter) {
    throw new Error('Scatter not available')
  }
  const identity = await window.scatter.getIdentity({ accounts: [{ chainId }] })
  return ({
    accountName: identity.name,
    publicKeys: [identity.publicKey],
  })
}

const WAX_NODE = process.env.WAX_NODE ?? 'https://wax-rpc.wombat.app'

// Initialize WaxJS instance with config for WAX
const waxJs = new WaxJS({
  rpcEndpoint: WAX_NODE,
  freeBandwidth: true,
  tryAutoLogin: false,
})

/**
 * Triggers the login for a user's Wax Cloud Wallet account
 */
export async function getWaxCloudWalletAccount(): Promise<AccountData> {
  await waxJs.login()
  return {
    accountName: waxJs.userAccount,
    publicKeys: waxJs.pubKeys,
  }
}
