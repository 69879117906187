import cl from 'classnames'
import React, { FC, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { setSelectToken } from '../store/multiChainSlice'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { useQuote } from '../hooks/useQuote'
import { ListItem } from '../../../hooks/useTokensList'
import { features } from '../../../feature-flags/feature-flags'
import { InputsGroup } from '../features/Inputs/InputsGroup'
import { InputAmount } from '../features/Inputs/InputAmount'
import { OutputAmount } from '../features/Inputs/OutputAmount'
import { Link } from '../features/Link/Link'
import { Button } from '../../../elements/Buttons/Button'
import { ReverseCurrencies } from '../../../elements/ReverseTokens'
import { InfoBox } from '../../../elements/InfoBox/InfoBox'
import { Recipient } from '../features/Inputs/Recipient'
import { PayoutMemo } from '../features/Inputs/PayoutMemo'

//TODO - remove
import styles from '../features/Inputs/Inputs.module.scss'
import viewStyles from '../../../views/Views.module.scss'

/**
 * UI component enabling a user to input the tokens and amounts they wish to exchange
 */
export const ChooseInputs: FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const state = useAppSelector(state => state.multiChain)

  useQuote()

  const errors = useMemo(() => {
    return state.warnings.filter(error => error.type === 'auth')
  }, [state.warnings])

  /**
   * Switch the input and output tokens
   * @param payIn The token being into to the swap
   * @param payOut The token being output from the swap
   */
  function reverseTokens(payIn?: ListItem, payOut?: ListItem) {
    if (payOut) {
      //TODO - check that is available as a from token
      dispatch(setSelectToken({ type: 'from', item: payOut }))
    }
    if (payIn) {
      //TODO - check that is available as a from token
      dispatch(setSelectToken({ type: 'to', item: payIn }))
    }
  }

  return (
    <div className={viewStyles['content']}>
      <div className={cl(viewStyles['container'], styles['inputs'])}>
        {errors.length > 0 ? (
          <>{errors.map(err => <InfoBox key={err.code} type='warning'>{err.message}</InfoBox>)}</>
        ) : null}
        <InputsGroup type='from' amountInput={<InputAmount/>} token={state.payinToken}/>
        <ReverseCurrencies
          onClick={() => reverseTokens(state.payinToken, state.payoutToken)}/>
        <InputsGroup type='to' amountInput={<OutputAmount/>} token={state.payoutToken}/>
        <Recipient/>
        <PayoutMemo/>
        <Button onClick={() => navigate('/cross-chain/preview')} disabled={!state.payinAmount
          || !state.payoutToken || !state.payinToken || !state.quote || !state.recipientAddress
          || (state.payoutId.required && !state.payoutId.value)}>
          Next
        </Button>
      </div>
      {features.isCrossChainSwapHistoryEnabled() && (
        <Link text='See previous transactions' onClick={() => navigate('/cross-chain/history')}/>)}
    </div>
  )
}
