import React, { FC, useMemo } from 'react'
import { Button } from '../elements/Buttons/Button'
import { useAppDispatch } from '../hooks/useAppDispatch'
import { setAllowance } from '../store/evmNetworksSlice'
import { InfoBox } from '../elements/InfoBox/InfoBox'
import { useAppSelector } from '../hooks/useAppSelector'
import { TxState, TxType } from '../store/transactionsSlice'
import { txLink } from '../views/TransactionStatus'

type Props = {
  /**
   * The address of the token which needs the allowance set
   */
  tokenAddress: string
}

/**
 * A UI component to allow the user to set a token allowance i.e. approve a token for use by the
 * swap contract, the first time they use the token
 */
export const SetAllowance: FC<Props> = props => {
  const dispatch = useAppDispatch()
  const pendingAllowances = useAppSelector(state => state.transactions)
    .filter(tx => (tx.type === TxType.SET_ALLOWANCE) && (tx.state === TxState.PENDING))
  const config = useAppSelector(state => state.configs)

  /**
   * A pending allowance transaction for the token address
   */
  const pendingTx = useMemo(() => {
    return pendingAllowances.find(tx => tx.tokenAddress === props.tokenAddress)
  }, [pendingAllowances, props.tokenAddress])

  if (pendingTx) {
    const link = txLink(config[pendingTx.network].scanService.name, pendingTx.hash)
    return (
      <>
        <InfoBox type='info'>
          <span>
            Waiting for your 'Unlock' transaction to be approved. Please check back in a bit.
            <br/>
            <a href={link} style={{ 'color': 'white' }}>You can check the status here</a>
          </span>
        </InfoBox>
        <Button disabled={true} onClick={() => {}}>
          Unlocking...
        </Button>
      </>
    )
  }

  return (
    <>
      <InfoBox type='info'>
        The first time you use a token you have to give us permission to trade it for you.
        <br/>
        This requires a transaction - please unlock the token and wait for the transaction to be
        approved.
      </InfoBox>
      <Button onClick={() => dispatch(setAllowance(props.tokenAddress))}>
        Unlock Token
      </Button>
    </>
  )
}
