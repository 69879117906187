import { useEffect } from 'react'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { useDebouncedCallback } from '../../../hooks/useDebouncedCallback'
import { validateAddress } from '../services/changelly/api'
import {
  clearWarnings, setLoading, setReceiverAddress, storeWarnings,
} from '../store/multiChainSlice'
import { multiChainSwapError } from '../../../store/warnings'

/**
 * The number of milliseconds to delay debouncing the api calls
 */
const DEBOUNCE_DELAY_MS = 1000

/**
 * Ensures a recipient address is valid for the output token before setting it in the state
 */
export function useHandleRecipientInput(address: string | undefined): void {
  const dispatch = useAppDispatch()
  const debounce = useDebouncedCallback(handleValidateAddress, DEBOUNCE_DELAY_MS)
  const payoutToken = useAppSelector(state => state.multiChain.payoutToken)

  useEffect(() => {
    debounce(address)
  }, [payoutToken, address])


  function handleValidateAddress(address: string | undefined) {
    if (address === '') {
      return dispatch(setReceiverAddress(undefined))
    }
    if (payoutToken && address) {
    // validate address
      dispatch(setLoading(true))
      dispatch(clearWarnings([multiChainSwapError.INVALID_RECEIVER]))
      validateAddress({ currency: payoutToken.id, address: address })
        .then(res => {
          if (('result' in res) && res.result.result === true) {
            dispatch(setReceiverAddress(address))
          } else {
            throw new Error('API response is invalid address')
          }
        })
        .catch(e => {
          console.error(e)
          dispatch(storeWarnings([multiChainSwapError.INVALID_RECEIVER]))
          dispatch(setReceiverAddress(undefined))
        })
        .finally(() => dispatch(setLoading(false)))
    }
  }
}
