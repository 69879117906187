@import '../../../../assets/styles/_variables';
@import '../../../../assets/styles/_typography';

.status {
  display: flex;
  justify-content: center;

  &-link {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    text-decoration: none;
    padding: 8px;
    color: var(--colorHighlight);
    @include text-normal(center, 600)
  }

  &-icon {
    height: 16px;
    width: 16px;
  }
}
