import cl from 'classnames'
import React, { FC, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { ManageConnection } from '../../../WalletConnection/ManageConnection'
import { NetworkManagementProps } from '../../../../views/initialisers/NetworkStateProviders'
import { ConnectWallet } from '../../../WalletConnection/ConnectWallet'
import { Navigation } from '../../../../elements/Navigation/Navigation'
import { toggleNetworkSelector } from '../../store/multiChainSlice'

import styles from './Header.module.scss'

/**
 * The paths of the swap landing pages
 */
const landingPages = ['/', '/cross-chain']

/**
 * Paths for which wallet connection and network switching functionalities are disabled
 */
const disabledActionsPaths = ['/cross-chain/preview']

/**
 * Header component for the cross-chain swap
 */
export const Header: FC<NetworkManagementProps> = props => {
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const [connectorOpen, setConnectorOpen] = useState(false)

  const isLandingPage: boolean = useMemo(() => {
    return landingPages.includes(pathname)
  }, [pathname])

  const readonly: boolean = useMemo(() => {
    if (disabledActionsPaths.includes(pathname)) {
      return true
    } else {
      return false
    }
  }, [pathname])

  function manageNetwork() {
    dispatch(toggleNetworkSelector())
  }

  return (
    <div className={cl(styles['header'], !isLandingPage ? styles['header--secondary'] : '')}>
      <Navigation pathname={pathname}/>
      <ManageConnection networkState={props.networkState} toggleNetworkSelector={manageNetwork}
        readonly={readonly} openWalletConnect={() => setConnectorOpen(true)}
        // TODO - this is a bit clunky, rething.
        isSecondaryHeader={!isLandingPage}/>
      {connectorOpen && (
        <ConnectWallet connectWombat={props.connectWombat}
          connectWaxCloudWallet={props.connectWaxCloudWallet}
          closeSelector={() => setConnectorOpen(false)}/>
      )}
    </div>
  )
}
