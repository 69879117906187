import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  EosIoNetworkConfig, EosIoNetworkSymbol, EvmNetworkConfig, Network, networksConfig,
} from '../configs/networks'
import { SupportedToken } from './processRawTokenData'
import { fetchTokens } from '../services/wombat/wombat-api'
import { convertWombatToSwapTokens } from '../services/wombat/convert-to-swap-tokens'
import { WombatSupportedTokens } from '../services/wombat/types'
import { features } from '../feature-flags/feature-flags'

/**
 * Returns the first functioning url from a list of nodes
 * @param nodeUrls a list of nodes to test
 * @returns the node which should be used for the network
 */
async function determineEosioNode(nodeUrls: string[]): Promise<string> {
  for (const node of nodeUrls) {
    try {
      await fetch(`${node}/v1/chain/get_info`)
      // Found a working node, return its URL
      return node
    } catch (e) {
      console.error(`${node} is not working, testing next node...`)
    }
  }
  throw new Error('No working EOSIO nodes found')
}

/**
 * Token config data for each of the available networks
 */
export type ConfigsState = {
  [Network.AVAX]: EvmNetworkConfig
  [Network.BNB]: EvmNetworkConfig
  [Network.ETH]: EvmNetworkConfig
  [Network.POL]: EvmNetworkConfig
  [Network.EOS]: EosIoNetworkConfig
  [Network.WAX]: EosIoNetworkConfig
}

/**
 * The state for configs when the app initialises
 */
const initialState: ConfigsState = networksConfig

/**
 * The token data for each available network
 */
export type SupportedNetworkTokens = Record<Network, SupportedToken[]>

const configsSlice = createSlice({
  name: 'configs',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(initialiseEosIoNodes.fulfilled, (state, action) => {
        const network = action.payload.network
        state[network].node = action.payload.node
        console.log(`${action.payload.network} node initialised`)
      })
      .addCase(initialiseEosIoNodes.rejected, (state, action) => {
        console.error('Error initialising node', action.error)
      })
      .addCase(setSupportedTokens.fulfilled, (state, action) => {
        Object.entries(action.payload).forEach(([key, value]) => {
          state[key as Network].availableTokens = value
        })
      })
      .addCase(setSupportedTokens.rejected, (state, action) => {
        console.error('Error fetching wombat supported tokens', action.error)
      })
  },
})

const disabledAddresses: string[] = []
const disabledSymbols: string[] = []

/**
 * Function to remove a token by its address and symbol from the supported Polygon tokens
 * @param supported The supported tokens
 * @returns the {@link WombatSupportedTokens} without the filtered tokens
 */
function disablePolygonToken(supported: WombatSupportedTokens) {
  // When a token has been disabled, we might want to enable it for certain environments, e.g. an
  // issue exists for the token in production, however it is necessary to enable on staging and
  // locally to fix the issue/for testing.
  if (features.isDisableTokensBypassed()) {
    return supported
  }

  const filteredPolygonErc20 = supported.polygon.erc20.filter(token => {
    return !((disabledAddresses.includes(token.address)
    && (disabledSymbols.includes(token.symbol))))
  })
  supported.polygon.erc20 = filteredPolygonErc20
  return supported
}

/**
 * Set the tokens which should be available in the dApp
 */
export const setSupportedTokens = createAsyncThunk<SupportedNetworkTokens, void>(
  'configs/tokens',
  async () => {
    const tokens = await fetchTokens()
    const allowedTokens = disablePolygonToken(tokens)
    return convertWombatToSwapTokens(allowedTokens)
  },
)

/**
 * Test available nodes for an EOSIO network and return a working node
 */
export const initialiseEosIoNodes = createAsyncThunk<
{ network: EosIoNetworkSymbol, node: string }, EosIoNetworkSymbol
>(
  'configs/nodes',
  async(network) => {
    let nodes
    switch(network) {
      case Network.EOS: {
        nodes = process.env.EOS_NODE_URLS
        break
      }
      case Network.WAX: {
        nodes = process.env.WAX_NODE_URLS
        break
      }
      default: {
        console.error('Invalid network name parameter')
        nodes = ''
        break
      }
    }

    /**
     * An array containing all potential nodes, in order of priority
     */
    const nodeUrls = nodes?.split(',') ?? ['']
    /**
     * The highest priority responsive node
     */
    const node = await determineEosioNode(nodeUrls)
    return { network, node }
  })

export default configsSlice.reducer
