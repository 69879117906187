import { getEosioTable } from './table'

export type TokenResponse = {
  /**
   * The name of the token contract e.g. "novusphereio" or "eosio.token"
   */
  contract: string
  /**
   * The token precision comma separated from the symbol
   * @example '3,ATMOS' or '4,EOS'
   */
  symbol: string
}

export type PairResponse = {
  /**
   * The id of the two tokens as a pair
   * @example 177
   */
  id: number
  /**
   * One of the tokens in the pair, it may be the `from` or the `to` token
   */
  token0: TokenResponse
  /**
   * One of the tokens in the pair, it may be the `from` or the `to` token
   */
  token1: TokenResponse
  /**
   * The available pool for `token0` in the format 'scaled token amount + the token symbol'
   * @example '52035.593 ATMOS'
   */
  reserve0: string
  /**
   * The available pool for `token1` in the format 'scaled token amount + the token symbol'
   * @example '58.5141 EOS'
   */
  reserve1: string
  /**
   * How much liquidity there is in total for this pair
   * @example '5177147'
   */
  liquidity_token: string
  /**
   * The last sale price of `token0`
   * @example '0.00112450145422576'
   */
  price0_last: string
  /**
   * The last sale price of `token1`
   * @example '889.28297623991488763'
   */
  price1_last: string
  /**
   * The Date of the last block creation
   * @example '2023-04-17T01:22:54'
   */
  block_time_last: Date
}

type PairTableArgs = {
  /**
   * The pairIndex of the token liquidity pool with the chain main token
   * @example '177'
   */
  pairIndex: string
  /**
   * The URL of an EOSIO RPC node
   * @example 'https://wax-rpc.wombat.app'
   */
  node: string
  /**
   * The swap contract name
   * @example 'swap.alcor'
   */
  contract: string
}

/**
 * Returns the conversion value of the token to the network main token, in this case EOS
 * !Important - although it's for EOSIO networks, this pairs table can only be used for EOS, WAX
 * data is fetched from a different table.
 * The main reason for having this wrapper for `getEosioTable` is in order to have a defined return
 * type, since `getEosioTable` can have the return type of any table.
 *
 * Documentation of the api endpoint can be found here:
 * https://developers.eos.io/manuals/eos/v2.2/nodeos/plugins/chain_api_plugin/api-reference/index#operation/get_table_rows
 */
export async function getEosioPairTable(args: PairTableArgs): Promise<PairResponse[]> {
  // TODO - replace with an array of nodes which are tested before being used
  return getEosioTable(args.node, {
    code: args.contract,
    scope: args.contract,
    table: 'pairs',
    lower_bound: args.pairIndex,
    upper_bound: args.pairIndex,
    limit: 1,
    key_type: 'key',
    index_position: '',
  })
  // we are only interested in the `rows` property
    .then(res => res.rows)
    // TODO - add a catch?
}

