import { Action } from 'eosjs/dist/eosjs-serialize'
import { combineReducers } from '@reduxjs/toolkit'
import eosReducer from './eosSlice'
import waxReducer from './waxSlice'
import evmReducer from './evmNetworksSlice'
import type { AccountData } from '../services/eosio/account'
import type { EvmTokenBalance, EosIoTokenBalance } from '../services/balances-api'
import type { PriceRoute } from '../services/evm/paraswap-api/paraswap-types'
import { AlcorToken } from '../services/eosio/alcor/types'

/**
 * Token values which are input to an EVM swap. These can not be in the {@link TokenAmount} format
 * since they are stored in redux and therefore must be serializable values
 */
type SwapToken = {
  /**
   * The contract name of the token
   * @example 'eosio.token'
   */
  contract: string
  /**
   * The symbol of the token
   * @example 'WAX'
   */
  symbol: string
  /**
   * The amount of the token as an scaled string with decimals
   * @example '5.4'
   */
  amount: string
  /**
   * The decimal precision of the token
   */
  decimals: number
}

/**
 * The data to be passed to an EOSIO transaction
 */
export type ActionData = {
  /**
   * The name of the smart contract executing the swap
   * @example 'swap.alcor'
   */
  exchangeContract: string
  /**
   * The pairId or pairIndex of the token liquidity pool
   * @example '177'
   */
  pairId: string
  /**
   * The token being input to the swap
   */
  from: SwapToken
  /**
   * The token being input to the swap
   */
  to: SwapToken
}

/**
 * The status of a network
 */
export type NetworkState = {
  /**
   * If a network state is initialized
   */
  initialized: 'NO' | 'IN_PROGRESS' | 'YES'
}

/**
 * Authentication for EosIo networks with wallets which use Scatter protocol
 */
export type EosIoAuthentication = AccountData & {
  /**
   * The way the user authenticated
   */
  authenticationMethod: 'WOMBAT' | 'WAX_CLOUD_WALLET'
 }

/**
 * The entire network state for EOSIO networks
 */
export type EosIoNetworkState = NetworkState & EosIoAuthentication & {
  /**
   * When this is set to a value > 0 the EosIo user data is reloaded after {@link reinitializeIn}
   * milliseconds
   */
  reinitializeIn: number
  /**
   * All the tokens owned by the user on a given EosIo network
   */
  ownedTokens: EosIoTokenBalance[]
  /**
   * The data for a swap transaction on the basis of a quote response
   */
  actionsData: ActionData[]
}

export type WaxNetworkState = EosIoNetworkState & {
  /**
   * The action for a WAX token swap transaction
   */
  action?: Action
  /**
   * A list of tokens supported by alcor exchange
   */
  alcorTokens: AlcorToken[]
}

/**
 * The entire network state for chains which interact via an Ethereum provider
 */
export type EvmNetworkState = NetworkState & {
  /**
   * The EVM address of the user
   */
  address: string
  /**
   * The the id of the network that is currently selected in the connected wallet.
   * @example 1 = Ethereum, 137 = Polygon
   */
  networkId: number
  /**
   * All the tokens owned by the user on a given EVM network
   */
  ownedTokens: EvmTokenBalance[]
  /**
   * The quote data needed in order to execute an EVM swap
   */
  transactionBody?: PriceRoute
  /**
   * The ids returned for transactions submitted on an EVM network
   * @example ['0x0d40989ec930311fa4e7f471fc0689ac6bfae3c1f23af035c2b152fa1c810635']
   */
  txs: string[]
}

/**
 * The user's account with all the networks in the dapp
 */
export type UserState = {
  /**
   * The user's account on the EOS network
   */
  eos: EosIoNetworkState
  /**
   * The user's account on the WAX network
   */
  wax: WaxNetworkState
  /**
   * The user's account on an EVM network
   */
  evm: EvmNetworkState
}

export default combineReducers<UserState>({
  wax: waxReducer,
  eos: eosReducer,
  evm: evmReducer,
})

