@import '../../assets/styles/_variables';
@import '../../assets/styles/_typography';

.dropdown {
  position: absolute;
  background: var(--colorSurfaceDark);
  z-index: 1;
  width: 100vw;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;

  @media (min-width: 768px) {
    left: unset;
    bottom: unset;
    top: 64px;
    width: 50%;
    overflow-y: auto;
    border: 1px solid var(--colorWhite);
    border-radius: 16px;
    height: unset;
  }
}

.search {
  display: flex;
  z-index: 1;
  position: sticky;
  top: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  background-color: var(--colorSurfaceDark);
  @include text-smallest(left);
  border-bottom: 0.1rem solid var(--colorWhite);
  width: 100%;

  @media (min-width: 768px) {
    top: 0px;
  }
}

.input {
  padding: 8px 16px 8px 40px;
  margin: 12px 24px;
  background: var(--colorSurfacePale2);
  background-image: url('../../assets/images/search.svg');
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 16px;
  background-size: 16px;
  border-radius: 24px;
  width: calc(100% - 104px);
}

.input::placeholder {
  color: var(--colorTextSecondary)
}

.virtuoso-scroller-container {
  margin: 0;
  position: sticky;
  top: 105px;
  height: calc(100vh - 105px);

  @media (min-width: 768px) {
    height: 280px;
  }
}

.token-list {
  padding: 0;
  margin: 0;
  overflow-y: auto;
  
  &:not(:last-child) {
    border-bottom: 0.1rem solid var(--colorWhiteDisabled);
  }
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0px;
  cursor: pointer;
  gap: 16px;
  padding: 12px 24px;
}

.token {
  display: flex;
  gap: 14px;
  align-items: center;
  border-top: none !important;
}

.icon {
  height: 1rem;
  width: 1rem;
  border-top: none !important;
}

.chain {
  font-size: 0.75rem;
  line-height: 0.87rem;
  color: var(--colorTextSecondary);
  margin-top: 4px;
}

.amount {
  @include text-smallest(right);
  color: var(--colorTextSecondary);
  overflow-y: auto;
}
