import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ethers } from 'ethers'
import IERC20Abi from '../abis/IERC20.json'
import { EvmTokenBalance, getEvmTokenBalances } from '../services/balances-api'
import { PriceRoute, TransactionResponse } from '../services/evm/paraswap-api/paraswap-types'
import { transactionData } from '../services/evm/paraswap-api/fetch-transactions'
import { provider, switchChain, transact } from '../services/evm/provider'
import { TxType, addTransaction } from './transactionsSlice'
import { ConfigType, Network, networksConfig } from '../configs/networks'
import type { AsyncThunkOptions } from './index'
import type { EvmNetworkState } from './userSlice'
import { TokenAmount } from '../numbers/TokenAmount'
import { storeWarning } from './singleChainSlice'
import { authErrors, warningGenerator } from './warnings'

/**
 * The paraswap contract managing the token transfers
 */
export const ParaswapTokenTransferProxyContract = '0x216b4b4ba9f3e719726886d34a177484278bfcae'

/**
 * The state for evm networks when the app initiates
 */
const initialState: EvmNetworkState = {
  initialized: 'NO',
  address: '',
  networkId: 0,
  ownedTokens: [],
  txs: [],
}

/**
 * Slice of the state containing the evm network selected in the wallet.
 */
const networkSlice = createSlice({
  name: 'evm',
  initialState: initialState,
  reducers: {
    /**
     * Action to reset the user's evm state
     */
    logout() {
      return initialState
    },
    /**
     * Action to store the data derived from a quote which is needed for swapping two tokens
     */
    setQuoteData(state, action: PayloadAction<PriceRoute>) {
      state.transactionBody = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(connectWallet.fulfilled, (state, action) => {
        state.address = action.payload
        state.initialized = 'YES'
      })
      .addCase(connectWallet.rejected, (state, action) => {
        console.error('Error getting the user address', action.error)
      })
      .addCase(determineNetwork.fulfilled, (state, action) => {
        state.networkId = action.payload
      })
      .addCase(determineNetwork.rejected, (state, action) => {
        console.error('Error determining the network', action.error)
      })
      .addCase(switchNetwork.fulfilled, (state, action) => {
        state.networkId = action.payload
      })
      .addCase(switchNetwork.rejected, (state, action) => {
        console.error('Error switching the network', action.error)
      })
      .addCase(loadTokenBalances.fulfilled, (state, action) => {
        state.ownedTokens = action.payload
      })
      .addCase(loadTokenBalances.rejected, (state, action) => {
        console.error('Error loading EVM token balances', action.error)
      })
      .addCase(doSingleChainSwap.fulfilled, (state, action) => {
        if (action.payload) {
          state.txs.push(action.payload)
        }
      })
      .addCase(doSingleChainSwap.rejected, (state, action) => {
        console.error('Error executing EVM single chain token swap', action.error)
      })
      .addCase(doMultiChainSwap.rejected, (state, action) => {
        console.error('Error executing the EVM multichain token swap', action.error)
      })
  },
})

/**
 * Explicitly requests the user to connect an account to the dApp
 */
export const connectWallet = createAsyncThunk<string, void, AsyncThunkOptions>(
  'user/evm/connectWallet',
  async (_, thunkApi) => {
    try {
      const accounts = await provider.send('eth_requestAccounts', [])
      return accounts[0]
    } catch (e) {
      if (['chain is not set up'].includes((e as Error).message)) {
        const network = thunkApi.getState().singleChain.network.symbol
        thunkApi.dispatch(storeWarning([{ ...authErrors.CHAIN_NOT_FOUND,
          message: warningGenerator.CHAIN_NOT_FOUND.message(network) }]))
      } else if (provider === undefined) {
        thunkApi.dispatch(storeWarning([authErrors.NO_PROVIDER]))
      }
      else {
        console.error(e)
        thunkApi.dispatch(storeWarning([authErrors.WALLET_NOT_FOUND]))
      }
      throw e
    }
  },
)

/**
 * Determines the network from the provider
 */
export const determineNetwork = createAsyncThunk<number>(
  'network/determine',
  () => {
    return provider.getNetwork()
      .then(network => network.chainId)
  },
)

/**
 * Switch the network to the usable for the dApp
 */
export const switchNetwork = createAsyncThunk<number, number>(
  'network/switch',
  (networkId) => {
    return switchChain(networkId)
      .then(network => network.chainId)
  },
)

/**
 * Fetch all token balances for a specified EVM network
 */
export const loadTokenBalances = createAsyncThunk<EvmTokenBalance[], Network, AsyncThunkOptions>(
  'user/evm/loadTokenBalances',
  async (network, thunkApi) => {
    const userAddress = thunkApi.getState().user.evm.address
    const networkId = networksConfig[network].id
    return (await getEvmTokenBalances(userAddress, networkId))
  },
)

/**
 * Sets the allowance for a staking smart contract for the user to MaxUint256.
 * This is required before the swap function can be used for any other but the chain main token.
 * @param tokenAddress The address of the token to set the allowance for
 */
export const setAllowance = createAsyncThunk<void, string, AsyncThunkOptions>(
  'user/evm/setAllowance',
  async (tokenAddress, thunkApi) => {
    const network = thunkApi.getState().singleChain.network.symbol
    // token contract
    const token = new ethers.Contract(tokenAddress, IERC20Abi, provider.getSigner())
    // Approve the maximum possible amount
    const address = ParaswapTokenTransferProxyContract
    console.debug('Setting the allowance for smart contract %s', address)
    const tx = await token.approve(address, ethers.constants.MaxUint256)
    console.debug('Allowance set for smart contract %s resulted in transaction %s', address,
      tx.hash)
    thunkApi.dispatch(addTransaction(tx.hash, network, TxType.SET_ALLOWANCE, tokenAddress))
  },
)

/**
 * Make a swap transaction on an EVM network
 */
export const doSingleChainSwap = createAsyncThunk<string | void, void, AsyncThunkOptions>(
  'user/evm/swap',
  async (_, thunkApi) => {
    const evm = thunkApi.getState().user.evm
    const { network, maxPriceImpact } = thunkApi.getState().singleChain
    const config = thunkApi.getState().configs[network.symbol]

    const priceImpactTolerance = parseFloat(maxPriceImpact)
    // `doSwap` will only be called if the `config.type` is `ConfigType.EVM`, the check is necessary
    // for typescript
    if ((config.type === ConfigType.EVM) && evm.transactionBody) {
      const txData = await transactionData(evm.transactionBody, evm.address, priceImpactTolerance,
        config.feePercentage)
      if ('error' in txData) {
        throw new Error(txData.error)
      } else if (txData) {
        const tx = await transact(txData)
        thunkApi.dispatch(addTransaction(tx, network.symbol, TxType.SWAP))
        return tx
      }
    }
  },
)

/**
 * Make a cross-chain transaction on an EVM network
 */
export const doMultiChainSwap = createAsyncThunk<string | void, Network, AsyncThunkOptions>(
  'user/evm/multiswap',
  async (network, thunkApi) => {
    const { transaction: transactionData, payinToken } = thunkApi.getState().multiChain
    if (!transactionData) {
      throw new Error('Executing cross-chain swap: No transaction data found')
    } else if (!payinToken) {
      throw new Error('Executing cross-chain swap: No pay-in token data found')
    } else if (payinToken.id !== transactionData.currencyFrom) {
      throw new Error('Executing cross-chain swap: Selected and transaction pay in tokens don\'t match')
    }
    const { address: senderAddress, ownedTokens } = thunkApi.getState().user.evm
    const config = thunkApi.getState().configs[network]

    const ownedToken = ownedTokens.find(owned => owned.address === payinToken.contract)

    if (!ownedToken) {
      throw new Error('Token isn\'t owned in wallet, can\'t be sent')
    }

    if (payinToken.contract !== '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee') {
      const amount = TokenAmount.fromStringWithDecimals(transactionData.amountExpectedFrom,
        payinToken.decimals, payinToken.symbol).asUnscaledQuantity(payinToken.decimals)
      // the amount value must be passed as a hex value since it is interpreted as a hex value in
      // the iOS client, regardless of whether it is actually in WEI. It is not possible to release
      // a fix quickly in iOS, so it has to be handled here, although the WEI value should also be
      // valid.
      const bigIntAmount = ethers.BigNumber.from(amount).toHexString()

      const contract = new ethers.Contract(ownedToken.address, IERC20Abi, provider)
      const transferData = contract.interface.encodeFunctionData('transfer',
        [transactionData.payinAddress, bigIntAmount])

      const data: TransactionResponse = {
        from: senderAddress,
        to: contract.address,
        value: '0',
        data: transferData,
        gas: 0,
        gasPrice: '',
        chainId: parseInt(config.id),
      }
      const tx = await transact(data)
      thunkApi.dispatch(addTransaction(tx, network, TxType.SWAP))
      return tx
    } else if (payinToken.contract === '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee') {
      const recipientAddress = transactionData.payinAddress
      const amount = TokenAmount.fromStringWithDecimals(transactionData.amountExpectedFrom,
        payinToken.decimals, payinToken.symbol).asUnscaledQuantity(payinToken.decimals)

      const data: TransactionResponse = {
        from: senderAddress,
        to: recipientAddress,
        value: amount,
        data: '',
        gas: 0,
        gasPrice: '',
        chainId: parseInt(config.id),
      }
      const tx = await transact(data)
      thunkApi.dispatch(addTransaction(tx, network, TxType.SWAP))
      return tx
    } else {
      throw new Error(`Invalid payin contract: ${payinToken.contract}`)
    }
  },
)

export const { logout, setQuoteData } = networkSlice.actions

export default networkSlice.reducer
