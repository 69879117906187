import React, { FC, useEffect, useMemo } from 'react'
import { TokenAmount } from '../../numbers/TokenAmount'
import { useTokenInputs } from '../../hooks/useTokenInputs'
import { useSwapEstimate } from '../../hooks/useSwapEstimate'
import { useAppSelector } from '../../hooks/useAppSelector'
import { SingleChainErrorKey } from '../../store/warnings'
import { EditableAmount } from '../../elements/AmountInputs/EditableAmount'
import { ConfigType } from '../../configs/networks'

// TODO - this should be split up
import styles from '../../elements/AmountInputs/AmountInput.module.scss'

/**
 * A UI component for inputing token amounts
 */
export const InputAmount: FC = () => {
  const {
    fromAmount: amount, warnings, maxPriceImpact, network,
  } = useAppSelector(state => state.singleChain)
  const { from, to } = useTokenInputs()
  const { handleNewInputAmount } = useSwapEstimate()

  // Effect which updates the output amount when the from token updates
  useEffect(() => {
    handleNewInputAmount('')
  }, [from])

  // Effect which updates the output amount when the to token updates
  useEffect(() => {
    if (amount) {
      handleNewInputAmount(amount)
    }
  }, [to])

  // Effect to recalculate the swap route when the slippage tolerance is changed
  // This is only required for EVM routes. This is due to the way that the EVM path is calculated -
  // the executed transaction must contain the data from the quote, which has to contain the user's
  // selected maximum price impact. Therefore the quote has to be refetched each time the max price
  // impact value is changed
  useEffect(() => {
    if (amount && network.type === ConfigType.EVM) {
      handleNewInputAmount(amount)
    }
  }, [maxPriceImpact])

  /**
   * A warning about insufficient balance to swap
   */
  const insufficientBalance = useMemo(() => {
    return warnings.some(warning => warning?.code === SingleChainErrorKey.INSUFFICIENT_BALANCE)
  }, [warnings])

  return (
    <EditableAmount value={amount} highlight={insufficientBalance} maxDecimals={from.decimals}
      onChange={handleNewInputAmount}>
      <button className={styles['cta__max']} aria-label='swap maximum available'
        disabled={!from.balance?.gt(TokenAmount.ZERO(from.balance.decimals, from.balance.symbol))}
        onClick={e => {
          e.preventDefault()
          handleNewInputAmount(from.balance.display(false, from.balance.decimals))
        }}>
        MAX
      </button>
    </EditableAmount>
  )
}
