import cl from 'classnames'
import React, { FC, useState } from 'react'
import { type TransactionDetail } from './TransactionDetails'

import EDIT from '../../assets/images/edit'
import INFO from '../../assets/images/info-outline'

import styles from './TransactionDetails.module.scss'

type Props = {
  /**
   * The values to show in the UI for the editable detail
   */
  detail: TransactionDetail
  /**
   * A callback to update the editable detail value
   */
  updateValue: (val: string) => unknown
  /**
   * A callback to confirm the finalised editable value
   */
  confirmValue: () => unknown
  /**
   * An option text to explain the editable detail
   */
  tooltipText?: string
}

/**
 * A value for the swap which can be edited by the user
 */
export const EditableDetail: FC<Props> = props => {
  const [edit, setEdit] = useState<boolean>(false)

  /**
   * Handles the user finishing editing
   */
  function confirmValue() {
    setEdit(false)
    props.confirmValue()
  }

  /**
   * Unfocusses a input when the user presses the 'Enter' key
   */
  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter'){
      e.currentTarget.blur()
    }
  }

  return (
    <p className={cl(styles['row'], props.detail.highlight ? styles['row--highlight'] : '')}>
      <span className={styles['label']}>
        <span>{props.detail.label}</span>
        {props.tooltipText && (
          <span className={styles['tooltip']}>
            <span className={styles['icon']}>
              <INFO colourHex={props.detail.highlight ? '#FF5A5A' : '#C0C0C0' } />
            </span>
            <span aria-live='polite' className={styles['tooltip-text']} role='tooltip'>
              {props.tooltipText}
            </span>
          </span>
        )}
      </span>
      <label className={styles['editable-input']} aria-label={`${props.detail.label} %`}>
        <span className={styles['editable-value']}>
          <input className={styles['input']} type='text'
            maxLength={4}
            value={props.detail.value}
            onFocus={() => setEdit(true)}
            onChange={e => props.updateValue(e.target.value)}
            onBlur={confirmValue}
            onKeyDown={e => handleKeyDown(e)}/>
          <span>%</span>
        </span>
        {!edit &&
            <span onClick={() => setEdit(true)} className={styles['icon']}>
              <EDIT colourHex={props.detail.highlight ? '#FF5A5A' : '#C0C0C0' } />
            </span>}
      </label>
    </p>
  )
}
