import React, { FC, PropsWithChildren } from 'react'
import { useBlockchain } from '../../hooks/useInputBlockchain'
import { Network } from '../../../../configs/networks'
import { WaxInitialiser } from '../../../../views/initialisers/WaxInitialiser'
import { EosSwap, EvmSwap, WaxSwap } from '../../../../views/initialisers/NetworkStateProviders'
import { EosInitialiser } from '../../../../views/initialisers/EosInitialiser'
import { EvmInitialiser } from '../../../../views/initialisers/EvmInitialiser'
import { useAppSelector } from '../../../../hooks/useAppSelector'

type NetworkSource = 'input-token' | 'user-selection'

/**
 * Returns a component wrapped in a state provider for the necessary blockchain network
 */
export const NetworkWrapper: FC<PropsWithChildren & { source: NetworkSource }> = props => {
  const selectedNetwork = useAppSelector(state => state.multiChain.network)
  // TODO - double-check this
  const network = useBlockchain({ source: props.source, userSelected: selectedNetwork })

  switch(network) {
    case Network.WAX: {
      return <WaxInitialiser>
        <WaxSwap swapType='cross-chain'>{props.children}</WaxSwap>
      </WaxInitialiser>
    }
    case Network.EOS: {
      return <EosInitialiser>
        <EosSwap swapType='cross-chain'>{props.children}</EosSwap>
      </EosInitialiser>
    }
    case Network.ETH:
    case Network.POL:
    case Network.BNB:
    case Network.AVAX: {
      return <EvmInitialiser network={network}>
        <EvmSwap network={network} swapType='cross-chain'>{props.children}</EvmSwap>
      </EvmInitialiser>
    }
    default: return <>Oops! Network not detected</>
  }
}
