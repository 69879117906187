@import '../../assets/styles/typography';

.header {
  display: flex;
  gap: 16px;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: var(--colorSurfaceDark);
  z-index: 1;

  @media (min-width: 768px) {
    position: unset;
    top: unset;
    @include headline-medium();
  }

  &--desktop-hidden {
    @media (min-width: 768px) {
      display: none;
    }
  }
}
