export default ({ colourHex }: { colourHex: string }) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='Info outline' clipPath='url(#clip0_2663_3491)'>
      <path id='Vector' d='M7.33325 4.66665H8.66658V5.99998H7.33325V4.66665ZM7.99992 11.3333C8.36658 11.3333 8.66658 11.0333 8.66658 10.6666V7.99998C8.66658 7.63331 8.36658 7.33331 7.99992 7.33331C7.63325 7.33331 7.33325 7.63331 7.33325 7.99998V10.6666C7.33325 11.0333 7.63325 11.3333 7.99992 11.3333ZM7.99992 1.33331C4.31992 1.33331 1.33325 4.31998 1.33325 7.99998C1.33325 11.68 4.31992 14.6666 7.99992 14.6666C11.6799 14.6666 14.6666 11.68 14.6666 7.99998C14.6666 4.31998 11.6799 1.33331 7.99992 1.33331ZM7.99992 13.3333C5.05992 13.3333 2.66659 10.94 2.66659 7.99998C2.66659 5.05998 5.05992 2.66665 7.99992 2.66665C10.9399 2.66665 13.3333 5.05998 13.3333 7.99998C13.3333 10.94 10.9399 13.3333 7.99992 13.3333Z' fill={colourHex}/>
    </g>
    <defs>
      <clipPath id='clip0_2663_3491'>
        <rect width='16' height='16' fill={colourHex}/>
      </clipPath>
    </defs>
  </svg>)
