type Body = {
  /**
   * The name of the smart contract that controls the provided table
   */
  code: string
  /**
   * The account to which this data belongs
   */
  scope: string
  /**
   * The name of the table to query
   */
  table: string
  /**
   * Filters results to return the first element that is not less than provided value in set
   */
  lower_bound: string
  /**
   * Filters results to return the first element that is greater than provided value in set
   */
  upper_bound: string
  /**
   * Limit number of results returned, default: 10
   */
  limit: number
  /**
   * Type of key specified by index_position (for example - uint64_t or name)
   */
  key_type: string
  /**
   * Position of the index used, accepted parameters: primary, secondary, tertiary, fourth, fifth,
   * sixth, seventh, eighth, ninth, tenth
   */
  index_position: string
}

/**
 * `getEosioTable` can be used to get any Eosio table
 */
type EosioTable = {
  /**
   * The entries in the table - in data in these depends on which table is being fetched. The form
   * of this data can't be known until the table which is being fetched is specified - that is
   * where the return type can be properly described.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  'rows': any[]
  /**
   * The key of the next entry in the table
   */
  next_key: string
  /**
   * Whether there remain more rows to be fetched
   */
  more: boolean
}

/**
 * Fetches the specified EOSIO table from a node based on the input parameters
 * @param baseUrl the url of the node e.g. `https://wax-rpc.wombat.app`
 * @param params values for the body of the request
 */
export async function getEosioTable(baseUrl: string, params: Body): Promise<EosioTable> {
  const body: Body = {
    code: params.code,
    scope: params.scope,
    table: params.table,
    lower_bound: params.lower_bound,
    upper_bound: params.upper_bound,
    limit: params.limit,
    key_type: params.key_type,
    index_position: params.index_position,
  }
  const response = await fetch(`${baseUrl}/v1/chain/get_table_rows`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...body,
      // necessary in order to receive a response in json format
      json: true,
    }),
  })
  return response.json()
}
