import React, { FC } from 'react'

import REVERSE from '../assets/images/reverse.svg'

import styles from './ReverseTokens.module.scss'

type Props = {
  /**
   * Function to reverse the input currencies
   */
  onClick: () => void
}

/**
 * UI component for reversing the input currencies
 */
export const ReverseCurrencies: FC<Props> = props => (
  <button onClick={props.onClick} className={styles['reverse']}>
    <img src={REVERSE} alt='reverse input and output tokens'/>
  </button>
)
