import { useEffect } from 'react'

type Args = {
  /**
   * Refs for elements for which a click outside should be handled
   */
  refs: React.RefObject<HTMLElement>[]
  /**
   * The function to execute when an outside click occurs
   */
  handleClick: () => unknown

}

/**
 * A hook to handle clicks outside an element in which it's used
 */
export const useOutsideClick = (args: Args) => {
  /**
   * Handles clicks outside a component with the provided ref
   */
  function handleClickOutside(e: MouseEvent) {
    e.preventDefault

    const isOutside = args.refs.every(ref => ref.current && !ref.current.contains(e.target as Node))
    if (isOutside) {
      args.handleClick()
    }
  }

  // Adds mousedown event listener to the document when the component mounts
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Cleans up the event listener when the component unmounts
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
}
