@import '../../../../assets/styles/_variables';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  @media (min-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
  }

  &--secondary {
    @media (min-width: 768px) {
      flex-wrap: unset;
      justify-content: space-between;
    }
  }
}
