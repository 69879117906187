import cl from 'classnames'
import React, { FC, useRef, useState } from 'react'
import { useOutsideClick } from '../../../../hooks/useOutsideClick'
import { Selector } from '../../../TokenInputs/Selector'
import { InputType, ListItem } from '../../../../hooks/useTokensList'

import CHEVRON_DOWN from '../../../../assets/images/triangle-down.svg'
import TOKEN from '../../../../assets/images/token.svg'

import styles from './Inputs.module.scss'

type Props = {
  /**
   * If the selector is set to be open, whether it should display the values to swap 'from' or to
   * swap 'into'
   */
  type: InputType
  /**
   * The name of the input
   */
  name: 'blockchain' | 'token'
  /**
   * The list of selectable options for this dropdown
   */
  options: ListItem[]
  /**
   * The item which has been selected from the options
   */
  selected: ListItem | undefined
  /**
   * A function to call when an option is selected
   */
  onSelect: (type: InputType, item: ListItem) => void
}

/**
 * UI Component for a clickable field which opens a dropdown selector and displays the selected item
 */
export const DropdownInput: FC<Props> = props => {
  const [selectorOpen, setSelectorOpen] = useState(false)
  const listRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)

  // Handles clicks outside of the provided ref
  useOutsideClick({ refs: [listRef, buttonRef], handleClick: () => setSelectorOpen(false) })

  return (
    <div>
      <button ref={buttonRef}
        className={
          cl(styles['token-select'], props.name === 'token' ? styles['token-select--half']: '')
        }
        onClick={() => setSelectorOpen(!selectorOpen)}>
        <p className={styles['label']}>Swap {props.type}</p>
        <div className={styles['token']}>
          {props.selected?.iconUrl && (
            <img src={props.selected.iconUrl} className={styles['icon']} alt=''
              onError={e => e.currentTarget.src = TOKEN}/>
          )}
          <p>{props.selected?.symbol ?? ''}</p>
        </div>
        <img className={styles[`icon${selectorOpen ? '--rotated' : '--unrotated' }`]}
          src={CHEVRON_DOWN} alt=''/>
      </button>
      {selectorOpen && (
        <div ref={listRef}>
          <Selector name={props.name} type={props.type} items={props.options}
            onSelect={(type, item) => props.onSelect(type, item)}
            closeSelector={() => setSelectorOpen(false)}/>
        </div>
      )}
    </div>
  )
}
