import {
  ChangellyResponse, ChangellyToken, ChangellyQuote, ChangellyTransaction, PastTransaction,
  ChangellyError, ChangellyPairInputRange,
} from './types'

const baseUrl = process.env.WOMBAT_BACKEND ?? 'https://api.getwombat.io'

/**
 * The methods available from the Changelly API
 */
type Method = 'getCurrenciesFull' | 'getExchangeAmount' | 'validateAddress' | 'createTransaction'
| 'getTransactions' | 'getPairsParams'

/**
 * The body of a request to Changelly API
 */
type Body = {
  /**
   * The JSON-RPC version. Changelly API uses JSON-RPC 2.0 protocol.
   * @example '2.0'
   */
  jsonrpc: string
  /**
   * A custom ID generated at the client side to distinguish responses. Any value can be used.
   * TODO - not sure what the point of this is.
   */
  id: string
  /**
   * The name of the method being called
   */
  method: Method
  /**
   * Any data as key value pairs which are needed by changelly for the API call
   */
  params: Record<string, string | string[]>
}

/**
 * Creates a request to the Changelly API
 * @param body The body of the request
 */
async function createRequest(body: Body): Promise<Response> {
  const endpoint = new URL('/changelly-proxy', baseUrl)
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
}

function generateRandomId() {
  const timestamp = new Date().getTime()
  const randomNumber = Math.floor(Math.random() * 1000000)
  return `spielworks-${timestamp}${randomNumber}`
}

/**
 * Creates the body for a request to Changelly API
 * @param method the {@link Method} for which the Changelly API is being called
 * @param params the data which is needed by the Changelly API method (these change depending on
 * the method)
 * @returns Creates the body of a request to the Changelly API
 */
function generateBody(method: Method, params: Record<string, string | string[]>): Body {
  return {
    jsonrpc: '2.0',
    id: generateRandomId(),
    method: method,
    params: params,
  }
}

/**
 * Fetches the list of available currencies available to swap via Changelly
 */
export async function getCurrenciesFull()
: Promise<ChangellyResponse & ({ result: ChangellyToken[] } | ChangellyError )> {
  const message = generateBody('getCurrenciesFull', {})
  const res = await createRequest(message)
  if (res.status === 200) {
    return res.json()
  } else {
    console.error(res)
    // not sure about this but for some reason this error is not otherwise being thrown
    throw new Error(res.statusText)
  }
}

/**
 * Data needed to fetch a quote for the exchange of two tokens for a specific input amount
 */
type ExchangeAmountArgs = {
  /**
   * The id a.k.a. 'ticker' (see {@link ChangellyToken}) of the payin token
   * @example 'ltc'
   */
  from: string
  /**
   * The id a.k.a. 'ticker' (see {@link ChangellyToken}) of the payout token
   * @example 'eth'
   */
  to: string
  /**
   * Scaled payin amount
   * @example '3.99'
   */
  amountFrom: string
}

/**
 * Fetches the minimum and maximum exchangeable amounts for specific pair of currencies.
 */
export async function getPairRange(args: Omit<ExchangeAmountArgs, 'amountFrom'>)
: Promise<ChangellyResponse & ({ result: ChangellyPairInputRange[] } | ChangellyError)> {
  const message = generateBody('getPairsParams', args)
  const res = await createRequest(message)
  if (res.status === 200) {
    return res.json()
  } else {
    // not sure about this but for some reason this error is not otherwise being thrown
    throw new Error(res.statusText)
  }
}

/**
 * Fetches a quote for the exchange of two tokens for a specific input amount
 */
export async function getExchangeAmount(args: ExchangeAmountArgs)
// TODO - document when swapping one token pair there will always be one item in the result array
: Promise<ChangellyResponse & ({ result: ChangellyQuote[] } | ChangellyError)> {
  const message = generateBody('getExchangeAmount', args)
  const res = await createRequest(message)
  if (res.status === 200) {
    return res.json()
  } else {
    // not sure about this but for some reason this error is not otherwise being thrown
    throw new Error(res.statusText)
  }
}

/**
 * Data needed from the user to confirm that an address is able to receive a given currency
 */
type ValidateAddressArgs = {
  /**
   * The id a.k.a. 'ticker' (see {@link ChangellyToken}) of the payout token
   * @example 'eth'
   */
  currency: string
  /**
   * The recipient address for the token
   * @example '0xf4bc55ad49573af16dd80bd3dd00e72d611b017c'
   */
  address: string
}

/**
 * Confirms that a provided address is able to receive a provided currency
 */
export async function validateAddress(args: ValidateAddressArgs)
: Promise<ChangellyResponse & ({ result: { result: boolean} } | ChangellyError)> {
  const message = generateBody('validateAddress', args)
  const res = await createRequest(message)
  if (res.status === 200) {
    return res.json()
  } else {
    // not sure about this but for some reason this error is not otherwise being thrown
    throw new Error(res.statusText)
  }
}

/**
 * Data needed to create a transaction via Changelly
 */
type CreateTransactionArgs = {
  /**
   * The id a.k.a. 'ticker' (see {@link ChangellyToken}) of the payin token
   * @example 'ltc'
   */
  from: string
  /**
   * The id a.k.a. 'ticker' (see {@link ChangellyToken}) of the payout token
   * @example 'eth'
   */
  to: string
  /**
   * The recipient address for the token
   * @example '0xf4bc55ad49573af16dd80bd3dd00e72d611b017c'
   */
  address: string
  /**
   * Scaled payin amount
   * @example '3.99'
   */
  amountFrom: string
  /**
   * Address of the wallet to refund in case of any technical issues during the exchange. The
   * currency of the wallet must match with the from currency.
   * This should be set as the sender address.
   */
  refundAddress: string,
  /**
   * An optional, user-supplied id (aka. memo, tag) for Changelly to include in their payout
   * transaction. This might be required by DEXes such as Coinbase for a user to receive certain
   * tokens, e.g. EOS.
   */
  extraId?: string
}

/**
 * Returns an address for the user to send the tokens for exchange, alongside other data about a
 * potential transaction (should the user choose to execute it)
 */
export async function createTransaction(args: CreateTransactionArgs)
: Promise<ChangellyResponse & ({ result: ChangellyTransaction } | ChangellyError)> {
  const message = generateBody('createTransaction', args)
  const res = await createRequest(message)
  if (res.status === 200) {
    return res.json()
  } else {
    // not sure about this but for some reason this error is not otherwise being thrown
    throw new Error(res.statusText)
  }
}

/**
 * Returns the status of transactions for a given transaction id
 * @param txId the Changelly id or array of ids for a transaction
 */
export async function getTransactionHistoryById(txId: string | string[])
: Promise<ChangellyResponse & ({ result: PastTransaction[] } | ChangellyError)> {
  const message = generateBody('getTransactions', { id: txId })
  const res = await createRequest(message)
  if (res.status === 200) {
    return res.json()
  } else {
    // not sure about this but for some reason this error is not otherwise being thrown
    throw new Error(res.statusText)
  }
}

/**
 * Returns the status of transactions base on the transaction's token recipient
 * @param address the address of the final recipient of the tokens (not to be confused with
 * Changelly's 'receiver address'... this is the user who eventually gets the tokens)
 */
export async function getTransactionHistoryByRecipient(address: string)
: Promise<ChangellyResponse & ({ result: PastTransaction[] } | ChangellyError)> {
  const message = generateBody('getTransactions', { payoutAddress: address })
  const res = await createRequest(message)
  if (res.status === 200) {
    return res.json()
  } else {
    // not sure about this but for some reason this error is not otherwise being thrown
    throw new Error(res.statusText)
  }
}
