@import '../../assets/styles/_typography';

.info-box {
  display: flex;
  padding: 8px 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--background-white-16, rgba(255, 255, 255, 0.16));
  @include text-smallest(left)
}

.icon {
  height: 16px;
  width: 16px;
  padding-top: 3px;
}
