import { FC, PropsWithChildren, useEffect, useMemo } from 'react'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { useAppSelector } from '../../hooks/useAppSelector'
import {
  connectWallet,
  determineNetwork,
  loadTokenBalances,
  logout,
  switchNetwork,
} from '../../store/evmNetworksSlice'
import {
  initialiseDisplayValues, storeMainToken, storeOwnedTokens,
} from '../../store/singleChainSlice'
import { formatEvmOwnedTokens } from '../../store/processRawTokenData'
import { EvmNetworkSymbol, Network } from '../../configs/networks'
import { monitorPendingTransactions } from '../../hooks/useTransactions'
import { provider } from '../../services/evm/provider'

/**
 * Returns the code for the network
 */
const NETWORK_IDS: Record<Network, number> = {
  [Network.AVAX]: parseInt(process.env.AVAX_SUPPORTED_NETWORK || '0'),
  [Network.BNB]: parseInt(process.env.BNB_SUPPORTED_NETWORK || '0'),
  [Network.EOS]: parseInt(process.env.EOS_SUPPORTED_NETWORK || '0'),
  [Network.ETH]: parseInt(process.env.ETH_SUPPORTED_NETWORK || '0'),
  [Network.POL]: parseInt(process.env.MATIC_SUPPORTED_NETWORK || '0'),
  [Network.WAX]: parseInt(process.env.WAX_SUPPORTED_NETWORK || '0'),
}

type EvmNetworksInitialiserProps = {
  /**
   * The network to use the component with. Should not be {@link Network.EOS} or {@link Network.WAX}
   */
  network: EvmNetworkSymbol
} & PropsWithChildren

/**
 * Component that ensures Ethereum state is initialized. This should be wrapped around all
 * components that use the Ethereum state.
 */
export const EvmInitialiser: FC<EvmNetworksInitialiserProps> = props => {
  const dispatch = useAppDispatch()
  const { initialized, networkId: connectedNetworkId } = useAppSelector(state => state.user.evm)
  const config = useAppSelector(state => state.configs[props.network])
  const viewedNetworkId = useMemo(() => NETWORK_IDS[props.network], [props.network])
  monitorPendingTransactions()

  // Initialise display values
  useEffect(() => {
    dispatch(initialiseDisplayValues(props.network))
  }, [props.network])

  // Effect to connect Wombat wallet automatically
  useEffect(() => {
    if ((initialized === 'NO') && (provider !== undefined)) {
      dispatch(connectWallet())
    }
  }, [provider])

  // Once the wallet is connected, check for the chainId
  useEffect(() => {
    if (initialized === 'YES') {
      dispatch(determineNetwork())
    }
  }, [initialized])

  // If the chain ID has already been set (and therefore is no longer '0') but is not the id
  // of the current network, switch to the current network
  useEffect(() => {
    if ((connectedNetworkId !== viewedNetworkId) && connectedNetworkId !== 0) {
      dispatch(switchNetwork(viewedNetworkId)).unwrap()
        // If the correct network cannot be switched to, reset the network data
        .catch(() => dispatch(logout()))
    }
  }, [connectedNetworkId, viewedNetworkId])

  // Once the page's network is initialised, load the token balances for the network
  useEffect(() => {
    // ensure the viewed network has been connected before loading token balances
    if (connectedNetworkId === viewedNetworkId) {
      dispatch(loadTokenBalances(config.name)).unwrap()
        .then(balances => {
          const formatted = formatEvmOwnedTokens(balances, config.availableTokens,
            config.mainTokenZeroBalance)
          // Stores tokens in the display state
          dispatch(storeOwnedTokens(formatted))
          dispatch(storeMainToken(formatted))
        })
    }
  }, [connectedNetworkId, viewedNetworkId, config.availableTokens])

  return <>{props.children}</>
}
