import { FC } from 'react'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { setAmount } from '../../store/multiChainSlice'
import { EditableAmount } from '../../../../elements/AmountInputs/EditableAmount'

/**
 * A UI component wrapper for the cross-chain pay in token amount input
 */
export const InputAmount: FC = () => {
  const dispatch = useAppDispatch()
  const state = useAppSelector(state => state.multiChain)

  return <EditableAmount value={state.payinAmount} maxDecimals={8} highlight={false}
    disabled={!state.inputRange} onChange={amount => dispatch(setAmount(amount))}/>
}
