import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  AccountData, getWaxCloudWalletAccount, getScatterAccount, WAX_CHAIN_ID,
} from '../services/eosio/account'
import { eosioAction, scatterTransact, waxCloudWalletTransact } from '../services/eosio/transact'
import { getEosIoTokenBalances, EosIoTokenBalance } from '../services/balances-api'
import { EosIoAuthentication, ActionData, WaxNetworkState } from './userSlice'
import { AsyncThunkOptions } from './index'
import { Network } from '../configs/networks'
import { Action } from 'eosjs/dist/eosjs-serialize'
import { TokenAmount } from '../numbers/TokenAmount'
import {
  setLoading, storeDetails, storeEstimatedSlippage, storeOutput, storeWarning,
} from './singleChainSlice'
import { authErrors, displayError, warningGenerator, singleChainSwapErrors } from './warnings'
import { formatTransactionDetails } from '../features/TxInformation/transaction-stats'
import { AlcorToken } from '../services/eosio/alcor/types'
import { getSwapRoute, getTokens } from '../services/eosio/alcor/alcor-api'
import { isSignificantPriceImpact } from '../services/eosio/price-impact'

// Custom marketplace for fees on Alcor
// See https://docs.alcor.exchange/alcor-swap/referral-custom-market-fee
const ALCOR_MARKETPLACE = process.env.ALCOR_MARKETPLACE

type ActionArgs = {
  /**
   * The token input to the swap
   */
  payinToken: {
    /**
     * The token contract name
     * @example 'alien.worlds'
     */
    contract: string
    /**
     * The amount of the token as a scaled decimal value
     * @example '89.0239'
     */
    amount: string
    /**
     * The token to
     * @example 'TLM'
     */
    symbol: string
  }
  /**
   * The account which the output of the swap is sent to, i.e. the account of the user
   */
  userAccount: string
  /**
   * The memo provided for this swap
   */
  memo: string
}

/**
 * Prepares the transaction action for one token amount to be swapped from one token into another
 * @returns an action ready to be executed in a wallet transaction
 */
function prepareAction(args: ActionArgs): Action {
  return {
    name: 'transfer',
    account: args.payinToken.contract,
    authorization: [{ actor: args.userAccount, permission: 'active' }],
    data: {
      from: args.userAccount,
      quantity: `${args.payinToken.amount} ${args.payinToken.symbol}`,
      memo: `${args.memo}#${ALCOR_MARKETPLACE}`,
      to: 'swap.alcor',
    },
  }
}

/**
 * Timeout after which to reinitialize WAX data after any action that changes the data
 */
const DEFAULT_REINITIALIZE_IN_TIMEOUT_MS = 2000

/**
 * The state for wax when the app initiates
 */
const initialState: WaxNetworkState = {
  initialized: 'NO',
  accountName: '',
  publicKeys: [],
  authenticationMethod: 'WOMBAT',
  ownedTokens: [],
  alcorTokens: [],
  reinitializeIn: 0,
  actionsData: [],
  action: undefined,
}

const waxSlice = createSlice({
  name: 'wax',
  initialState: initialState,
  reducers: {
    /**
     * Action to reset the user's wax state
     */
    logout() {
      return initialState
    },
    /**
     * Resets the {@link EosIoNetworkState#reinitializeIn} to 0, meaning no further data refreshes
     * will be scheduled.
     */
    clearRefresh(state) {
      state.reinitializeIn = 0
    },
    /**
     * Stores action for a swap transaction
     */
    setAction(state, action: PayloadAction<Action>) {
      // ensure the action is reset
      state.action = undefined
      state.action = action.payload
    },
    /**
     * Stores the data for executing an action to swap two tokens
     */
    setActions(state, action: PayloadAction<ActionData[]>) {
      // ensure the actions data is reset
      state.actionsData = []
      state.actionsData = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(connectWombat.fulfilled, (state, action) => {
        state.initialized = 'YES'
        state.accountName = action.payload.accountName
        state.publicKeys = action.payload.publicKeys
        state.authenticationMethod = action.payload.authenticationMethod
      })
      .addCase(connectWombat.rejected, (state, action) => {
        console.error('Error getting scatter user WAX', action.error)
      })
      .addCase(connectWaxCloud.fulfilled, (state, action) => {
        state.initialized = 'YES'
        state.accountName = action.payload.accountName
        state.publicKeys = action.payload.publicKeys
        state.authenticationMethod = 'WAX_CLOUD_WALLET'
      })
      .addCase(connectWaxCloud.rejected, (state, action) => {
        console.error('Error getting Wax Cloud Wallet user', action.error)
      })
      .addCase(getBalances.fulfilled, (state, action) => {
        state.ownedTokens = action.payload
      })
      .addCase(getBalances.rejected, (state, action) => {
        console.error('Error loading WAX token balances', action.error)
      })
      .addCase(getAlcorTokens.fulfilled, (state, action) => {
        state.alcorTokens = action.payload
      })
      .addCase(getAlcorTokens.rejected, (state, action) => {
        console.error('Error fetching alcor swap tokens', action.error)
      })
      .addCase(doSingleChainSwap.fulfilled, (state) => {
        state.reinitializeIn = DEFAULT_REINITIALIZE_IN_TIMEOUT_MS
      })
      .addCase(doSingleChainSwap.rejected, (state, action) => {
        console.error('Error executing WAX token swap', action.error)
      })
      .addCase(doMultiChainSwap.fulfilled, (state) => {
        state.reinitializeIn = DEFAULT_REINITIALIZE_IN_TIMEOUT_MS
      })
      .addCase(doMultiChainSwap.rejected, (state, action) => {
        console.error('Error executing WAX token swap', action.error)
      })
  },
})

/**
 * Action to get the WAX account via Scatter ("login").
 */
export const connectWombat = createAsyncThunk<EosIoAuthentication, void, AsyncThunkOptions>(
  'user/wax/connectScatter',
  async (_, thunkApi) => {
    try {
      const accountData = await getScatterAccount(WAX_CHAIN_ID)
      const authentication: EosIoAuthentication = {
        accountName: accountData.accountName,
        publicKeys: accountData.publicKeys,
        authenticationMethod: 'WOMBAT',
      }
      return authentication
    } catch (e) {
      if (['The wallet is locked.', 'no wallet matching chainId found'].includes((e as Error).message)) {
        thunkApi.dispatch(storeWarning([{ ...authErrors.CHAIN_NOT_FOUND, message: warningGenerator.CHAIN_NOT_FOUND.message('WAX') }]))
      } else {
        thunkApi.dispatch(storeWarning([authErrors.WALLET_NOT_FOUND]))
      }
      throw e
    }
  },
)

/**
 * Action to get the WAX account via WAX Cloud Wallet ("login")
 */
export const connectWaxCloud = createAsyncThunk<AccountData>(
  'user/wax/connectWaxCloudWallet',
  async () => {
    const accountData = await getWaxCloudWalletAccount()
    const authentication: EosIoAuthentication = {
      accountName: accountData.accountName,
      publicKeys: accountData.publicKeys,
      authenticationMethod: 'WAX_CLOUD_WALLET',
    }
    return authentication
  },
)

/**
 * Action to load the balances of all owned WAX tokens
 */
export const getBalances = createAsyncThunk<EosIoTokenBalance[], void, AsyncThunkOptions>(
  'user/wax/getBalances',
  async (_, thunkApi) => {
    const userAccount = thunkApi.getState().user.wax.accountName
    return (await getEosIoTokenBalances(userAccount, Network.WAX))
  },
)

/**
 * Action to fetch the tokens which can be traded using alcor exchange. This is the exchange which
 * is being used to trade tokens on WAX.
 */
export const getAlcorTokens = createAsyncThunk<AlcorToken[], void>(
  'user/wax/getAlcorTokens',
  () => {
    return getTokens()
  },
)

export type Token = {
  /**
   * The token contract address
   * @example 'eosio.token'
   */
  contract: string
  /**
   * The token symbol
   * @example 'WAX'
   */
  symbol: string
}

export type WaxQuoteArgs = {
  /**
   * The amount of the token as a scaled decimal value
   * @example '89.0239'
   */
  amount: string
  /**
   * The payin token
   */
  from: Token
  /**
   * The payout token
   */
  to: Token
}

/**
 * Finds the alcor exchange data for the token which the user has selected to trade
 * @param tokens The {@link AlcorToken[]} of tokens available to trade using alcor
 * @param selectedToken The token which the user is either inputting or outputting in the trade
 */
function findAlcorToken(tokens: AlcorToken[], selectedToken: Token) {
  const token = tokens.find(token => {
    return (token.contract === selectedToken.contract)
    && (token.symbol.toLowerCase() === selectedToken.symbol.toLowerCase())
  })

  if (!token) {
    throw new Error(`Could not find "from" token with address ${selectedToken.contract} and symbol ${selectedToken.symbol}` )
  }
  return token
}

/**
 * Sets the quote for swapping two tokens on WAX
 */
export const getQuote = createAsyncThunk<void, WaxQuoteArgs, AsyncThunkOptions>(
  'user/wax/getQuote',
  async (args, thunkApi) => {
    const { accountName, alcorTokens } = thunkApi.getState().user.wax
    const maxPriceImpact = thunkApi.getState().singleChain.maxPriceImpact
    try {
      // Ensure the alcor tokens have been fetched
      if (alcorTokens.length === 0) {
        thunkApi.dispatch(getAlcorTokens())
        throw new Error(`No tokens found for exchange - attempting to refetch exchange tokens.
        Please try again.`)
      }
      // Find the alcor exchange data for each token - necessary to have the alcor ID for each
      const payinToken = findAlcorToken(alcorTokens, args.from)
      const payoutToken = findAlcorToken(alcorTokens, args.to)
      const res = await getSwapRoute({ amount: args.amount, receiver: accountName, slippage:
        maxPriceImpact, payinId: payinToken.id, payoutId: payoutToken.id })
      thunkApi.dispatch(storeOutput(res.output))

      // Prepare the information about fees and rates for the swap
      thunkApi.dispatch(storeEstimatedSlippage(res.priceImpact))
      const highPriceImpact = isSignificantPriceImpact(res.priceImpact, parseFloat(maxPriceImpact))
      const rate = { fromSymbol: args.from.symbol, toSymbol: args.to.symbol,
        rate: parseFloat(res.output) / parseFloat(res.input) }
      if (highPriceImpact) {
        thunkApi.dispatch(storeWarning([{ ...singleChainSwapErrors.NOT_ALLOWED_PRICE_IMPACT,
          message: warningGenerator.NOT_ALLOWED_PRICE_IMPACT.message(res.priceImpact) },
        ]))
      }
      const txDetails = formatTransactionDetails({ rate: rate, priceImpact: {
        value: res.priceImpact, highlight: highPriceImpact } })
      thunkApi.dispatch(storeDetails(txDetails))

      // Create the action to be used if a transaction is executed
      const action = prepareAction({
        payinToken: { contract: args.from.contract, amount: res.input, symbol: args.from.symbol },
        userAccount: accountName, memo: res.memo,
      })
      thunkApi.dispatch(setAction(action))
    } catch (e) {
      console.error(e)
      const warning = displayError(e)
      thunkApi.dispatch(storeWarning([warning]))
    }
    thunkApi.dispatch(setLoading(false))
  },
)

/**
 * Make a swap transaction on WAX network
 */
export const doSingleChainSwap = createAsyncThunk<void, void, AsyncThunkOptions>(
  'user/wax/swap',
  async (_, thunkApi) => {
    const { authenticationMethod, action } = thunkApi.getState().user.wax
    const config = thunkApi.getState().configs.WAX

    if (!action) {
      throw new Error('No action data found for this swap')
    }

    if (authenticationMethod === 'WOMBAT') {
      await scatterTransact({ actions: [action] }, config.id, config.node)
    } else if (authenticationMethod === 'WAX_CLOUD_WALLET') {
      await waxCloudWalletTransact({ actions: [action] }, config.node)
    }
  },
)

/**
 * Make a cross-chain transaction on EOS
 */
export const doMultiChainSwap = createAsyncThunk<void, void, AsyncThunkOptions>(
  'user/wax/multiswap',
  async (_, thunkApi) => {
    const { accountName, ownedTokens } = thunkApi.getState().user.wax
    const { transaction, payinToken, payoutId } = thunkApi.getState().multiChain
    const config = thunkApi.getState().configs.WAX
    // TODO - make a neater way to do these checks and handle the errors
    if (!transaction) {
      throw new Error('Executing cross-chain swap: No transaction data found')
    } else if (!payinToken) {
      throw new Error('Executing cross-chain swap: No pay-in token data found')
    } else if (payinToken.id !== transaction.currencyFrom) {
      throw new Error('Executing cross-chain swap: Selected and transaction pay in tokens don\'t match')
    }

    let ownedToken

    if (payinToken.contract) {
      ownedToken = ownedTokens.find(owned => {
        return owned.contract === payinToken.contract
        && owned.currency.toLowerCase() === payinToken.symbol.toLowerCase()
      })
    } else {
    // TODO - ASK ABOUT THIS! - what do we do when there is no contract address provided?
      const token = config.availableTokens.find(available => {
        return (available.name === payinToken.name) && (available.symbol === payinToken.symbol)
      })
      ownedToken = ownedTokens.find(owned => {
        return (
          (owned.contract === token?.address)
          && owned.currency.toLowerCase() === payinToken.symbol.toLowerCase()
        )
      })
    }

    if (!ownedToken) {
      throw new Error('Token isn\'t owned in wallet, can\'t be sent')
    }

    if (payinToken.txIdName && !transaction.payinExtraId) {
      throw new Error(`No payin transaction memo provided for tx ${transaction.id}`)
    }

    if (payoutId.required && payoutId.value && !transaction.payoutExtraId) {
      throw new Error(`No payout transaction memo returned by Changelly for tx ${transaction.id}`)
    }

    const scaledAmount = TokenAmount.fromStringWithDecimals(transaction.amountExpectedFrom,
      ownedToken.decimals, ownedToken.currency).asStringWithDecimalQuantity(ownedToken.decimals)

    const action = eosioAction({ sender: accountName, recipient: transaction.payinAddress,
      memo: transaction.payinExtraId ?? undefined, sentToken: {
        contract: ownedToken.contract, scaledAmount: scaledAmount, symbol: ownedToken.currency,
      } })

    await scatterTransact({ actions: [action] }, config.id, config.node)
  },
)

export const { logout, clearRefresh, setAction, setActions } = waxSlice.actions

export default waxSlice.reducer
