type TokenProperties = {
  /**
   * The address of the input token
   * @example '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'
   */
  srcToken: string
  /**
   * The decimal precision of the input token
   * @example 18
   */
  srcDecimals: number
  /**
   * The address of the output token
   * @example '0x5f0197ba06860dac7e31258bdf749f92b6a636d4'
   */
  destToken: string
  /**
   * The decimal precision of the output token
   * @example 18
   */
  destDecimals: number
}

type TokenQuantities = {
  /**
   * The amount of the token being input to this hop, as a string in wei
   * @example '154797686773955087'
   */
  srcAmount: string
  /**
   * The amount of the token being output from this hop, as a string in wei
   * @example '586314460292827500198'
   */
  destAmount: string
}

/**
 * The route for a swap, always returned as part of the {@link PriceRoute} object
 */
type BestRoute = {
  /**
   * The percentage of this swap which can be made (this is not documented by Paraswap, this is
   * inferred from the responses we receive)
   * @example 100
   */
  percent: number
  /**
   * Each stage of the hops which are needed to complete the swap. Paraswap deals well with swapping
   * via max 1 intermediary token, e.g. WAS -> WOMBAT -> POL and tends to miss longer routes (even
   * if more effective i.e. they have a larger liquidity pool, e.g. WAS -> WOMBAT -> USDC -> POL
   * is ignored even though preferable in terms of exchange rate / lower slippage).
   * Each object in the `swaps` array contains a single hop, i.e. the full transfer of one token
   * into another, e.g. in the WAS -> POL example, the first item would describe the swap from
   * WAS -> WOMBAT and the second, from WOMBAT -> POL.
   */
  swaps: TokenProperties & {
    /**
     * A list of the swap exchanges and liquidity pools that this swap hop is split across. It can
     * be that the entire swap is done via one liquidity pool, however more often especially for
     * larger swap quantities, a single hop e.g. WAS -> WOMBAT is executed using several liquidity
     * pools and on a number of exchanges (e.g. both Uniswap and Quickswap).
     * This can be the case even where a large liquidity pool is available which could easily handle
     * the whole trade - why this happens is unfortunately a black box to us.
     */
    swapExchanges: TokenQuantities & {
      /**
       * The exchange on which this swap is made
       * @example 'QuickSwap'
       */
      exchange: string
      /**
       * The percentage of the input amount which is swapped via this exchange
       * @example 18
       */
      percent: number
      /**
       * The pool addresses in the exchange via which the swap is made
       * n.b., typically this is just one pool
       * @example ["0xfcf19F1BAa7d36b4b293BC096A2948a1268f020d",
       * "0xeF23DcFC07223Dc9036A0Ae26BcD82F129118bCe"]
       * or ["0x35B0EF7294a5Dd3281459c7fde675e055F7953A6"]
       */
      poolAddresses: string[]
      data: {
        /**
         * @example '0xf3938337F7294fEf84e9B2c6D548A93F956Cc281'
         */
        router: string
        /**
         * @example ['0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
         * '0x5f0197ba06860dac7e31258bdf749f92b6a636d4']
         */
        path: string[]
        /**
         * @example '0x5757371414417b8C6CAad45bAeF941aBc7d3Ab32'
         */
        factory: string
        /**
         * @example '0x96e8ac4277198ff8b6f785478aa9a39f403cb768dd02cbee326c3e7da348845f'
         */
        initCode: string
        /**
         * @example 10000
         */
        feeFactor: number
        /**
         * More detailed information about each of the pools in the `poolAddresses`
         */
        pools: {
          /**
           * The address of the pool, corresponds to the address in the `poolAddresses`
           * @example '0xf1464fabaFfFf79eea51be4e9C883c1Bc5737ab2'
           */
          address: string
          /**
           * @example 30
           */
          fee: number
          /**
           * @example true
           */
          direction: boolean
        }[]
        /**
         * The gas for this swap as a string in USD
         * @example '0.005445'
         */
        gasUSD: string
      }
    }[]
  }[]
}[]

/**
 * https://app.swaggerhub.com/apis/paraswapv5/api/1.0#/PriceRoute
 */
export type PriceRoute = TokenProperties & TokenQuantities & {
  /**
   * @example 46692203
   */
  blockNumber: number
  /**
   * The identifying number of the EVM network. Corresponds to the `id` in the {@link evmNetworks}
   * @example 137
   */
  network: number
  /**
   * The recommended route
   */
  bestRoute: BestRoute
  /**
   * The cost of gas for the transaction in USD as a scaled number string
   * @example '0.010847'
   */
  gasCostUSD: string
  /**
   * The gas needed to fuel the transaction
   * !Important this is NOT the price of gas. This is poor naming for the absolute amount of gas
   * which the transaction needs.
   * @example '179300'
   */
  gasCost: string
  /**
   * Not certain what this is - however only 'SELL' is relevant for us
   * @example 'SELL' | 'BUY'
   */
  side: 'SELL' | 'BUY'
  /**
   * @example '0x216b4b4ba9f3e719726886d34a177484278bfcae'
   */
  tokenTransferProxy: string
  /**
   * @example '0xDEF171Fe48CF0115B1d80b88dc8eAB59176FEe57'
   */
  contractAddress: string
  /**
   * The type/complexity of swap which is used. We have enabled 'simpleSwap', 'multiSwap' and
   * 'megaSwap' though more methods are available
   * @example 'simpleSwap'
   */
  contractMethod: string
  /**
   * The input token amount in USD
   * @example '0.0859185985'
   */
  srcUSD: string
  /**
   * The output token amount in USD
   * @example '0.0880409794'
   */
  destUSD: string
  /**
   * @example 'anon', 'paraswap.io'
   */
  partner: string
  /**
   * @example 0
   */
  partnerFee: number
  /**
   * The maximum impact percentage, sometimes referred to as slippage though this is slightly
   * different, permitted for the swap
   * @example 1.5
   */
  maxImpact: number
  /**
   * Whether the swap surpasses the max set priceImpact
   * (default is 15%)
   */
  maxImpactReached: boolean
  /**
   * @example 'da66271b7f006413806647423170e133fc08f714'
   */
  hmac: string
}

/**
 * Errors which can be received from the {@link PriceSuccessResponse}
 */
export enum ParaswapPriceErrors {
  /**
   * A liquidity error for the selected tokens
   */
  LIQUIDITY = 'No routes found with enough liquidity',
  /**
   * An error that a very high price impact is expected from the swap
   *
   * However it's not clear what the MAX IMPACT is (i.e. how big)
   */
  PRICE_IMPACT = 'ESTIMATED_LOSS_GREATER_THAN_MAX_IMPACT'
}

export type PriceErrorResponse = ParaswapPriceError | ParaswapPriceRouteWithError

type ParaswapPriceError = {
  /*
  * An error code for the price route, either:
  * https://app.swaggerhub.com/apis/paraswapv5/api/1.0#/PriceError
  */
 error: ParaswapPriceErrors
}

type ParaswapPriceRouteWithError = ParaswapPriceError & {
  /**
   * The price impact percentage as a string including the percentage symbol
   * @example '99%'
   */
  value: string
  /**
   * The price route returned despite the error
   */
  priceRoute: PriceRoute
}

/**
 * https://app.swaggerhub.com/apis/paraswapv5/api/1.0#/prices/get_prices
 */
export type PriceSuccessResponse = {
  /**
   * The price route which all data for making the swap, received if there isn't an error which
   * prevents it e.g. no liquidity
   */
  priceRoute: PriceRoute
  /**
   * An error for the price route
   */
  error?: ParaswapPriceErrors
  /**
   * The price impact percentage as a string including the percentage symbol
   * @example '99%'
   */
  value?: string
}

export type PriceResponse = PriceErrorResponse | PriceSuccessResponse

/**
 * The body to be sent to the transaction endpoint
 *
 * docs: https://app.swaggerhub.com/apis/paraswapv5/api/1.0#/TransactionsRequestPayload
 */
export type TransactionBody = TokenProperties
// including the `destAmount` value allows users to make swaps with a high estimated price
// impact, if they want to
& Omit<TokenQuantities, 'destAmount'>
& {
  // either the `destAmount` parameter can be passed to give a precise expected output, or an
  // acceptable maximum slippage can be provided. If both are included in the body the call fails
  // the API accepts either `destAmount` or `slippage`, not both
  /**
   * Slippage percentage (represented in 'basis points') e.g. for 2.5% slippage, set the value to
   * 0.025 * 10000 = 250; for 10% = 1000.
   * Slippage can be passed instead of destAmount when side=SELL or srcAmount when side=BUY
   * min: 0, max 10000
   * This is what we call 'price impact'. The difference between price impact and slippage is that
   * price impact calculates the effect of a swap on the price of the token, however slippage is a
   * broader value which accounts for various factors such as price volatility due to the trades
   * of other users.
   * @example 300 ( = 3%)
   */
  slippage: number
  /**
   * The {@link PriceRoute} returned from the `/prices` endpoint
   */
  priceRoute: PriceRoute
  /**
   * The address of the user's evm account
   */
  userAddress: string
  /**
   * The address of the account to receive the referrer fee
   */
  partnerAddress: string
  /**
   * A percentage fee for referring the transaction (represented in 'basis points')
   * e.g. for 2.5% fee percentage, set the value to 0.025 * 10000 = 250; for 10% = 1000.
   * min: 0, max 10000
   * @example 50 ( = 0.5%)
   */
  partnerFeeBps?: number
}

/**
 * https://app.swaggerhub.com/apis/paraswapv5/api/1.0#/TransactionsBuildResponse
 */
export type TransactionResponse = {
  /**
   * the user's address making the swap
   * @example '0xf4bc55ad49573af16dd80bd3dd00e72d611b017c'
   */
  from: string
  /**
   * the swap smart contract address used to make the swap
   * @example '0x1111111254eeb25477b68fb85ed929f73a960582'
   */
  to: string
  /**
   * data about the swap in hex format. This is a *very* long string returned from the 1inch
   * `swap` api when a `swap` request is made, not sure what the data contained is but it is
   * required to execute a swap transaction
   * @example '0x12aa3caf0....000cfee7c08'
   */
  data: string
  /**
   * the input token amount in wei
   * @example '10000000000000000'
   */
  value: string
  /**
   * the amount of gas used for the swap
   * @example 0
   */
  gas: number
  /**
   * the price to pay per unit of gas to fuel the swap
   * @example '20'
   */
  gasPrice: string
  /**
   * The id number of the network
   * @example 137
   */
  chainId: number
}

/**
 * The known errors which can be returned from a failed call to the paraswap `transaction` endpoint
 */
export enum ParaswapTransactionError {
  /**
   * Unable to process due to the maximum allowed slippage/price impact being reached
   */
  MAX_PRICE_IMPACT = 'Validation failed: Can\'t process priceRoute with max impact reached',
  /**
   * The user hasn't enough token to process the transaction
   */
  INSUFFICIENT_FUNDS = 'insufficient funds for gas * price + value',
  /**
   * No information
   */
  UNSPECIFIED_ISSUES = 'This transaction has some errors and may fail. Please contact support for more details',
  /**
   * When the TX confirmation/rejection popup times out
   */
  POPUP_TIMEOUT_REACHED = 'Timeout reached waiting for the answer in popup'
}

export type ErrorResponse = {
  /**
   * Description of an error returned
   */
  error: ParaswapTransactionError | string
}
