@import '../../assets/styles/_variables';

.input-group {
  width: 100%;
  @media (min-width: 768px) {
    position: relative;
  }
}

.inputs {
  display: grid;
  grid-template-columns: 50% 50%;  
}

.token-select {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  gap: 10px;
  border: 1px solid var(--colorWhite);
  border-right: unset;
  border-radius: 16px 0 0 16px;
  box-sizing: content-box;
  height: 20px; 
}

.label {
  position: absolute;
  top: -7px;
  background: var(--colorSurface);
  padding: 0 4px;
  font-size: 12px;
  color: var(--colorTextSecondary);
}

.token {
  display: flex;
  gap: 10px;
  align-items: center;
  overflow: hidden;
}

.icon {
  height: 16px;
  width: 16px;

  &--rotated {
    transform: rotate(180deg);
    transition: all 0.5s;
  }

  &--unrotated {
    transform: rotate(0deg);
    transition: all 0.5s;
  }
}
