import React, { FC } from 'react'

import HISTORY from '../../../../assets/images/history.svg'

import styles from './Link.module.scss'

type Props = {
  /**
   * The text for the link
   */
  text: string
  /**
   * Function to execute when the link button is clicked
   */
  onClick: () => void
}

/**
 * A button which looks like a link in the UI
 */
export const Link: FC<Props> = props => (
  <div className={styles['status']}>
    <button className={styles['status-link']} onClick={props.onClick}>
      <img src={HISTORY} alt='link' className={styles['icon']}/>
      <p>{props.text}</p>
    </button>
  </div>
)
