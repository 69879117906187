@import '../../../../assets/styles/_variables';

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__title {
    font-weight: 700;
    font-size: 1.125rem;
  }
}

.navigation {
  display: flex;
  gap: 10px;
  align-items: center;

  &__text {
    font-weight: 700;
    font-size: 0.75rem;
    color: var(--colorHighlight);
  }

  &__icon {
    height: 0.625rem;
    width: 0.625rem;
  }
}

.inputs {
  display: flex;
  flex-direction: column;
}

.input-group {
  display: grid;
  grid-template-columns: 50% 50%;

  @media (min-width: 768px) {
    position: relative;
  }
}

.input {
  width: 100%;
  color: var(--colorWhite);
  font-size: 1rem;

  &__subtext {
    font-size: 0.75rem;
    padding: 4px 24px;
  }
}

.input::placeholder {
  font-size: 0.75rem;
  color: var(--colorTextDisabled);
}

.token-select {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  gap: 10px;
  border: 1px solid var(--colorWhite);
  border-radius: 16px 0 0 16px;
  box-sizing: content-box;
  height: 20px;
  width: calc(100% - 48px);
  border-radius: 16px;
  
  &--half {
    border-right: unset;
    border-radius: 16px 0 0 16px;
  }

  &--tall {
    height: 40px;
  }
}

.label {
  position: absolute;
  top: -7px;
  background: var(--colorSurface);
  padding: 0 4px;
  font-size: 12px;
  color: var(--colorTextSecondary);
}

.token {
  display: flex;
  gap: 10px;
  align-items: center;
  overflow: hidden;
}

.icon {
  height: 16px;
  width: 16px;

  &--rotated {
    transform: rotate(180deg);
    transition: all 0.5s;
  }

  &--unrotated {
    transform: rotate(0deg);
    transition: all 0.5s;
  }
}

textarea {
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  background: transparent;
  color: var(--colorTextSecondary);
  font-family: inherit;
}
