import React, { FC } from 'react'
import { Network, NetworkSymbol } from '../../configs/networks'
import { useAppSelector } from '../../hooks/useAppSelector'
import { useLocation } from 'react-router-dom'

import EXTERNAL_LINK from '../../assets/images/open-external-link.svg'

import styles from './Moonpay.module.scss'

/**
 * The Moonpay currency code for each etwork main token name
 */
const MoonPayCode: Record<NetworkSymbol, string> = {
  [Network.AVAX]: 'AVAX_CCHAIN',
  [Network.BNB]: 'BNB_BSC',
  [Network.ETH]: 'ETH',
  [Network.POL]: 'MATIC_POLYGON',
  [Network.EOS]: 'EOS',
  [Network.WAX]: 'WAXP',
}

/**
 * A UI component allowing the user to purchase crypto by redirecting them to Moonpay
 */
export const MoonPay: FC = () => {
  const { network, mainToken } = useAppSelector(state => state.singleChain)
  const { pathname } = useLocation()

  if (pathname.includes('/cross-chain')) {
    return null
  }


  const base = 'https://buy.moonpay.com/?'

  const query = new URLSearchParams({
    apiKey: process.env.MOONPAY_API_KEY ?? '',
    defaultCurrencyCode: MoonPayCode[network.symbol],
    colorCode: '%23FF4F24',
  })
  const url = base + query

  return (
    <div className={styles['moonpay']}>
      <p className={styles['title']} aria-labelledby='link'>Not enough {mainToken.symbol}?</p>
      <img className={styles['icon']} src={network.icon} alt='network-icon'/>
      <a href={url} className={styles['link']}>
        <img src={EXTERNAL_LINK} alt='link'/>
        <p id='link'>Buy more</p>
      </a>
    </div>
  )
}
